import { createSlice } from '@reduxjs/toolkit';

export interface IToggleFilterState {
  isCharacterToggleActive: boolean;
  isPromotionToggleActive: boolean;
}

const initialState: IToggleFilterState = {
  isCharacterToggleActive: true,
  isPromotionToggleActive: false,
};

const toggleFilterSlice = createSlice({
  initialState,
  name: 'toggleFilter',
  reducers: {
    updateCharactersToggleActive: (state, action) => {
      state.isCharacterToggleActive = action.payload.value;
    },
    updatePromotionToggleActive: (state, action) => {
      state.isPromotionToggleActive = action.payload.value;
    },
  },
});

export default toggleFilterSlice.reducer;
export const { updateCharactersToggleActive, updatePromotionToggleActive } = toggleFilterSlice.actions;
