import { RootState } from '@lib/core/service/types/appStateType';

/**
 * @returns the handshake status
 */
export const selectCommsHandshake = (state: RootState, defaultValue = false): boolean =>
  state.comms.isHostHandshakeApproved || defaultValue;

/**
 * @returns the custom id set on the hosting website for tracking purposes
 */
export const selectCommsHostCustomId = (state: RootState, defaultValue = ''): string =>
  state.comms.hostCustomId || defaultValue;

/**
 * @returns the default redirect url for "see all products" on the hosting website
 */
export const selectCommsHostRedirectUrl = (state: RootState, defaultValue = ''): string =>
  state.comms.hostRedirectUrl || defaultValue;

/**
 * @returns the Levia redirect url for "see all products" on the hosting website
 */
export const selectCommsHostLeviaRedirectUrl = (state: RootState, defaultValue = ''): string =>
  state.comms.hostLeviaRedirectUrl || defaultValue;
