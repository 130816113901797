import { createSlice } from '@reduxjs/toolkit';

import { selectRetailerId } from '@lib/core/retailers/selectors/retailer';
import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { apiUrlCreateExternalCRM } from '@lib/core/service/slices/technical/urls';

interface IExternalCRMRequestData {
  sessionId: string;
  externalCrmId: string;
}

interface IClientIpSlice {
  isLoading: boolean;
}
const initialState: IClientIpSlice = {
  isLoading: false,
};

export const actionCreateExternalCRM = createTypedAsyncThunk(
  'actionCreateExternalCRM',
  async ({ sessionId, externalCrmId }: IExternalCRMRequestData, { getState }) => {
    return await request(
      apiUrlCreateExternalCRM,
      {
        method: REQUEST_METHODS.POST,
      },
      {
        crm_id: externalCrmId,
        retailer: selectRetailerId(getState()),
        user_session: sessionId,
      },
    );
  },
);

export const externalCRM = createSlice({
  extraReducers: builder => {
    builder.addCase(actionCreateExternalCRM.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionCreateExternalCRM.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(actionCreateExternalCRM.fulfilled, state => {
      state.isLoading = false;
    });
  },
  initialState,
  name: 'externalCRM',
  reducers: {},
});

export default externalCRM.reducer;
