import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { TEssenceGroups } from '@lib/core/characters/types/essences';
import { apiUrlGetEssenceGroup } from '@lib/core/essences/slices/urls';
import request from '@lib/core/service/requests/request';
import { IToolkitRejectedAction } from '@lib/core/service/types/common';

interface IEssenceGroupsSlice {
  isLoading: boolean;
  data: TEssenceGroups[];
  error: string;
}
const initialState: IEssenceGroupsSlice = {
  data: undefined,
  error: '',
  isLoading: false,
};

export const actionGetEssenceGroups = createAsyncThunk('essences/groups/get', async () => {
  return await request(apiUrlGetEssenceGroup);
});

const essenceGroupsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetEssenceGroups.pending, state => {
      state.error = '';
      state.isLoading = true;
    });
    builder.addCase(actionGetEssenceGroups.fulfilled, (state, action: PayloadAction<TEssenceGroups[]>) => {
      const { payload } = action;
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(actionGetEssenceGroups.rejected, (state, action: IToolkitRejectedAction) => {
      const { payload: { errors: { detail } = {} } = {} } = action;
      state.isLoading = false;
      state.error = detail;
    });
  },
  initialState,
  name: 'essenceGroups',
  reducers: {},
});

export default essenceGroupsSlice.reducer;
