/* eslint-disable sort-keys-fix/sort-keys-fix */
import { createSlice } from '@reduxjs/toolkit';

import recyclingReducer, { IRecyclingState } from '@lib/core/productAttributes/slices/recycling';
import wellnessReducer, { IWellnessState } from '@lib/core/productAttributes/slices/wellness';

interface IProductAttributesSlice {
  recycling: IRecyclingState;
  wellness: IWellnessState;
  isLoading: boolean;
}

const initialState: IProductAttributesSlice = {
  recycling: recyclingReducer(undefined, { type: '' }),
  wellness: wellnessReducer(undefined, { type: '' }),
  isLoading: false,
};

export const productAttributesSlice = createSlice({
  extraReducers: builder => {
    builder.addDefaultCase((state, action) => {
      state.recycling = recyclingReducer(state.recycling, action);
      state.wellness = wellnessReducer(state.wellness, action);

      if (state.recycling.isLoading === false && state.wellness.isLoading === false) {
        state.isLoading = false;
      } else {
        state.isLoading = true;
      }
    });
  },
  initialState,
  name: 'productAttributes',
  reducers: {},
});

export default productAttributesSlice.reducer;
