import { B2C_EXPOSURE_TAGS } from '@lib/core/service/consts';
import { USER_NO_FOOD_PREFERENCES_DATA, USER_NO_TAGS_PREFERENCES_DATA } from '@lib/tools/shared/helpers/consts';

export type IPreference = {
  isEnabled: boolean;
  name: string;
  exposure: string;
  description: string;
  slug: string;
};

export type IPreferenceExposure = Record<string, IPreference>;

export type IUserProductPreferences = {
  slug: string;
  name: string;
  exposure: string;
  description: string;
};

export const formatUserPreferenceData = ({
  allUserProductPreferencesData,
  exposure,
  userSelectedProductPreferencesData = [],
}) => {
  let returnObject = {};
  let lastPlaceObject = {};

  const noPreferencesSlug =
    exposure === B2C_EXPOSURE_TAGS ? USER_NO_TAGS_PREFERENCES_DATA : USER_NO_FOOD_PREFERENCES_DATA;

  allUserProductPreferencesData.forEach(item => {
    if (item.exposure === exposure) {
      const isEnabled =
        userSelectedProductPreferencesData.some(selectedItem => item.slug === selectedItem.slug) ?? false;

      // ! Check typing
      // @ts-ignore
      const preferenceItem: IPreferenceExposure = {
        [item.slug]: {
          description: item.description,
          isEnabled,
          name: item.name,
          slug: item.slug,
        },
      };

      if (item.slug === noPreferencesSlug) {
        lastPlaceObject = { ...preferenceItem };
      } else {
        returnObject = { ...returnObject, ...preferenceItem };
      }
    }
  });

  return { ...returnObject, ...lastPlaceObject };
};
