import { createSlice } from '@reduxjs/toolkit';

import { IB2CArticle } from '@app/native/src/interfaces/article';

import { ENV_IS_BUILD } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import { selectLocale } from '@lib/core/service/selectors';

export interface IArticleRequestData {
  count: number;
  results: IB2CArticle[];
}

export interface IAppArticleTypes {
  identifier?: string;
  name?: string;
}

export interface IArticlesCatalogState {
  articlesRequestError: string;
  articlesData: IArticleRequestData;
  articlesTypes: IAppArticleTypes[];
  isArticlesRequestLoading: boolean;
}

export const initialArticleCatalogState: IArticlesCatalogState = {
  articlesData: {
    count: 0,
    results: [],
  },
  articlesRequestError: '',
  articlesTypes: [],
  isArticlesRequestLoading: false,
};

export const fetchArticlesFromS3 = () => {
  return fetch(
    ENV_IS_BUILD ? backendApiUrls.apiUrlArticlesList : `https://app.vinhood.dev${backendApiUrls.apiUrlArticlesList}`,
  )
    .then(response => response.json())
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
    });
};

export const fetchArticleLists = createTypedAsyncThunk('articleCatalog/fetchArticleLists', async (_, { getState }) => {
  const lang = selectLocale(getState());

  const json = await fetchArticlesFromS3();

  const articlesList = json[lang];

  const articleTypes = [
    ...new Map(articlesList.map(articles => [articles.article_type.identifier, articles.article_type])).values(),
  ];
  const sortedArticlesTypes = articleTypes.sort((a: any, b: any) => a.identifier.localeCompare(b.identifier));
  return { articlesTypes: sortedArticlesTypes, results: articlesList };
});

export const articleCatalogSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchArticleLists.fulfilled, (state, action) => {
      state.isArticlesRequestLoading = false;
      state.articlesData.results = action.payload?.results;
      state.articlesTypes = action.payload?.articlesTypes;
    });
    builder.addCase(fetchArticleLists.pending, state => {
      state.isArticlesRequestLoading = true;
    });
    builder.addCase(fetchArticleLists.rejected, state => {
      state.isArticlesRequestLoading = false;
    });
  },
  initialState: initialArticleCatalogState,
  name: 'articleCatalog',
  reducers: {},
});

export default articleCatalogSlice.reducer;
