import { createSlice } from '@reduxjs/toolkit';

import { IRequestParams, getProductsListData } from '@lib/core/products/utils';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { parseThunkError } from '@lib/tools/shared/helpers/utils';

export const fetchPromotionProducts = createTypedAsyncThunk(
  'kioskRoot/fetchPromotionProducts',
  async (params: IRequestParams) => {
    const { results: products = [] } = await getProductsListData(params);
    return !!products.length;
  },
);

const initialState = {
  isPromotionProductsAvailable: false,
  isPromotionProductsLoading: false,
  requestError: '',
};

const promotionSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchPromotionProducts.pending, state => {
      state.isPromotionProductsLoading = true;
    });
    builder.addCase(fetchPromotionProducts.fulfilled, (state, action) => {
      state.isPromotionProductsLoading = false;
      state.isPromotionProductsAvailable = action.payload;
    });
    builder.addCase(fetchPromotionProducts.rejected, (state, action: any) => {
      state.isPromotionProductsLoading = false;
      state.requestError = parseThunkError(action);
    });
  },
  initialState,
  name: 'promotionProducts',
  reducers: {
    resetPromotionProducts() {
      return initialState;
    },
  },
});

export const { resetPromotionProducts } = promotionSlice.actions;
export default promotionSlice.reducer;
