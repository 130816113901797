import { createSlice } from '@reduxjs/toolkit';

import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { IToolkitRejectedAction } from '@lib/core/service/types/common';

export interface IScoreMatrixSlice {
  isLoading: boolean;
  data: Record<string, Record<string, any>>;
  error: string;
}
const initialState: IScoreMatrixSlice = {
  data: {},
  error: '',
  isLoading: false,
};

export const updateScoreMatrix = createTypedAsyncThunk(
  'scoreMatrix/prepareScoreMatrix',
  async (
    { scoreMatrixData, selectedProductCategory }: { scoreMatrixData: object; selectedProductCategory: string },
    { getState },
  ) => {
    const state = getState();
    const updatedData = {
      ...state.data,
      [selectedProductCategory]: scoreMatrixData,
    };
    return updatedData;
  },
);
export const updateScoreMatrixByChangedNodes = createTypedAsyncThunk(
  'scoreMatrix/updateScoreMatrixByChangedNodes',
  async (
    {
      changedNodes,
      productCategory,
      fetchedScoreMatrix,
    }: { changedNodes: object; productCategory: string; fetchedScoreMatrix?: object },
    { getState },
  ) => {
    const state = getState();
    const scoreMatrix = JSON.parse(JSON.stringify(state.scoreMatrix));
    let scoreMatrixToUpdate;

    if (fetchedScoreMatrix) {
      scoreMatrixToUpdate = fetchedScoreMatrix;
    } else {
      scoreMatrixToUpdate = scoreMatrix.data[productCategory];
    }

    if (changedNodes && changedNodes[productCategory]) {
      Object.keys(changedNodes[productCategory]).forEach(changedNode => {
        if (changedNode.startsWith('QA')) {
          const scoreArray = changedNodes[productCategory][changedNode].scoresArray;
          const foundKey = Object.keys(scoreMatrixToUpdate.scores).find(key => {
            const answersObj = scoreMatrixToUpdate.scores[key];
            const answerArray = Object.keys(answersObj);
            return answerArray.includes(changedNode);
          });

          scoreMatrixToUpdate.scores[foundKey][changedNode] = scoreArray;
        }
      });
      Object.keys(changedNodes[productCategory]).forEach(changedNode => {
        if (changedNode.startsWith('QQ') && changedNodes[productCategory][changedNode].addedChildren) {
          changedNodes[productCategory][changedNode].addedChildren.forEach(addedChild => {
            const foundKey = Object.keys(scoreMatrixToUpdate.scores).find(key => {
              const answersObj = scoreMatrixToUpdate.scores[key];
              const answerArray = Object.keys(answersObj);
              return answerArray.includes(addedChild);
            });

            const scoreArray = scoreMatrixToUpdate.scores[foundKey][addedChild];
            if (!scoreMatrixToUpdate.scores[changedNode][addedChild])
              scoreMatrixToUpdate.scores[changedNode][addedChild] = {};
            scoreMatrixToUpdate.scores[changedNode][addedChild] = scoreArray;
          });
        }
      });
      Object.keys(changedNodes[productCategory]).forEach(changedNode => {
        if (changedNode.startsWith('QQ') && changedNodes[productCategory][changedNode].removedChildren) {
          changedNodes[productCategory][changedNode].removedChildren.forEach(removedChild => {
            delete scoreMatrixToUpdate.scores[changedNode][removedChild];
          });
        }
      });
      Object.keys(changedNodes[productCategory]).forEach(changedNode => {
        if (changedNode.startsWith('QQ') && changedNodes[productCategory][changedNode].isRemovedNode) {
          delete scoreMatrixToUpdate.scores[changedNode];
        }
      });
    }

    return scoreMatrixToUpdate;
  },
);

export const updateScoreMatrixByValue = createTypedAsyncThunk(
  'scoreMatrix/updateScoreMatrixByValue/put',
  async (
    {
      parentId,
      nodeId,
      scoreArray,
      selectedProductCategory,
    }: { parentId: string; nodeId: string; scoreArray: Array<number>; selectedProductCategory: string },
    { getState },
  ) => {
    const currentState = getState().scoreMatrix;
    currentState.data[selectedProductCategory].scores[parentId][nodeId] = scoreArray;
    return currentState;
  },
);

const handleFulfilledAction = (state, action) => {
  const { payload } = action;
  state.isLoading = false;
  state.data = payload;
};

const handleRejectedAction = (state, action: IToolkitRejectedAction) => {
  const { payload: { errors: { detail } = {} } = {} } = action;
  state.error = detail;
};

const handlePendingAction = state => {
  state.error = '';
  state.isLoading = true;
};

export const scoreMatrixSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(updateScoreMatrix.fulfilled, handleFulfilledAction);
    builder.addCase(updateScoreMatrix.pending, handlePendingAction);
    builder.addCase(updateScoreMatrix.rejected, handleRejectedAction);
    builder.addCase(updateScoreMatrixByChangedNodes.fulfilled, handleFulfilledAction);
    builder.addCase(updateScoreMatrixByChangedNodes.pending, handlePendingAction);
    builder.addCase(updateScoreMatrixByChangedNodes.rejected, handleRejectedAction);
    builder.addCase(updateScoreMatrixByValue.fulfilled, handleFulfilledAction);
    builder.addCase(updateScoreMatrixByValue.pending, handlePendingAction);
    builder.addCase(updateScoreMatrixByValue.rejected, handleRejectedAction);
  },
  initialState,
  name: 'scoreMatrix',
  reducers: {},
});

export default scoreMatrixSlice.reducer;
