/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';
import { EditTextarea } from 'react-edit-text';
import { IntlShape, injectIntl } from 'react-intl';

import { useApp } from '@lib/core/service/hooks';
import { insertIntlJSXPart } from '@lib/core/service/utils';
import StyledTooltip from '@lib/tools/devtools/components/feedback/Tooltip';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { languages } from '@lib/tools/locale/utils/consts';
import CustomMDReactComponent from '@lib/tools/locale/views/CustomMDReactComponent';

// eslint-disable-next-line no-restricted-imports
import './locale_fragment.scss';
import 'react-edit-text/dist/index.css';

export type ILocaleType = { readonly id: string; readonly defaultMessage: string };
export type ILocaleText = { readonly id: string; readonly defaultMessage: string } | string;

export interface LocaleFragmentProps {
  message: ILocaleText;
  intl: IntlShape;
  options?: Record<string, string>;
  variables?: Record<string, { readonly id: string; readonly defaultMessage: string }>;
  index?: Record<string, string>;
  insertIntlJSXPartOptions?: {
    linkId: string;
    linkRender: (text: string) => React.ReactElement;
    className?: string;
  };
  transformText?: 'default' | 'none' | 'custom-pmi';
}

/**
 * * This component is used for any static text. It injectsIntl and sources translations from firebase or local files.
 * ! Pass productCategory into the options payload
 * Can handle both JSON terms and plain strings.
 * Supports markdown.
 */
const LocaleFragment: FC<LocaleFragmentProps> = ({
  intl: { formatMessage },
  message,
  insertIntlJSXPartOptions,
  options = {},
  variables,
  index = {},
  transformText = 'default',
}) => {
  const { isDevToolsEnabled, isLocaleEditMode, locale } = useApp();

  const renderContent = (text: string) => <CustomMDReactComponent text={text} transformText={transformText} />;

  if (typeof message === 'string') {
    return isDevToolsEnabled ? (
      <span data-testid={message.slice(0, 20).replace(/\s+/g, '-').toLowerCase()}>{renderContent(message)}</span>
    ) : (
      renderContent(message)
    );
  }

  const getText = () => {
    const mergedOptions = { ...index, ...options };
    if (variables) {
      Object.entries(variables).forEach(([key, value]) => {
        mergedOptions[key] = formatMessage(value);
      });
    }
    return formatMessage(message, mergedOptions).replaceAll('||', '   \n');
  };

  const text = getText();
  const reactMarkdownText = renderContent(text);

  if (isDevToolsEnabled && isLocaleEditMode && locale !== languages.ENGLISH) {
    return (
      <StyledTooltip arrow title={message.id}>
        <div
          className="locale-fragment-wrapper"
          onClick={e => e.stopPropagation()}
          onKeyDown={e => e.stopPropagation()}
        >
          <EditTextarea
            className="locale-fragment"
            defaultValue={text}
            id={message.id}
            inputClassName="locale-fragment-input"
            onSave={data => LocaleUtils.updateTerm(message.id, data.value)}
          />
        </div>
      </StyledTooltip>
    );
  }

  if (insertIntlJSXPartOptions) {
    return insertIntlJSXPart({ ...insertIntlJSXPartOptions, text });
  }

  return isDevToolsEnabled ? (
    <span data-testid={message.id || 'localeFragment'}>{reactMarkdownText}</span>
  ) : (
    reactMarkdownText
  );
};

export default injectIntl(LocaleFragment);
