import { TProductCategory } from '@lib/core/products/types';
import { getRequestCommonParameters } from '@lib/core/service/requests/api';

/**
 * List of all available characters for certain retailer
 */
export const apiUrlGetCharacters = () => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();
  return `/characters/${retailerSlug}/${retailerLocationSlug}/characters/`;
};

/**
 * List of available characters for certain retailer with possible filter by productCategory
 */
export const apiUrlGetAssociatedCharacter = (productCategory: TProductCategory, characterId: string) => {
  const { retailerSlug, retailerLocationSlug } = getRequestCommonParameters();

  return `/characters/${retailerSlug}/${retailerLocationSlug}/associated-character/${productCategory}/${characterId}/`;
};

/**
 * Used for get character data for TA based on character ID
 *
 * @param {string} characterID
 * @returns {string} url for get character data for TA based on character ID
 */
export const apiUrlGetRetailerCharacter = ({ characterID }: { characterID: string }) => {
  const { retailerSlug } = getRequestCommonParameters();

  return `/retailers/${retailerSlug}/retailer-character/${characterID}/`;
};
