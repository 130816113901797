import { createSelector } from 'reselect';

import { RootState } from '@lib/core/service/types/appStateType';

export const selectIsFidelityCardScanLoading = (state: RootState) => state.fidelityCard.isScanningLoading;
export const selectFidelityCardData = (state: RootState) => state.fidelityCard.fidelityCardData?.fidelity_card;
export const selectActiveFidelityModalCode = (state: RootState) => state.fidelityCard.activeFidelityModalCode;

export const selectFidelityCardId: (state) => string = createSelector([selectFidelityCardData], data => {
  return data?.card_identifier;
});
