import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { writeHistory } from '@lib/core/service/slices';

/**
 * ! Needs improvement
 * ? Move into Launcher
 * * Set the history object and its properties to be used outside React.
 */
export const history = {
  navigate: null,
  push: (page, ...args) => {
    if (history.navigate) history.navigate(page, ...args);
  },
};

export const Navigator = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigateMethod = useNavigate();

  useEffect(() => {
    history.navigate = navigateMethod;
  }, []);

  useEffect(() => {
    dispatch(writeHistory(`${location.pathname}${location.search}`));
  }, [location]);

  return null;
};
