export const languages = {
  ARABIC: 'ar',
  ARABIC_BH: 'ar-bh',
  ARABIC_EG: 'ar-eg',
  ARABIC_JO: 'ar-jo',
  ARABIC_KW: 'ar-kw',
  ARABIC_LB: 'ar-lb',
  ARABIC_MA: 'ar-ma',
  ARABIC_PS: 'ar-ps',
  ARABIC_SA: 'ar-sa',
  BOSNIAN: 'bs',
  BULGARIAN: 'bg',
  CROATIAN: 'hr',
  CZECH: 'cs',
  DANISH: 'da',
  ENGLISH: 'en',
  ENGLISH_AE: 'en-ae',
  ENGLISH_BA: 'en-ba',
  ENGLISH_BH: 'en-bh',
  ENGLISH_CH: 'en-ch',
  ENGLISH_CI: 'en-ci',
  ENGLISH_CY: 'en-cy',
  ENGLISH_CZ: 'en-cz',
  ENGLISH_DE: 'en-de',
  ENGLISH_EG: 'en-eg',
  ENGLISH_EL: 'en-el',
  ENGLISH_ES: 'en-es',
  ENGLISH_HR: 'en-hr',
  ENGLISH_HU: 'en-hu',
  ENGLISH_ID: 'en-id',
  ENGLISH_IL: 'en-il',
  ENGLISH_IT: 'en-it',
  ENGLISH_JA: 'en-ja',
  ENGLISH_JO: 'en-jo',
  ENGLISH_KK: 'en-kk',
  ENGLISH_KW: 'en-kw',
  ENGLISH_LB: 'en-lb',
  ENGLISH_MA: 'en-ma',
  ENGLISH_MK: 'en-mk',
  ENGLISH_MX: 'en-mx',
  ENGLISH_MY: 'en-my',
  ENGLISH_PH: 'en-ph',
  ENGLISH_PL: 'en-pl',
  ENGLISH_PS: 'en-ps',
  ENGLISH_PT: 'en-pt',
  ENGLISH_RO: 'en-ro',
  ENGLISH_RU: 'en-ru',
  ENGLISH_SA: 'en-sa',
  ENGLISH_SI: 'en-si',
  ENGLISH_SK: 'en-sk',
  ENGLISH_TC: 'en-tc',
  ENGLISH_ZA: 'en-za',
  FRENCH: 'fr',
  GERMAN: 'de',
  GERMAN_CH: 'de-ch',
  GREEK: 'el',
  GREEK_CY: 'el-cy',
  HEBREW: 'he',
  HUNGARIAN: 'hu',
  INDONESIAN: 'id',
  ITALIAN: 'it',
  ITALIAN_CH: 'it-ch',
  JAPANESE: 'ja',
  KAZAKH: 'kk',
  KOREAN: 'ko',
  MACEDONIAN: 'mk',
  MALAY: 'ms',
  MA_FRENCH: 'fr-ma',
  POLISH: 'pl',
  PORTUGUESE: 'pt',
  ROMANIAN: 'ro',
  RUSSIAN: 'ru',
  RUSSIAN_KZ: 'ru-kk',
  SERBIAN: 'sr',
  SLOVAK: 'sk',
  SLOVENIAN: 'sl',
  SPANISH: 'es',
  SPANISH_CI: 'es-ci',
  SPANISH_CO: 'es-co',
  SPANISH_MX: 'es-mx',
  TURKISH: 'tr',
};

export const currencies = {
  AFN: '؋',
  ALL: 'Lek',
  ANG: 'ƒ',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BGN: 'лв',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BRL: 'R$',
  BSD: '$',
  BWP: 'P',
  BYN: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CHF: 'CHF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUP: '₱',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  EGP: '£',
  EUR: '€',
  Euro: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GGP: '£',
  GHS: '¢',
  GIP: '£',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JPY: '¥',
  KGS: 'лв',
  KHR: '៛',
  KPW: '₩',
  KRW: '₩',
  KYD: '$',
  KZT: 'лв',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  MKD: 'ден',
  MNT: '₮',
  MUR: '₨',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SOS: 'S',
  SRD: '$',
  SVC: '$',
  SYP: '£',
  THB: '฿',
  TRY: '',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  UAH: '₴',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  XCD: '$',
  YER: '﷼',
  ZAR: 'R',
  ZWD: 'Z$',
};
