import { useSelector } from 'react-redux';

import { TCharacter } from '@lib/core/characters/types';
import {
  selectQuizQuestionImages,
  selectRetailerCharacterRedirectUrls,
  selectUserQuizCharacters,
  selectUserQuizCompleted,
  selectUserQuizId,
  selectUserQuizLoading,
  selectUserQuizSlug,
  selectUserQuizState,
  selectUserQuizType,
} from '@lib/core/quizzes/selectors';
import { IUserQuizSlice } from '@lib/core/quizzes/slices/userQuiz';
import { TUserQuizType } from '@lib/core/quizzes/types/userQuiz';

interface IUserQuiz extends TUserQuizType {
  isUserQuizComplete: boolean;
  isUserQuizLoading: boolean;

  userQuizCharacters: TCharacter[];
  userQuizId: string;
  userQuizState: IUserQuizSlice;
  userQuizQuestionImages: Record<string, string>;
  userQuizSlug: string;
  userQuizRetailerCharacterRedirectUrls: string;
}

export const useUserQuiz = (): IUserQuiz => {
  return {
    isUserQuizComplete: useSelector(selectUserQuizCompleted),
    isUserQuizLoading: useSelector(selectUserQuizLoading),
    userQuizCharacters: useSelector(selectUserQuizCharacters),
    userQuizId: useSelector(selectUserQuizId),
    userQuizQuestionImages: useSelector(selectQuizQuestionImages),
    userQuizRetailerCharacterRedirectUrls: useSelector(selectRetailerCharacterRedirectUrls),
    userQuizSlug: useSelector(selectUserQuizSlug),
    userQuizState: useSelector(selectUserQuizState),
    ...useSelector(selectUserQuizType),
  };
};
