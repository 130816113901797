import { useEffect, useLayoutEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { trackQuizCompletion, trackQuizInitialization, trackQuizQuestionDisplay } from '@app/pmi/src/comms/events';
import {
  broadcastDomHeightToHost,
  broadcastUserQuizResultToHost,
  isRouteEcommerce,
  saveCrmIdToLocalStorage,
} from '@app/pmi/src/comms/utils';

import { useProducts } from '@lib/core/products/hooks/products';
import { useQuizView } from '@lib/core/quizzes/hooks';
import { POST_MESSAGE_QUIZ_COMPLETED } from '@lib/tools/comms/consts';
import { useComms } from '@lib/tools/comms/hooks';
import { initComms, isAppInIframe } from '@lib/tools/comms/utils';
import { recommendProducts } from '@lib/tools/shared/pmi/products/recommender';
import { isQuizAnswerTagDissatisfied, isQuizAnswerTagSatisfied } from '@lib/tools/shared/pmi/quizzes/filters';

import { getTechnicalCaptionText } from '@components/pmi/src/utils';

const CommsProvider = ({ children, intl }) => {
  const dispatch = useDispatch();

  const { productsByCharacterCorrelation, productsList } = useProducts();
  const { quizViewAnswerTags, quizViewUserPath, quizViewQuestionId } = useQuizView();
  const { isHostHandshakeApproved } = useComms();

  /**
   * * Setup postMessage listener
   */
  useEffect(() => {
    // Listen for messages from hosting libraries
    if (isAppInIframe) {
      initComms(dispatch);

      if (isRouteEcommerce()) saveCrmIdToLocalStorage();
    }
  }, []);

  /**
   * * Propagate DOM height to host
   */
  useLayoutEffect(() => {
    broadcastDomHeightToHost();
  }, [productsList, productsByCharacterCorrelation]);

  /**
   * * Track quiz initialization
   */
  useEffect(() => {
    const userPathLength = Object.keys(quizViewUserPath).length;
    if (!userPathLength) return;

    if (userPathLength === 1) trackQuizInitialization();
    trackQuizQuestionDisplay(userPathLength, quizViewQuestionId);
  }, [quizViewUserPath]);

  /**
   * * Quiz completion
   */
  useLayoutEffect(() => {
    if (productsByCharacterCorrelation.length) {
      const recommendedProducts = recommendProducts(productsByCharacterCorrelation);

      if (recommendedProducts) {
        const isQuizDissatisfiedCondition = quizViewAnswerTags.some(isQuizAnswerTagDissatisfied);
        const isQuizSatisfiedCondition = quizViewAnswerTags.some(isQuizAnswerTagSatisfied);

        let technicalCaptionTextNumbers = [];
        if (isQuizSatisfiedCondition) {
          technicalCaptionTextNumbers = [2, 1];
        }
        if (isQuizDissatisfiedCondition) {
          technicalCaptionTextNumbers = [1, 2];
        }

        const recommendationCardTextOne = getTechnicalCaptionText(intl, technicalCaptionTextNumbers[0]);
        const recommendationCardTextTwo = getTechnicalCaptionText(intl, technicalCaptionTextNumbers[1]);

        broadcastUserQuizResultToHost(POST_MESSAGE_QUIZ_COMPLETED, {
          products: productsByCharacterCorrelation,
          recommendedProductCaptions: [recommendationCardTextOne, recommendationCardTextTwo],
        });

        trackQuizCompletion(recommendedProducts);
      }
    }
  }, [productsByCharacterCorrelation]);

  return (isAppInIframe && isHostHandshakeApproved) || !isAppInIframe ? children : null;
};

export default injectIntl(CommsProvider);
