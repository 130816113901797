export const BANNER_POSITION_HOME = 'home';
export const BANNER_POSITION_MULTI_PRODUCT_RESULT = 'multi_product_test_result';
export const BANNER_POSITION_EMAIL_REGISTRATION = 'email-registration';
export const BANNER_POSITION_RECIPE_PAGE = 'recipe';
export const BANNER_POSITION_PROFILE_TASTE_PAGE = 'profileTaste';
export const BANNER_POSITION_CATALOG = 'catalog';
export const BANNER_POSITION_CATALOG_WINE = 'catalog/wine';
export const BANNER_POSITION_CATALOG_COFFEE = 'catalog/coffee';
export const BANNER_POSITION_CATALOG_BEER = 'catalog/beer';
export const BANNER_POSITION_PRODUCT_DETAILS = 'product';
export const BANNER_POSITION_EXPERIENCES = 'experiences';
export const BANNER_POSITION_ARTICLES = 'articles';
export const BANNER_POSITION_TIP_AND_TRICKS = 'tip-and-tricks';
export const BANNER_POSITION_EDUTAINMENT = 'edutainment';
export const BANNER_ID_REDIRECT_EDUTAINMENT = 'NL0046';
export const BANNER_POSITION_TASTE_ID = 'taste-id';

export const BANNER_ICONS = {
  bookmark: 'bookmark',
  box: 'box',
  cart: 'cart',
  compass: 'compass',
  doc: 'doc',
  filter: 'filter',
  'finger-print': 'finger-print',
  'fork-knife': 'fork-knife',
  list: 'list',
  promo: 'promo',
  rocket: 'rocket',
  save: 'save',
  star: 'star',
} as const;

export const AuthBannerTypes = {
  app: 'app',
  experience: 'experience',
  plain: 'plain',
} as const;

// MultiProductResult Email Banner
export const BANNER_STATIC_MULTI_PRODUCT_RESULT = {
  identifier: 'NL0045',
  linkIndentifier: 'R00003',
  positionIdentifier: 'R00002',
};
