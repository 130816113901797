import { useSelector } from 'react-redux';

import {
  selectIsRemoteAccessLoading,
  selectIsRemoteAccessValid,
  selectRemoteAccessCustomId,
  selectRemoteAccessError,
  selectRemoteAccessExpiry,
  selectRemoteAccessKey,
  selectRemoteAccessLinks,
  selectRemoteAccessRetries,
} from '@lib/core/retailers/selectors/remoteAccess';
import { TGenerateRetailerAccessKey } from '@lib/core/retailers/types';

type TRetailerDetails = {
  isRemoteAccessLoading: boolean;
  isRemoteAccessValid: boolean;

  remoteAccessError: string;
  remoteAccessExpiry: string;
  remoteAccessKey: string;
  remoteAccessRetries: number;
  remoteAccessCustomId: string;
  remoteAccessLinks: TGenerateRetailerAccessKey[];
};

export const useRemoteAccess = (): TRetailerDetails => {
  return {
    isRemoteAccessLoading: useSelector(selectIsRemoteAccessLoading),
    isRemoteAccessValid: useSelector(selectIsRemoteAccessValid),

    remoteAccessCustomId: useSelector(selectRemoteAccessCustomId),
    remoteAccessError: useSelector(selectRemoteAccessError),
    remoteAccessExpiry: useSelector(selectRemoteAccessExpiry),
    remoteAccessKey: useSelector(selectRemoteAccessKey),
    remoteAccessLinks: useSelector(selectRemoteAccessLinks),
    remoteAccessRetries: useSelector(selectRemoteAccessRetries),
  };
};
