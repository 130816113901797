import DifferenceIcon from '@mui/icons-material/Difference';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box, Stack } from '@mui/system';
import { DiffResult, Viewer } from 'json-diff-kit';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useApp } from '@lib/core/service/hooks';
import { setServiceLocale, toggleLocaleEditMode } from '@lib/core/service/slices';
import { useUser } from '@lib/core/users/hooks';
import { actionResetAuthSlice } from '@lib/core/users/slices/auth';
import { SnackbarAlert } from '@lib/tools/devtools/components/feedback/SnackBar';
import StyledTooltip from '@lib/tools/devtools/components/feedback/Tooltip';
import LoginForm from '@lib/tools/devtools/components/forms/LoginForm';
import { Button } from '@lib/tools/devtools/components/inputs/Button';
import { ILocaleTableDataRow } from '@lib/tools/devtools/components/interfaces';
import { TableComponent } from '@lib/tools/devtools/components/table/index';
import { themeDevTools } from '@lib/tools/devtools/components/theme';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { languages } from '@lib/tools/locale/utils/consts';

import 'json-diff-kit/dist/viewer.css';

export const LocalePanel = () => {
  const dispatch = useDispatch();

  const { locale, isLocaleEditMode } = useApp();
  const { isUserAuthenticated, userGroups } = useUser();

  type IFilterOptions = 'all' | 'translated' | 'untranslated' | 'draft';
  type ILocaleOptions = 'draft' | 'diff';

  const { retailerLanguages } = useRetailer();

  const [isDiffView, toggleDiffView] = useState(false);
  const [tableData, setTableData] = useState<ILocaleTableDataRow[]>();
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState<IFilterOptions>('all');
  const [draftTerms, setDraftTerms] = useState({});

  const [defaultTerms, setDefaultTerms] = useState({});
  const [publishedTerms, setPublishedTerms] = useState<Record<string, string>>({});
  const [diff, setDiff] = useState<readonly [DiffResult[], DiffResult[]]>();
  // const [snackbarMessage, setSnackbarMessage] = useState<string>();

  const [isPublishSnackbarOpen, togglePublishSnackbar] = useState(false);

  const isUserEditor = userGroups?.some(userGroup => userGroup?.name === 'Static Editor');

  const [localeToggles, setLocaleToggles] = useState<ILocaleOptions[]>();

  const optionsLanguages = [
    <option key="" disabled>
      select locale
    </option>,
  ];

  retailerLanguages.forEach((lang, i) => {
    optionsLanguages.push(
      <option key={i} value={lang.code}>
        {lang.code} - {lang.language}
      </option>,
    );
  });

  // ! improve redundant LanguageSelector in the AppPanel
  const LanguageSelect = (
    <select name="language" value={locale} onChange={e => dispatch(setServiceLocale(e.target.value))}>
      {optionsLanguages}
    </select>
  );

  // ! Move search and filtering into table component
  useMemo(async () => {
    if (isLocaleEditMode && locale) {
      const publishedDefaultTerms = await LocaleUtils.getPublishedTerms(languages.ENGLISH);

      setDefaultTerms(publishedDefaultTerms);
      LocaleUtils.getDraftTerms(locale, setDraftTerms);
    }
  }, [isLocaleEditMode]);

  // ! Improve toggles
  useEffect(() => {
    const isDiffMode = localeToggles?.includes('diff') || false;
    const isEditMode = (locale !== languages.ENGLISH && localeToggles?.includes('draft')) || false;
    toggleDiffView(isDiffMode);
    dispatch(toggleLocaleEditMode(isEditMode));
  }, [localeToggles]);

  useMemo(async () => {
    setPublishedTerms(await LocaleUtils.getPublishedTerms(locale));

    if (Object.keys(publishedTerms).length > 0) {
      // ? sort
      const rows: any = new Set();
      const contexts = Object.keys(defaultTerms);

      // ! Improve
      const searchFilter = (row: ILocaleTableDataRow) =>
        searchText.length
          ? row.context?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase()) ||
            row.defaultText?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase()) ||
            row.publishedText?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase()) ||
            row.draftText?.toLocaleLowerCase()?.includes(searchText.toLocaleLowerCase())
          : true;

      contexts.forEach(context => {
        const defaultText = defaultTerms[context];
        const publishedText = publishedTerms[context];
        const draftText = draftTerms[context];

        const isUnpublished = draftText?.length && draftText !== publishedText;
        const isTranslated = publishedText?.length || isUnpublished;

        const rowData = { context, defaultText, draftText, publishedText };

        if (filterType === 'translated' && isTranslated) {
          rows.add(rowData);
        } else if (filterType === 'untranslated' && !isTranslated) {
          rows.add(rowData);
        } else if (filterType === 'all') {
          rows.add(rowData);
        } else if (filterType === 'draft' && isUnpublished) {
          rows.add(rowData);
        }
      });

      const rowsArray: ILocaleTableDataRow[] = Array.from(rows);
      setTableData(rowsArray.filter(searchFilter));
      setDiff(LocaleUtils.generateDiff(publishedTerms, draftTerms));
    }
  }, [searchText, filterType, draftTerms, defaultTerms]);

  const FilterSelect = (
    // @ts-ignore
    <select name="page" onChange={e => setFilterType(e.target.value)}>
      <option key={0} value="all">
        {`all (${Object.keys(defaultTerms).length})`}
      </option>
      <option key={1} value="draft">
        draft
      </option>
      <option key={2} value="translated">
        translated
      </option>
      <option key={3} value="untranslated">
        untranslated
      </option>
    </select>
  );

  return (
    <>
      {/* ? Allow force RTL */}
      <div className="panel collapsible" role="presentation">
        Locale
      </div>

      <Box sx={{ margin: themeDevTools.spacing.medium }}>
        {!isUserAuthenticated && <LoginForm />}

        {isUserAuthenticated && isUserEditor && (
          <>
            <div className="p-2">
              <ToggleButtonGroup
                aria-label="locale-toggles"
                value={localeToggles}
                onChange={(_, options) => {
                  setLocaleToggles(options);
                }}
              >
                <StyledTooltip placement="bottom" title="Draft">
                  <ToggleButton
                    aria-label="draft"
                    value="draft"
                    sx={{
                      '&.Mui-selected, &.Mui-selected:hover': {
                        background: themeDevTools.color.orange,
                      },
                      '&:hover': {
                        background: themeDevTools.color.orange,
                      },
                    }}
                  >
                    <ModeEditIcon
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover': {
                          color: themeDevTools.color.black,
                        },
                        color: themeDevTools.color.white,
                        height: themeDevTools.spacing.large,
                        width: themeDevTools.spacing.large,
                      }}
                    />
                  </ToggleButton>
                </StyledTooltip>

                <StyledTooltip placement="bottom" title="View Diff">
                  <ToggleButton
                    aria-label="diff"
                    value="diff"
                    sx={{
                      '&.Mui-selected, &.Mui-selected:hover': {
                        background: themeDevTools.color.orange,
                      },
                      '&:hover': { background: themeDevTools.color.orange },
                    }}
                  >
                    <DifferenceIcon
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover': {
                          color: themeDevTools.color.black,
                        },
                        color: themeDevTools.color.white,
                        height: themeDevTools.spacing.large,
                        width: themeDevTools.spacing.large,
                      }}
                    />
                  </ToggleButton>
                </StyledTooltip>
              </ToggleButtonGroup>
            </div>

            <div className="panel">
              <span className="title">Language</span>
              {LanguageSelect}
            </div>

            {isDiffView && <Viewer highlightInlineDiff lineNumbers diff={diff} indent={1} />}

            {/* // ! Add search box feature for looking up firebase terms */}
            {isLocaleEditMode && (
              <>
                <div className="panel">
                  <div className="my-2">
                    <input placeholder="search" onChange={e => setSearchText(e.target.value)} />
                    {FilterSelect}
                  </div>
                  <div className="my-1">
                    <TableComponent
                      rowData={tableData}
                      rowType="LocaleRow"
                      tableHeadInfo={['context', languages.ENGLISH, locale]}
                    />
                  </div>
                  <Stack direction="row" spacing={2}>
                    <Button onClick={() => LocaleUtils.importTerms()}>Import</Button>
                    <Button onClick={() => LocaleUtils.exportTerms(tableData)}>Export</Button>
                    <Button
                      disabled={diff?.[0]?.length <= 2}
                      onClick={() => {
                        LocaleUtils.publishTerms();
                        // ! TODO: Invoke snackbar only upon successful publishing of terms
                        togglePublishSnackbar(true);
                      }}
                    >
                      Publish
                    </Button>
                  </Stack>

                  <SnackbarAlert
                    description=""
                    handleSnackbarOpen={togglePublishSnackbar}
                    isOpen={isPublishSnackbarOpen}
                    title="Published terms for current locale and invalidated distributions."
                  />
                </div>
              </>
            )}

            <div className="panel">
              <Button onClick={() => dispatch(actionResetAuthSlice())}>Logout</Button>
            </div>
          </>
        )}
      </Box>
    </>
  );
};
