import cn from 'classnames';
import { FC, Fragment } from 'react';

import { TProductEssenceScore } from '@lib/core/products/types';
import { useApp } from '@lib/core/service/hooks';

export interface EnhancerProductCardProps {
  color: string;
  essences: TProductEssenceScore[];
}

const EssencesBlock: FC<EnhancerProductCardProps> = ({ essences, color }) => {
  const { isLayoutRightToLeft } = useApp();

  return (
    <div className="ta-v2-bar-essences-wrapper">
      {essences.map((essence, i) => (
        <Fragment key={i}>
          {parseInt(essence.score, 10) > 0 && (
            <div className="ta-v2-bar-essence-wrapper">
              <div className="ta-v2-bar-essence-text font-ta-v2-global-regulatory-text-small">{essence.name}</div>
              <div className="ta-v2-bar-essence-score-wrapper">
                {new Array(5).fill(null).map((_, index) => (
                  <div
                    key={index}
                    style={{ backgroundColor: parseInt(essence.score, 10) >= index + 1 ? color : '#D9D1C8' }}
                    className={cn('ta-v2-bar-essences-score', {
                      'rtl-orientation': isLayoutRightToLeft,
                    })}
                  />
                ))}
              </div>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default EssencesBlock;
