// ToDo we don't need to type actions, we can remove PayloadAction from all slices because store typed properly now

import { createSlice } from '@reduxjs/toolkit';

import { getProductsListData } from '@lib/core/products/utils';
import { PRODUCT_REQUEST_FOR_HOME_PAGE, PRODUCT_REQUEST_FOR_RESULT_PAGE } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { IFetchSwiperProductsPayload, IProductsSwiperState } from '@lib/tools/productsSwiper/types/interface';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_QUERY,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';
import { getUniqueItemForToday } from '@lib/tools/shared/helpers/utils';

// ToDo refactor idea: system which should be able to fetch, save and clean certain amount of products based on key
// For example: we want to have 3 product for b2c welcome, we create enum [productsSets.B2CWelcome] and start logic by
// provide it as identifier in welcome container. Using same identifiers we will be able also to clean any set of them
export const fetchSwiperProducts = createTypedAsyncThunk(
  'table/fetchSwiperProducts',
  async ({ params, productResultFor }: IFetchSwiperProductsPayload) => {
    const json = await getProductsListData(params);
    const { results } = json;
    const productResults =
      productResultFor === PRODUCT_REQUEST_FOR_HOME_PAGE
        ? getUniqueItemForToday({ itemArray: results, returnNumberOfUniqueItemForDay: 3 })
        : results;
    return {
      [PRODUCT_CATEGORY_QUERY]: params[PRODUCT_CATEGORY_QUERY],
      json,
      productResultFor,
      products: productResults,
    };
  },
);

export const initialState: IProductsSwiperState = {
  isProductsFetchedFor: {
    [PRODUCT_REQUEST_FOR_HOME_PAGE]: false,
    [PRODUCT_REQUEST_FOR_RESULT_PAGE]: false,
  },
  isProductsRequestLoading: {
    [PRODUCT_CATEGORY_BEER]: false,
    [PRODUCT_CATEGORY_COFFEE]: false,
    [PRODUCT_CATEGORY_WINE]: false,
  },
  productsCount: {
    [PRODUCT_CATEGORY_BEER]: null,
    [PRODUCT_CATEGORY_COFFEE]: null,
    [PRODUCT_CATEGORY_WINE]: null,
  },
  productsData: {
    [PRODUCT_CATEGORY_BEER]: [],
    [PRODUCT_CATEGORY_COFFEE]: [],
    [PRODUCT_CATEGORY_WINE]: [],
  },
  productsRequestError: '',
};

export const productSwiperSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchSwiperProducts.pending, (state, action) => {
      // @ts-ignore
      const productCategory = action.meta?.arg[PRODUCT_CATEGORY_QUERY];
      state.isProductsRequestLoading = { ...state.isProductsRequestLoading, [productCategory]: true };
    });
    builder.addCase(fetchSwiperProducts.fulfilled, (state, action) => {
      const productCategory = action.payload[PRODUCT_CATEGORY_QUERY];
      const productResultFor = action.payload?.productResultFor;
      state.isProductsRequestLoading = { ...state.isProductsRequestLoading, [productCategory]: false };
      state.productsData = { ...state.productsData, [productCategory]: action.payload.products };
      state.productsCount = { ...state.productsCount, [productCategory]: action.payload.json.count };
      state.isProductsFetchedFor = { ...state.isProductsFetchedFor, [productResultFor]: true };
    });
    builder.addCase(fetchSwiperProducts.rejected, (state, action: any) => {
      const productCategory = action.meta?.arg[PRODUCT_CATEGORY_QUERY];
      if (action.payload?.errorMessage) {
        state.productsRequestError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.productsRequestError = action.error.message;
      }
      state.isProductsRequestLoading = { ...state.isProductsRequestLoading, [productCategory]: false };
    });
  },
  initialState,
  name: 'productsSwiper',
  reducers: {
    resetProductSwiperState: state => {
      state.isProductsRequestLoading = initialState.isProductsRequestLoading;
      state.productsData = initialState.productsData;
      state.isProductsFetchedFor = initialState.isProductsFetchedFor;
    },
  },
});

export default productSwiperSlice.reducer;
export const { resetProductSwiperState } = productSwiperSlice.actions;
