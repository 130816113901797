import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IB2CExperience } from '@app/native/src/interfaces/experience';

import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import request from '@lib/core/service/requests/request';

interface IExperienceRequestData {
  count: number | undefined;
  results: IB2CExperience[];
  next: string;
  previous: string;
}

interface IExperienceSearchState {
  experienceRequestError: string;
  experiencesData: IExperienceRequestData;
  isExperienceRequestLoading: boolean;
}

const initialExperienceSearchState: IExperienceSearchState = {
  experienceRequestError: '',
  experiencesData: {
    count: undefined,
    next: '',
    previous: '',
    results: [],
  },
  isExperienceRequestLoading: false,
};

interface IFetchExperiencesOptions {
  params: {
    page?: number;
    page_size?: number;
    product_type?: string;
    experience_type: string;
  };
  isPagination?: boolean;
}

export const fetchExperiences = createAsyncThunk(
  'experienceSearch/fetchExperiences',
  async ({ params }: IFetchExperiencesOptions) => {
    return await request(backendApiUrls.apiUrlB2CExperiencesLists, {
      params,
    });
  },
);

export const experienceSearchSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchExperiences.pending, (state, action) => {
      const { arg: { isPagination = false } = {} } = action.meta;
      if (!isPagination) {
        state.experiencesData = {
          count: undefined,
          next: '',
          previous: '',
          results: [],
        };
      }

      state.isExperienceRequestLoading = true;
    });
    builder.addCase(fetchExperiences.fulfilled, (state, action) => {
      const { results } = action.payload;
      state.isExperienceRequestLoading = false;

      state.experiencesData = {
        ...action.payload,
        results: [...state.experiencesData.results, ...results],
      };
    });
    builder.addCase(fetchExperiences.rejected, (state, action: any) => {
      if (action.payload?.errorMessage) {
        state.experienceRequestError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.experienceRequestError = action.error.message;
      }
      state.isExperienceRequestLoading = false;
    });
  },
  initialState: initialExperienceSearchState,
  name: 'table',
  reducers: {},
});

export default experienceSearchSlice.reducer;
