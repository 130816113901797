export const RETAILER_SLUG_ROMANIA = 'romania';
export const RETAILER_SLUG_JAPAN = 'japan';

// Types of store
// ! Convert to selectors
export const RetailerLocationStoreType = {
  dutyFree: 'duty-free',
  ecommerce: 'e-commerce',
  events: 'events',
  main: 'main',
} as const;
