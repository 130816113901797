import { createSelector } from 'reselect';

import { IUserProductPreferencesSlice } from '@lib/core/products/slices/userProductPreferences';
import { IUserProductPreferences } from '@lib/core/products/types/userProductsPreferences';

const selectUserProductPreferencesState = (state): IUserProductPreferencesSlice =>
  state?.productsRoot?.userProductPreferences || {};

export const selectUserProductPreferences: (state) => IUserProductPreferences[] = createSelector(
  [selectUserProductPreferencesState],
  userProductPreferencesSlice => userProductPreferencesSlice?.data || [],
);
