import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { retailerLocationDataApiUrlCreator } from '@lib/core/retailers/slices/urls';
import { IRetailerLocationsRequestParams } from '@lib/core/retailers/types';
import { IRetailerLocation } from '@lib/core/retailers/types/retailerLocation';
import { PRODUCER_IDENTIFIER_QUERY } from '@lib/core/service/consts';
import request from '@lib/core/service/requests/request';

export interface IExploreLocationsState {
  isLoading: boolean;
  list: IRetailerLocation[];
}

const initialState: IExploreLocationsState = {
  isLoading: false,
  list: [],
};

export const actionGetExploreLocations = createAsyncThunk(
  'exploreLocations/get',
  async ({ retailerSlug, producerId }: IRetailerLocationsRequestParams) => {
    const apiPath = retailerLocationDataApiUrlCreator({ retailerSlug });
    const config = {
      params: {
        ...(producerId && { [PRODUCER_IDENTIFIER_QUERY]: producerId }),
      },
    };

    const response = await request(apiPath, config);
    return { list: response.length ? response : [] };
  },
);

export const exploreLocationsSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(actionGetExploreLocations.pending, state => {
        state.isLoading = true;
      })
      .addCase(actionGetExploreLocations.fulfilled, (state, action: PayloadAction<{ list?: IRetailerLocation[] }>) => {
        const { list } = action.payload;
        state.isLoading = false;
        state.list = list;
      })
      .addCase(actionGetExploreLocations.rejected, state => {
        state.isLoading = false;
      });
  },
  initialState,
  name: 'exploreLocations',
  reducers: {},
});

export default exploreLocationsSlice.reducer;
