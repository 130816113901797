import cn from 'classnames';
import { FC } from 'react';

import { ReactComponent as Close } from '@app/pmi/src/assets/media/V2/v2_close_white.svg';

interface Props {
  btnText: string | JSX.Element;
  isDark: boolean;
  onBtnClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const ButtonClose: FC<Props> = ({ isDark, btnText, onBtnClick, className, disabled }) => {
  return (
    <button
      disabled={disabled}
      type="button"
      className={cn('ta-v2-cta-btn-close', className, {
        'ta-v2-cta-btn-close-dark': isDark,
      })}
      onClick={onBtnClick}
    >
      <span className="font-ta-v2-global-body-large">{btnText}</span>
      <span className="ta-v2-cta-btn-icon">
        <Close className={cn({ isDark })} />
      </span>
    </button>
  );
};

export default ButtonClose;
