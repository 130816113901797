import { useSelector } from 'react-redux';

import {
  selectProductSetProducts,
  selectProductSetsIds,
  selectProductSetsImages,
  selectProductSetsIsFetching,
  selectProductSetsProductListIsFetching,
} from '@lib/core/products/selectors/productSets';
import { TProductSetsProducts } from '@lib/core/products/types';

type IProductSets = {
  isProductSetsFetching: boolean;
  isProductSetsProductListIsFetching: boolean;
  productSetProducts: TProductSetsProducts[];
  productSetIds: any[];
  productSetsImages: string[];
};

export const useProductSets = (): IProductSets => {
  return {
    isProductSetsFetching: useSelector(selectProductSetsIsFetching),
    isProductSetsProductListIsFetching: useSelector(selectProductSetsProductListIsFetching),

    productSetIds: useSelector(selectProductSetsIds),
    productSetProducts: useSelector(selectProductSetProducts),
    productSetsImages: useSelector(selectProductSetsImages),
  };
};
