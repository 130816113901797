import { createSelector } from 'reselect';

import { IQuizzesSlice } from '@lib/core/quizzes/slices/quizzes';
import { TQuiz } from '@lib/core/quizzes/types';

/**
 * @returns the whole quizzes state
 */
const selectQuizzesState = (state): IQuizzesSlice => state.quizzes;

/**
 * @returns the list of quizzes assigned to the retailer
 */
export const selectQuizzes: (state) => TQuiz[] = createSelector([selectQuizzesState], quizzes => quizzes.data || []);

/**
 * @returns loading state of quizzes requests
 */
export const selectQuizzesFetching: (state) => boolean = createSelector(
  [selectQuizzesState],
  quizzes => quizzes.isLoading || false,
);
