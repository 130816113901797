import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import { IRemoteAccessTableDataRow } from '@lib/tools/devtools/components/interfaces';

class RemoteAccessUtils {
  public static exportTerms = async (tableData: IRemoteAccessTableDataRow[]) => {
    const state = store.getState();
    const retailerSlug = selectRetailerSlug(state);
    const serviceProduct = selectServiceProductCategory(state);
    const headers = ['custom_id', 'initial_link'];

    const csvRows = [headers];

    tableData.forEach(row => {
      csvRows.push([row.customId, row.link]);
    });

    const csvData = csvRows.join('\n');
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `links_${retailerSlug}_${serviceProduct}.csv`;
    link.click();
  };
}

export default RemoteAccessUtils;
