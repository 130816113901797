import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';

import { AppStateType } from '@lib/core/service/types/appStateType';

type TypedAsyncThunk<ReturnedType, ArgType> = AsyncThunk<ReturnedType, ArgType, { state: AppStateType }>;

function createTypedAsyncThunk<ReturnedType, ArgType = void>(
  typePrefix: string,
  payloadCreator: (
    args: ArgType | undefined,
    thunkAPI: TypedAsyncThunk<ReturnedType, ArgType> & any, // ToDo under development
  ) => Promise<ReturnedType>,
) {
  return createAsyncThunk<ReturnedType, ArgType | undefined>(typePrefix, payloadCreator);
}

export { createTypedAsyncThunk };
