/* eslint-disable no-dupe-else-if */
/* eslint-disable radix */
/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable max-classes-per-file */

interface PropellerOptions {
  angle?: number;
  inertia?: number;
  minimalAngleChange?: number;
  minimalSpeed?: number;
  rotateParentInstantly?: boolean;
  speed?: number;
  step?: number;
  stepTransitionEasing?: string;
  stepTransitionTime?: number;
  touchElement?: string | HTMLElement;
  onRotate?: (angle: number) => void;
  onStop?: (angle: number) => void;
  onDragStop?: (angle: number) => void;
  onDragStart?: (angle: number, index: number) => void;
}

interface ViewOffset {
  x: number;
  y: number;
}

interface PropellerDefaults extends PropellerOptions {
  rotateParentInstantly: boolean;
}

const defaults: PropellerDefaults = {
  angle: 0,
  inertia: 0,
  minimalAngleChange: 0.1,
  minimalSpeed: 0.001,
  rotateParentInstantly: false,
  speed: 0,
  step: 0,
  stepTransitionEasing: 'linear',
  stepTransitionTime: 0,
  touchElement: null,
};

export class Propeller {
  private element: HTMLElement;

  private active = false;

  private transiting = false;

  private lastMouseEvent?: { pageX: number; pageY: number };

  private cx = 0;

  private cy = 0;

  private accelerationPostfix = '';

  private listenersInstalled = false;

  private lastAppliedAngle = 0;

  private virtualAngle = 0;

  private _angle = 0;

  private speed = 0;

  private minimalAngleChange = 0.1;

  private rotateParentInstantly = false;

  onRotate: any;

  step: number;

  inertia: number;

  minimalSpeed: number;

  onStop: any;

  lastMouseAngle: any;

  lastElementAngle: number;

  stepTransitionTime: any;

  mouseDiff: number;

  touchElement: HTMLElement;

  onDragStop: (value, index) => void;

  onDragStart: (value, index) => void;

  stepTransitionEasing: any;

  angle: any;

  constructor(element: string | HTMLElement | NodeList, options?: PropellerOptions) {
    if (typeof element === 'string') {
      element = document.querySelectorAll(element);
    }

    if (element instanceof NodeList) {
      element = element[0] as HTMLElement;
    }

    if (element instanceof HTMLElement) {
      this.element = element;
      this.active = false;
      this.transiting = false;
      this.update = this.update.bind(this);

      this.initCSSPrefix();
      this.initAngleGetterSetter();
      this.initOptions(options);
      this.initHardwareAcceleration();
      this.initTransition();
      this.bindHandlers();
      this.addListeners();
      this.update();
    } else {
      throw new Error('Invalid element provided to Propeller constructor.');
    }
  }

  initAngleGetterSetter() {
    // throw new Error('Method not implemented.');
  }

  static cssPrefix: string | undefined;

  static deg2radians: number = (Math.PI * 2) / 360;

  update(): void {
    if (this.lastMouseEvent !== undefined && this.active === true) {
      this.updateAngleToMouse(this.lastMouseEvent);
    }

    this.updateAngle();
    this.applySpeed();
    this.applyInertia();

    if (Math.abs(this.lastAppliedAngle - this._angle) >= this.minimalAngleChange && this.transiting === false) {
      this.updateCSS();
      this.blockTransition();

      if (this.onRotate !== undefined && typeof this.onRotate === 'function') {
        this.onRotate.bind(this)(this._angle, 0);
      }

      this.lastAppliedAngle = this._angle;
    }

    window.requestAnimationFrame(this.update);
  }

  private updateAngle(): void {
    if (this.step > 0) {
      this._angle = this.getAngleFromVirtual();
    } else {
      this._angle = this.normalizeAngle(this.virtualAngle);
    }
  }

  private getAngleFromVirtual(): number {
    return Math.ceil(this.virtualAngle / this.step) * this.step;
  }

  private normalizeAngle(angle: number): number {
    let result = angle;
    result %= 360;
    if (result < 0) {
      result = 360 + result;
    }
    return result;
  }

  private differenceBetweenAngles(newAngle: number, oldAngle: number): number {
    const a1 = newAngle * (Math.PI / 180);
    const a2 = oldAngle * (Math.PI / 180);
    const radians = Math.atan2(Math.sin(a1 - a2), Math.cos(a1 - a2));
    const degrees = radians * (180 / Math.PI);
    return Math.round(degrees * 100) / 100;
  }

  private applySpeed(): void {
    if (this.inertia > 0 && this.speed !== 0 && this.active === false) {
      this.virtualAngle += this.speed;
    }
  }

  private applyInertia(): void {
    if (this.inertia > 0) {
      if (Math.abs(this.speed) >= this.minimalSpeed) {
        this.speed *= this.inertia;

        if (this.active === false && Math.abs(this.speed) < this.minimalSpeed) {
          if (this.onStop !== undefined) {
            this.onStop();
          }
        }
      } else if (this.speed !== 0) {
        this.speed = 0;
      }
    }
  }

  private updateAngleToMouse(event: { pageX: number; pageY: number }): void {
    const xDiff = event.pageX - this.cx;
    const yDiff = event.pageY - this.cy;

    const mouseRadians = Math.atan2(xDiff, yDiff);
    const mouseDegrees = mouseRadians * ((180 / Math.PI) * -1) + 180;

    if (this.lastMouseAngle === undefined) {
      this.lastElementAngle = this.virtualAngle;
      this.lastMouseAngle = mouseDegrees;
    }

    if (this.stepTransitionTime !== defaults.stepTransitionTime) {
      this.speed = this.mouseDiff = this.differenceBetweenAngles(mouseDegrees, this.lastMouseAngle);
      this.virtualAngle = this.lastElementAngle + this.mouseDiff;
      this.lastElementAngle = this.virtualAngle;
      this.lastMouseAngle = mouseDegrees;
    } else {
      const oldAngle = this.virtualAngle;
      this.mouseDiff = mouseDegrees - this.lastMouseAngle;
      this.virtualAngle = this.lastElementAngle + this.mouseDiff;
      const newAngle = this.virtualAngle;
      this.speed = this.differenceBetweenAngles(newAngle, oldAngle);
    }
  }

  private initCoordinates(): void {
    const elementOffset = this.getViewOffset();
    this.cx = elementOffset.x + this.element.offsetWidth / 2;
    this.cy = elementOffset.y + this.element.offsetHeight / 2;
  }

  private initDrag(): void {
    this.speed = 0;
    this.lastMouseAngle = undefined;
    this.lastElementAngle = undefined;
    this.lastMouseEvent = undefined;
  }

  private initOptions(options: PropellerOptions): void {
    options = options || defaults;

    // @ts-ignore
    this.touchElement = document.querySelectorAll(options.touchElement)[0] || this.element;

    this.onRotate = options.onRotate;
    this.onStop = options.onStop;
    this.onDragStop = options.onDragStop;
    this.onDragStart = options.onDragStart;

    this.step = options.step || defaults.step;
    this.stepTransitionTime = options.stepTransitionTime || defaults.stepTransitionTime;
    this.stepTransitionEasing = options.stepTransitionEasing || defaults.stepTransitionEasing;

    this.angle = options.angle || defaults.angle;
    this.speed = options.speed || defaults.speed;
    this.inertia = options.inertia || defaults.inertia;
    this.minimalSpeed = options.minimalSpeed || defaults.minimalSpeed;
    this.lastAppliedAngle = this.virtualAngle = this._angle = options.angle || defaults.angle;
    this.minimalAngleChange = this.step !== defaults.step ? this.step : defaults.minimalAngleChange;
    this.rotateParentInstantly = options.rotateParentInstantly || defaults.rotateParentInstantly;
  }

  private initCSSPrefix(): void {
    if (Propeller.cssPrefix === undefined) {
      if (typeof document.body.style.transform !== 'undefined') {
        Propeller.cssPrefix = '';
      } else if (typeof document.body.style.transform !== 'undefined') {
        Propeller.cssPrefix = '-moz-';
      } else if (typeof document.body.style.webkitTransform !== 'undefined') {
        Propeller.cssPrefix = '-webkit-';
      } else if (typeof document.body.style.transform !== 'undefined') {
        Propeller.cssPrefix = '-ms-';
      }
    }
  }

  private initHardwareAcceleration(): void {
    this.accelerationPostfix = '';
    const el = document.createElement('p');
    let has3d;
    const transforms = {
      MozTransform: '-moz-transform',
      OTransform: '-o-transform',
      msTransform: '-ms-transform',
      transform: 'transform',
      webkitTransform: '-webkit-transform',
    };

    document.body.insertBefore(el, null);

    // eslint-disable-next-line no-restricted-syntax
    for (const t in transforms) {
      if (el.style[t] !== undefined) {
        el.style[t] = 'translate3d(1px,1px,1px)';
        has3d = window.getComputedStyle(el).getPropertyValue(transforms[t]);
      }
    }

    document.body.removeChild(el);

    const supported = has3d !== undefined && has3d.length > 0 && has3d !== 'none';

    if (supported === true) {
      this.accelerationPostfix = 'translateZ(0)';
      this.element.style[`${Propeller.cssPrefix}transform`] = this.accelerationPostfix;
      this.updateCSS();
    }
  }

  private initTransition(): void {
    if (this.stepTransitionTime !== defaults.stepTransitionTime) {
      const prop = `all ${this.stepTransitionTime}ms ${this.stepTransitionEasing}`;
      this.element.style[`${Propeller.cssPrefix}transition`] = prop;
    }
  }

  private updateCSS(): void {
    this.element.style[`${Propeller.cssPrefix}transform`] = `rotate(${this._angle}deg) ${this.accelerationPostfix}`;
  }

  private blockTransition(): void {
    if (this.stepTransitionTime !== defaults.stepTransitionTime) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      setTimeout(() => {
        self.transiting = false;
      }, this.stepTransitionTime);
      this.transiting = true;
    }
  }

  private getViewOffset(): ViewOffset {
    const coords: ViewOffset = { x: 0, y: 0 };

    if (this.element)
      // @ts-ignore
      this.addOffset(this.element, coords, 'defaultView' in document ? document.defaultView : document.parentWindow);

    return coords;
  }

  private addOffset(node: HTMLElement, coords: ViewOffset, view: Window | undefined): void {
    // @ts-ignore
    const p: HTMLElement = node.offsetParent;
    coords.x += node.offsetLeft - (p ? p.scrollLeft : 0);
    coords.y += node.offsetTop - (p ? p.scrollTop : 0);

    if (p) {
      if (p.nodeType === 1) {
        const parentStyle = view?.getComputedStyle(p, '') || window.getComputedStyle(p, '');
        if (parentStyle.position !== 'static') {
          coords.x += parseInt(parentStyle.borderLeftWidth || '0');
          coords.y += parseInt(parentStyle.borderTopWidth || '0');

          if (p.localName?.toLowerCase() === 'table') {
            coords.x += parseInt(parentStyle.paddingLeft || '0');
            coords.y += parseInt(parentStyle.paddingTop || '0');
          } else if (p.localName?.toLowerCase() === 'body') {
            const style = view?.getComputedStyle(node, '') || window.getComputedStyle(node, '');
            coords.x += parseInt(style.marginLeft || '0');
            coords.y += parseInt(style.marginTop || '0');
          }
        } else if (p.localName?.toLowerCase() === 'body') {
          coords.x += parseInt(parentStyle.borderLeftWidth || '0');
          coords.y += parseInt(parentStyle.borderTopWidth || '0');
        }

        let parent: Partial<HTMLElement> = node.parentNode;
        while (p !== parent) {
          coords.x -= parent?.scrollLeft || 0;
          coords.y -= parent?.scrollTop || 0;
          parent = parent?.parentNode;
        }
        this.addOffset(p, coords, view);
      }
    } else {
      if (node.localName?.toLowerCase() === 'body') {
        const style = view?.getComputedStyle(node, '') || window.getComputedStyle(node, '');
        coords.x += parseInt(style.borderLeftWidth || '0');
        coords.y += parseInt(style.borderTopWidth || '0');

        const htmlStyle =
          view?.getComputedStyle(node.parentNode as Element, '') ||
          window.getComputedStyle(node.parentNode as Element, '');
        coords.x += parseInt(htmlStyle.paddingLeft || '0');
        coords.y += parseInt(htmlStyle.paddingTop || '0');
        coords.x += parseInt(htmlStyle.marginLeft || '0');
        coords.y += parseInt(htmlStyle.marginTop || '0');
      }

      if (node.scrollLeft) coords.x += node.scrollLeft;
      if (node.scrollTop) coords.y += node.scrollTop;

      const win = node.ownerDocument?.defaultView;
      if (win && win.frameElement) this.addOffset(win.frameElement as HTMLElement, coords, win);
    }
  }

  private returnFalse(): boolean {
    return false;
  }

  private bindHandlers(): void {
    this.onRotationStart = this.onRotationStart.bind(this);
    this.onRotationStop = this.onRotationStop.bind(this);
    this.onRotated = this.onRotated.bind(this);
  }

  private addListeners(): void {
    this.listenersInstalled = true;

    if ('ontouchstart' in document.documentElement) {
      this.touchElement.addEventListener('touchstart', this.onRotationStart);
      this.touchElement.addEventListener('touchmove', this.onRotated);
      this.touchElement.addEventListener('touchend', this.onRotationStop);
      this.touchElement.addEventListener('touchcancel', this.onRotationStop);
      this.touchElement.addEventListener('dragstart', this.returnFalse);
    } else {
      this.touchElement.addEventListener('mousedown', this.onRotationStart);
      this.touchElement.addEventListener('mousemove', this.onRotated);
      this.touchElement.addEventListener('mouseup', this.onRotationStop);
      this.touchElement.addEventListener('mouseleave', this.onRotationStop);
      this.touchElement.addEventListener('dragstart', this.returnFalse);
    }

    this.touchElement.ondragstart = this.returnFalse;
  }

  private removeListeners(): void {
    this.listenersInstalled = false;

    if ('ontouchstart' in document.documentElement) {
      this.touchElement.removeEventListener('touchstart', this.onRotationStart);
      this.touchElement.removeEventListener('touchmove', this.onRotated);
      this.touchElement.removeEventListener('touchend', this.onRotationStop);
      this.touchElement.removeEventListener('touchcancel', this.onRotationStop);
      this.touchElement.removeEventListener('dragstart', this.returnFalse);
    } else {
      this.touchElement.removeEventListener('mousedown', this.onRotationStart);
      this.touchElement.removeEventListener('mousemove', this.onRotated);
      this.touchElement.removeEventListener('mouseup', this.onRotationStop);
      this.touchElement.removeEventListener('mouseleave', this.onRotationStop);
      this.touchElement.removeEventListener('dragstart', this.returnFalse);
    }
  }

  bind(): void {
    if (this.listenersInstalled !== true) {
      this.addListeners();
    }
  }

  unbind(): void {
    if (this.listenersInstalled === true) {
      this.removeListeners();
      this.onRotationStop();
    }
  }

  stop(): void {
    this.speed = 0;
    this.onRotationStop();
  }

  onRotationStart(event: TouchEvent | MouseEvent): void {
    this.initCoordinates();
    this.initDrag();
    this.active = true;

    if (this.onDragStart !== undefined) {
      this.onDragStart(this._angle, 0);
    }

    if (this.rotateParentInstantly === false) {
      event.stopPropagation();
    }
  }

  onRotationStop(): void {
    if (this.onDragStop !== undefined && this.active === true) {
      this.onDragStop(this.angle, 0);
    }

    this.active = false;
  }

  onRotated(event: TouchEvent | MouseEvent): void {
    if (this.active === true) {
      event.stopPropagation();
      event.preventDefault();

      // @ts-ignore
      if (event.targetTouches !== undefined && event.targetTouches[0] !== undefined) {
        this.lastMouseEvent = {
          // @ts-ignore
          pageX: event.targetTouches[0].pageX,
          // @ts-ignore
          pageY: event.targetTouches[0].pageY,
        };
      } else {
        this.lastMouseEvent = {
          // @ts-ignore
          pageX: event.pageX || event.clientX,
          // @ts-ignore
          pageY: event.pageY || event.clientY,
        };
      }
    }
  }
}

export const initPropeller = (w: Window): void => {
  class PropellerInstance extends Propeller {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(element: string | HTMLElement | NodeList, options?: PropellerOptions) {
      super(element, options);
    }
  }

  PropellerInstance.cssPrefix = undefined;

  PropellerInstance.deg2radians = (Math.PI * 2) / 360;

  // @ts-ignore
  w.Propeller = PropellerInstance;

  // RequestAnimatedFrame polyfill
  // @ts-ignore
  w.requestAnimFrame =
    w.requestAnimationFrame ||
    // @ts-ignore
    w.webkitRequestAnimationFrame ||
    // @ts-ignore
    w.mozRequestAnimationFrame ||
    ((callback: FrameRequestCallback) => {
      w.setTimeout(callback, 1000 / 60);
    });
};
