import { createSelector } from 'reselect';

import { ICharactersSlice } from '@lib/core/characters/slices';
import { TCharacter } from '@lib/core/characters/types';

/**
 * @returns the characters slice state
 */
export const selectCharactersState = (state): ICharactersSlice => state.characters || {};

/**
 * @returns all characters array
 */
export const selectCharacters: (state) => TCharacter[] = createSelector(
  [selectCharactersState],
  characters => characters.data || [],
);

/**
 * @returns loading state for characters
 */
export const selectIsCharactersLoading: (state) => boolean = createSelector(
  [selectCharactersState],
  characters => characters.isLoading || false,
);
