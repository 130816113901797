import { getRequestCommonParameters } from '@lib/core/service/requests/api';

/**
 * Get wellness data
 */
export const apiUrlGetWellness = '/product-attributes/wellness/';

/**
 * Get recycling data
 */
export const apiUrGetRecycling = '/product-attributes/recycling/';

/**
 * Get recipes data
 */
export const apiUrlGetRecipes = () => {
  const { retailerSlug } = getRequestCommonParameters();
  return `/product-attributes/${retailerSlug}/recipes/`;
};
