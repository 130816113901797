import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useEffectSkipFirst = (effect: EffectCallback, depValue: DependencyList): void => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      effect();
    }
  }, [...depValue]);
};
