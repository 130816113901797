import { RootState } from '@lib/core/service/types/appStateType';

/**
 * Selects the state of the Facebook Pixel from the Redux store.
 * If the state is not available, it returns the defaultValue.
 *
 * @param state - The root state of the Redux store.
 * @param defaultValue - The default value to return if the state is not available. Default is false.
 * @returns The state of the Facebook Pixel.
 */
export const selectFacebookPixelState = (state: RootState, defaultValue = false): boolean =>
  state.tracking.facebookPixelState || defaultValue;

/**
 * Retrieves the hotjar state from the root state.
 * If the hotjar state is not found, returns the defaultValue.
 *
 * @param state - The root state object.
 * @param defaultValue - The default value to return if the hotjar state is not found. Default is false.
 * @returns The hotjar state value.
 */
export const selectHotjarState = (state: RootState, defaultValue = false): boolean =>
  state.tracking.hotjarState || defaultValue;

/**
 * Selects whether logging is enabled from the Redux state.
 * If the value is not found in the state, it returns the defaultValue.
 *
 * @param state - The Redux state.
 * @param defaultValue - The default value to return if the value is not found in the state. Default is false.
 * @returns A boolean indicating whether logging is enabled.
 */
export const selectIsLoggingEnabled = (state: RootState, defaultValue = false): boolean =>
  state.tracking.isLoggingEnabled || defaultValue;

/**
 * Selects the value of `mixPanelDidIdentify` from the tracking state.
 * If the value is undefined or null, it returns the provided `defaultValue`.
 *
 * @param state - The root state of the application.
 * @param defaultValue - The default value to return if `mixPanelDidIdentify` is undefined or null. Default is `false`.
 * @returns The value of `mixPanelDidIdentify` or the `defaultValue`.
 */
export const selectMixPanelDidIdentify = (state: RootState, defaultValue = false): boolean =>
  state.tracking.mixPanelDidIdentify || defaultValue;

/**
 * Selects the MixPanel state from the root state.
 * If the MixPanel state is not found, returns the defaultValue.
 *
 * @param state - The root state.
 * @param defaultValue - The default value to return if the MixPanel state is not found.
 * @returns The MixPanel state or the defaultValue.
 */
export const selectMixPanelState = (state: RootState, defaultValue = false): boolean =>
  state.tracking.mixPanelState || defaultValue;

/**
 * Selects the log history from the state.
 *
 * @param state - The root state object.
 * @param defaultValue - The default value to return if the log history is not found in the state.
 * @returns An array of log history objects, each containing a string or a nested object of strings.
 */
export const selectLogHistory = (
  state: RootState,
  defaultValue = [],
): Record<string, string | Record<string, string>>[] => state.tracking.logHistory || defaultValue;
