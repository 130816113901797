import { TProductInstance } from '@lib/core/products/types';
import { TQuizAnswerTag } from '@lib/core/quizzes/types';
import {
  isQuizAnswerTagContextBestMatch,
  isQuizAnswerTagContextCharacterMatch,
  isQuizAnswerTagContextFilterCharacter,
  isQuizAnswerTagContextFilterProduct,
  isQuizAnswerTagContextSatisfaction,
  isQuizAnswerTagDifferentCharacter,
  isQuizAnswerTagDissatisfied,
  isQuizAnswerTagSameCharacter,
  isQuizAnswerTagSatisfied,
} from '@lib/tools/shared/pmi/quizzes/filters';

export const recommendVeev = (gprls: TProductInstance[], answerTags: TQuizAnswerTag[]): TProductInstance[] => {
  const tagBestMatchProducts = answerTags.filter(isQuizAnswerTagContextBestMatch);
  const tagCharactersMatched = answerTags.filter(isQuizAnswerTagContextCharacterMatch);
  const tagUserSatisfaction = answerTags.filter(isQuizAnswerTagContextSatisfaction);
  const tagFilterCharacters = answerTags.filter(isQuizAnswerTagContextFilterCharacter);
  const tagFilterProducts = answerTags.filter(isQuizAnswerTagContextFilterProduct);

  const bestMatchGprl = gprls.find(
    gprl => gprl.product.slug === answerTags.find(isQuizAnswerTagContextBestMatch)?.name,
  );
  let filteredGprls = gprls;

  if (tagBestMatchProducts.length && tagUserSatisfaction.some(isQuizAnswerTagSatisfied)) {
    filteredGprls = gprls.filter(gprl => gprl.identifier !== bestMatchGprl?.identifier);
  }

  if (bestMatchGprl) {
    if (tagUserSatisfaction.some(isQuizAnswerTagSatisfied)) {
      // Happy
      if (tagFilterProducts.length) {
        return filteredGprls.slice(0, 2);
      }

      return [bestMatchGprl, filteredGprls.filter(gprl => gprl.identifier !== bestMatchGprl.identifier)[0]];
    }

    if (!tagUserSatisfaction.length) return gprls.slice(0, 2);
  }

  if (tagBestMatchProducts.length && tagCharactersMatched.length) {
    if (tagUserSatisfaction.some(isQuizAnswerTagSatisfied)) {
      return filteredGprls.slice(0, 2);
    }

    if (tagFilterCharacters.some(isQuizAnswerTagDifferentCharacter)) {
      const filterOutCharacters = [];
      return filteredGprls
        .filter(gprl => {
          tagCharactersMatched.forEach(tag => filterOutCharacters.push(tag.name));
          return !filterOutCharacters.includes(gprl.product.character.identifier);
        })
        .slice(0, 2);
    }

    if (tagFilterCharacters.some(isQuizAnswerTagSameCharacter)) {
      const filterInCharacters = [];
      return filteredGprls
        .filter(gprl => {
          tagCharactersMatched.forEach(tag => filterInCharacters.push(tag.name));
          return filterInCharacters.includes(gprl.product.character.identifier);
        })
        .slice(0, 2);
    }

    return filteredGprls.slice(0, 2);
  }

  if (tagUserSatisfaction.some(isQuizAnswerTagDissatisfied)) {
    if (tagFilterCharacters.some(isQuizAnswerTagDifferentCharacter)) {
      return filteredGprls.slice(0, 2);
    }
  }

  return gprls.slice(0, 2);
};
