import { combineReducers } from 'redux';

import products from '@lib/core/products/slices/products';
import productSets from '@lib/core/products/slices/productSets';
import userProductPreferences from '@lib/core/products/slices/userProductPreferences';

export const productsRoot = combineReducers({
  productSets,
  products,
  userProductPreferences,
});
