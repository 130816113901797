export const useCurrentQuizBackground = (questionImage: string, isShowRestrictedBackground: boolean): string => {
  let currentBackground = '#f6f4f0';

  if (isShowRestrictedBackground) {
    currentBackground = '#51BAB0';
  } else if (questionImage) {
    currentBackground = `url(${questionImage}) center/cover no-repeat`;
  }

  return currentBackground;
};
