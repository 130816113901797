import cn from 'classnames';
import { useEffect, useState } from 'react';

import finishTestVideo from '@app/pmi/src/assets/media/V2/result-loading-page-animation.mp4';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  currentPrimaryColor: string;
}

function ResultLoadingPage({ currentPrimaryColor }: Props) {
  const [showLoaderAnimation, setShowLoaderAnimation] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoaderAnimation(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="ta-v2-ecommerce-result-loading-page">
      <div className="ta-v2-ecommerce-result-loading-page-load-container">
        <div className="ta-v2-ecommerce-result-loading-page-load-content-box">
          <div
            className={cn('ta-v2-ecommerce-result-loading-page-load-image-cover', {
              'not-visible': !showLoaderAnimation,
            })}
          >
            <video autoPlay loop muted>
              <source src={finishTestVideo} type="video/mp4" />
            </video>
          </div>
          <svg
            viewBox="0 0 126 126"
            xmlns="http://www.w3.org/2000/svg"
            className={cn('ta-v2-ecommerce-result-loading-icon', {
              visible: !showLoaderAnimation,
            })}
          >
            <g fill="none" fillRule="evenodd">
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M0.867 0.867H124.867V124.867H0.867z"
                        transform="translate(-657 -113) translate(0 76) translate(657 37) translate(.133 .133)"
                      />
                      <path
                        d="M77.692 37.445C73.324 34.875 68.234 33.4 62.8 33.4c-16.237 0-29.4 13.163-29.4 29.4s13.163 29.4 29.4 29.4 29.4-13.163 29.4-29.4"
                        fillRule="nonzero"
                        stroke="#272A33"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3.2"
                        transform="translate(-657 -113) translate(0 76) translate(657 37) translate(.133 .133)"
                      />
                      <path
                        d="M74.956 42.102C71.391 40.004 67.236 38.8 62.8 38.8c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24"
                        fillRule="nonzero"
                        stroke={currentPrimaryColor}
                        strokeLinecap="round"
                        strokeWidth="3.2"
                        transform="translate(-657 -113) translate(0 76) translate(657 37) translate(.133 .133)"
                      />
                    </g>
                    <path
                      d="M48.62 60.515L60.506 72.956 90.654 41.983"
                      fillRule="nonzero"
                      stroke="#272A33"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3.2"
                      transform="translate(-657 -113) translate(0 76) translate(657 37)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <span className="font-ta-v2-title-4">
            <LocaleFragment message={localeV2.ecommerce.resultLoadingPageText} />
          </span>
        </div>
      </div>
    </div>
  );
}

export default ResultLoadingPage;
