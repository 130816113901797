import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FILTER_TYPE_WISHLIST } from '@lib/tools/shared/helpers/consts';

export interface IShowOnlyFilterState {
  [FILTER_TYPE_WISHLIST]: boolean;
}

const initialState: IShowOnlyFilterState = {
  [FILTER_TYPE_WISHLIST]: false,
};

export const showOnlyFilterSlice = createSlice({
  initialState,
  name: 'showOnlyFilter',
  reducers: {
    updateShowOnlyFilterValue: (state, action: PayloadAction<keyof IShowOnlyFilterState>) => {
      state[action.payload] = !state[action.payload];
    },
  },
});

export default showOnlyFilterSlice.reducer;
export const { updateShowOnlyFilterValue } = showOnlyFilterSlice.actions;
