import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';

export const NormalTableHead = ({ tableHeadInfo }: { tableHeadInfo: any[] }) => {
  return (
    <TableHead>
      <TableRow>
        {tableHeadInfo.map((column, i) => (
          <TableCell key={i} align={column.align} style={{ minWidth: column.minWidth }}>
            {column.label ? column.label : column}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const EnhancedTableHead = ({ tableHeadInfo }: { tableHeadInfo: any }) => {
  const { order, orderBy, handleRequestSort, headCells } = tableHeadInfo;

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map(cell => (
          <TableCell
            key={cell.id}
            align={cell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === cell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : 'asc'}
              onClick={createSortHandler(cell.id)}
            >
              {cell.label}
              {orderBy === cell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
