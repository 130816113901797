import { Divider, FormControl, InputLabel, MenuItem, Select, Stack, TableContainer, TextField } from '@mui/material';
import * as d3 from 'd3';
import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useChangedNodes } from '@lib/core/quizzes/hooks/changedNodes';
import { useScoreMatrix } from '@lib/core/quizzes/hooks/scoreMatrix';
import { changeScoreForAlreadyInPath, changeScoreForAlreadyInState } from '@lib/core/quizzes/slices/changedNodes';
import { updateScoreMatrix, updateScoreMatrixByValue } from '@lib/core/quizzes/slices/scoreMatrix';
import { apiUrlGetCsv } from '@lib/core/quizzes/slices/urls';
import { useApp } from '@lib/core/service/hooks';
import request from '@lib/core/service/requests/request';
import { setProductCategory } from '@lib/core/service/slices';
import { ITableColumn, ITableRow } from '@lib/tools/devtools/components/interfaces';
import { TableComponent } from '@lib/tools/devtools/components/table/index';
import ScoreMatrixUtils from '@lib/tools/devtools/panels/sensory/utils/weights';

export const ScoreMatrixTab = () => {
  const { productCategory } = useApp();
  const { scoreMatrix } = useScoreMatrix();
  const dispatch = useDispatch();

  const [scoreRows, setScoreRows] = useState<ITableRow[]>([]);
  const [filteredScoreRows, setFilteredMatrixRows] = useState<ITableRow[]>([]);
  const [scoreColumns, setScoreColumns] = useState<ITableColumn[]>([]);

  const [questionID, setQuestionID] = useState('');
  const [answerID, setAnswerID] = useState('');
  const [filteredMatrix, setFilteredMatrix] = useState<any>(null);
  // const [loading] = useState(false);
  const [editingCell, setEditingCell] = useState<{ rowIndex: number; columnId: string } | null>(null);
  const [newInput, setNewInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const { changedNodes } = useChangedNodes();

  const handleQuestionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuestionID(event.target.value);
  };

  const handleAnswerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAnswerID(event.target.value);
  };
  const resetTheScores = () => {
    setFilteredMatrix({});
    setFilteredMatrixRows([]);
  };

  const resetAllFilters = () => {
    resetTheScores();
    setAnswerID('');
    setQuestionID('');
  };
  const handleKeyPressAnswer = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const { characters } = scoreMatrix[productCategory];
      const foundKey = Object.keys(scoreMatrix[productCategory].scores).find(key => {
        const answersObj = scoreMatrix[productCategory].scores[key];
        const answerArray = Object.keys(answersObj);
        return answerArray.includes(answerID);
      });

      const scoresArray = scoreMatrix[productCategory].scores[foundKey][answerID];
      const matrix = { characters, scores: { [foundKey]: { [answerID]: scoresArray } } };

      setFilteredMatrix(matrix);
    } else {
      resetTheScores();
    }
  };

  const handleKeyPressQuestion = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const { characters } = scoreMatrix[productCategory];
      const answersObj = scoreMatrix[productCategory].scores[questionID];
      const questionObj = { [questionID]: answersObj };
      const matrix = { characters, scores: questionObj };
      setFilteredMatrix(matrix);
    } else {
      resetTheScores();
    }
  };
  const prepareRowsAndColumns = (scoreMatrixData: any) => {
    if (productCategory && Object.keys(scoreMatrixData).length) {
      const values: any = Object.values(scoreMatrixData);
      if (values && values.length) {
        // if the fetching already occured
        const { scores } = scoreMatrixData; //  { question -> answer -> score for each answer }
        const { characters } = scoreMatrixData; // all characters
        const questions = Object.keys(scores); // all questions

        const rows: ITableRow[] = [];
        const columns: ITableColumn[] = [
          { id: 'question', label: 'Question', minWidth: 100 },
          { id: 'answer', label: 'Answer', minWidth: 100 },
        ];
        characters.forEach((character: string) => {
          columns.push({ id: character, label: character, minWidth: 100 });
        });

        questions.forEach(question => {
          const answers = Object.keys(scores[question]);
          answers.forEach(answer => {
            // all scores for a single answer [ 0, 1.2, 0.5, 0.09]

            const usedScores = [...scoreMatrixData.scores[question][answer]];
            const rowObject: ITableRow = {
              answer,
              question,
            };
            characters.forEach((character: string, index: number) => {
              rowObject[character] = usedScores[index];
            });
            rows.push(rowObject);
          });
        });
        setScoreColumns(columns);
        if (filteredMatrix && Object.keys(filteredMatrix).length) {
          setFilteredMatrixRows(rows);
        } else {
          setScoreRows(rows);
        }
      }
    }
  };
  const handleCellClick = (rowIndex: number, columnId: string) => {
    setEditingCell({ columnId, rowIndex });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewInput(event.target.value);
    setIsTyping(true);
  };
  const handleSubmit = (row: any, column: any, columnIndex: any) => {
    const parentId = row.question;
    const nodeId = row.answer;
    const index = columnIndex - 2;
    const updatedScore = parseFloat(newInput);
    let scoreArray;
    const selectedProductCategory = productCategory;
    // means that we have a new node or the scores of a node is changed
    if (changedNodes[productCategory] && Object.keys(changedNodes[productCategory]).includes(nodeId)) {
      scoreArray = [...changedNodes[productCategory][nodeId].scoresArray];
      scoreArray[index] = updatedScore;

      dispatch(changeScoreForAlreadyInState({ nodeId, scoreArray, selectedProductCategory }));
    } else {
      scoreArray = [...scoreMatrix[productCategory].scores[parentId][nodeId]];
      scoreArray[index] = updatedScore;
      dispatch(changeScoreForAlreadyInPath({ nodeId, parentId, scoreArray, selectedProductCategory }));
    }
    const scoresRowCopy = [...scoreRows];
    scoresRowCopy.forEach(scoreRow => {
      if (scoreRow.answer === nodeId) {
        scoreRow[column.id] = newInput;
      }
    });

    if (filteredMatrix && Object.keys(filteredMatrix).length) {
      const filteredScoresRowCopy = [...filteredScoreRows];

      filteredScoresRowCopy.forEach(filteredScoreRow => {
        if (filteredScoreRow.answer === nodeId) {
          filteredScoreRow[column.id] = newInput;
        }
      });
      setFilteredMatrixRows(filteredScoresRowCopy);
      const filteredScoreMatrixCopy = JSON.parse(JSON.stringify(filteredMatrix));
      filteredScoreMatrixCopy.scores[parentId][nodeId] = scoreArray;
      setFilteredMatrix(filteredScoreMatrixCopy);
    }
    dispatch(updateScoreMatrixByValue({ nodeId, parentId, scoreArray, selectedProductCategory }));

    setScoreRows(scoresRowCopy);
    setEditingCell(null);
    setNewInput('');
    setIsTyping(false);
  };
  const fetchDataAndProcess = async () => {
    try {
      const response = await request(apiUrlGetCsv(productCategory), { ignoreProfileHeaders: true });

      const rawScoreMatrix = d3.csvParse(response);
      const filteredCsvData = rawScoreMatrix.map(row => {
        delete row.LEGENDA;
        delete row.NOTE;
        delete row['Question position'];
        return row;
      });

      const fetchedScoreMatrix = ScoreMatrixUtils.transformRawScoreMatrix(filteredCsvData);
      const scoreMatrixData = fetchedScoreMatrix;

      const selectedProductCategory = productCategory;
      dispatch(updateScoreMatrix({ scoreMatrixData, selectedProductCategory }));
      prepareRowsAndColumns(scoreMatrixData);
    } catch (error) {
      console.error('Error fetching and processing data:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (productCategory) {
        let scoreMatrixData;
        if (Object.keys(scoreMatrix).length && scoreMatrix[productCategory]) {
          scoreMatrixData = scoreMatrix[productCategory];
          prepareRowsAndColumns(scoreMatrixData);
        } else {
          await fetchDataAndProcess();
        }
      }
    };
    fetchData();
  }, [productCategory]);
  useEffect(() => {
    if (filteredMatrix) {
      prepareRowsAndColumns(filteredMatrix);
    }
  }, [filteredMatrix]);
  const tableRows = filteredScoreRows && filteredScoreRows.length ? filteredScoreRows : scoreRows;

  return (
    <>
      <div className="panel collapsible" role="presentation">
        <span className="title">Score Matrix</span>
      </div>

      <div className="panel">
        <Stack spacing={2}>
          <Stack alignItems="flex-start" direction="row" spacing={0.5}>
            <FormControl sx={{ backgroundColor: 'lightgrey', minWidth: 180 }}>
              <InputLabel id="product-category-label" size="small" style={{ color: 'white' }}>
                Product category
              </InputLabel>
              <Select
                id="product-category"
                label="Product category"
                labelId="product-category"
                size="small"
                sx={{ color: 'black' }}
                value={productCategory}
                onChange={event => {
                  const { value } = event.target;
                  dispatch(setProductCategory(value));
                }}
              >
                <MenuItem key="heets" value="heets">
                  HEETS
                </MenuItem>
                <MenuItem key="terea" value="terea">
                  Terea
                </MenuItem>
                <MenuItem key="veev" value="veev">
                  Veev
                </MenuItem>
                <MenuItem key="blends" value="blends">
                  Blends
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ backgroundColor: 'lightgrey', minWidth: 180 }}>
              <TextField
                InputProps={{ style: { color: 'black' } }}
                id="question-id"
                label="Question ID"
                size="small"
                value={questionID}
                variant="outlined"
                onChange={handleQuestionChange}
                onClick={resetAllFilters}
                onKeyDown={handleKeyPressQuestion}
              />
            </FormControl>

            <FormControl sx={{ backgroundColor: 'lightgrey', minWidth: 180 }}>
              <TextField
                InputProps={{ style: { color: 'black' } }}
                id="answer-id"
                label="Answer ID"
                size="small"
                value={answerID}
                variant="outlined"
                onChange={handleAnswerChange}
                onClick={resetAllFilters}
                onKeyDown={handleKeyPressAnswer}
              />
            </FormControl>
          </Stack>

          <Divider />

          <TableContainer sx={{ maxHeight: window.innerHeight }}>
            {scoreColumns.length && scoreRows.length && (
              <TableComponent
                editingCell={editingCell}
                handleCellClick={handleCellClick}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                isTyping={isTyping}
                newInput={newInput}
                rowData={tableRows}
                rowType="ScoreMatrixRow"
                tableHeadInfo={scoreColumns}
              />
            )}
          </TableContainer>
        </Stack>
      </div>
    </>
  );
};
