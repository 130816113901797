import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TProductInstance } from '@lib/core/products/types';
import { getProductsListData } from '@lib/core/products/utils';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';
import { parseThunkError } from '@lib/tools/shared/helpers/utils';

interface IDiscoveryQuizProducts {
  isProductsLoading: boolean;
  discoveryQuizProducts: TProductInstance[];
  requestError: string;
}

interface IDiscoveryQuizState {
  discoveryQuizProductInstance: TProductInstance;
  data: IDiscoveryQuizProducts;
  isLoadingScreenOpen: boolean;
}

const initialState: IDiscoveryQuizState = {
  data: {
    discoveryQuizProducts: undefined,
    isProductsLoading: false,
    requestError: '',
  },
  discoveryQuizProductInstance: undefined,
  isLoadingScreenOpen: false,
};

export const fetchDiscoveryQuizProducts = createTypedAsyncThunk(
  'discoveryQuiz/fetchDiscoveryQuizProducts',
  async (productInstanceData: TProductInstance) => {
    try {
      const productCategory = productInstanceData?.product?.category;

      const { results: products = [] } = await getProductsListData({
        [PRODUCT_CATEGORY_QUERY]: productCategory,
        offset: 0,
      });

      return products;
    } catch (error: any) {
      throw new Error(error);
    }
  },
);

const discoveryQuizSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchDiscoveryQuizProducts.pending, state => {
        state.data.discoveryQuizProducts = undefined;
        state.data.isProductsLoading = true;
      })
      .addCase(fetchDiscoveryQuizProducts.fulfilled, (state, action) => {
        state.data.discoveryQuizProducts = action.payload;
        state.data.isProductsLoading = false;
      })
      .addCase(fetchDiscoveryQuizProducts.rejected, (state, action) => {
        state.data.discoveryQuizProducts = undefined;
        state.data.isProductsLoading = false;
        state.data.requestError = parseThunkError(action);
      });
  },
  initialState,
  name: 'discoveryQuiz',
  reducers: {
    openLoadingScreen: (state, { payload }) => {
      state.isLoadingScreenOpen = payload;
    },
    setDiscoveryQuizProductInstance: (state, action: PayloadAction<TProductInstance>) => {
      state.discoveryQuizProductInstance = action.payload;
    },
  },
});

export const { setDiscoveryQuizProductInstance, openLoadingScreen } = discoveryQuizSlice.actions;

export default discoveryQuizSlice.reducer;
