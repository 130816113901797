import { TProductInstance } from '@lib/core/products/types';
import { isAppInIframe } from '@lib/tools/comms/utils';
import { isCoolingEssencePresent } from '@lib/tools/shared/pmi/essences';
import {
  isProductLevia,
  isProductSentia,
  isProductTagEuClassicTobacco,
  isProductTagEuEssences,
  isProductTagHighMenthol,
} from '@lib/tools/shared/pmi/products/filters';

// Hide products that have portfolio order 0, and return a sorted list of products based on the ordering.
// ! Combine with orderTereaProductsList into a single method
export const sortProductsByPortfolioOrder = (
  productsList: TProductInstance[],
  isDimensions: boolean,
  allProducts = false,
): TProductInstance[] => {
  return productsList
    .filter(
      gprl =>
        gprl.product.attributes?.portfolio_order !== 0 &&
        (allProducts ||
          (isDimensions
            ? gprl.product.attributes?.is_dimensions === 'true'
            : gprl.product.attributes?.is_dimensions !== 'true')),
    )
    .sort(
      (a, b) =>
        parseFloat(`${a.product.attributes.portfolio_order}`) - parseFloat(`${b.product.attributes.portfolio_order}`),
    );
};

export const orderTereaProductsList = (productsList: TProductInstance[]): TProductInstance[] =>
  productsList
    .filter(grpl => grpl.product.attributes.portfolio_order !== 0)
    .sort(
      (a, b) =>
        parseFloat(`${a.product.attributes.portfolio_order}`) - parseFloat(`${b.product.attributes.portfolio_order}`),
    );

// ! use `isProductDimensions` in the component.
export const filterDimensionsProducts = (productsList: TProductInstance[], isDimension: boolean): TProductInstance[] =>
  productsList.filter(grpl =>
    isDimension ? grpl.product.attributes.is_dimensions === 'true' : grpl.product.attributes.is_dimensions !== 'true',
  );

// ! use these filters directly where needed.
export const retailerProductLocationTags = (gprl: TProductInstance) => {
  const { retailer_product_location_tags: tags } = gprl;

  return {
    isEuClassicTobaccoEssencesTag: tags?.some(isProductTagEuEssences),
    isEuClassicTobaccoTag: tags?.some(isProductTagEuClassicTobacco),
    isHighMenthol: tags?.some(isProductTagHighMenthol),
  };
};

// ! Move into RouteUtils as a generic redirection method with proper encoding.
export const redirectToProductPage = url => {
  if (isAppInIframe) window.top.location.href = url;
};

export const showProductWithCoolingEssence = (gprl: TProductInstance): boolean => {
  const { isEuClassicTobaccoEssencesTag } = retailerProductLocationTags(gprl);
  return !isEuClassicTobaccoEssencesTag && isCoolingEssencePresent(gprl?.product?.essences);
};

export const parseTereaVariants = (
  gprls: TProductInstance[],
): {
  orderedLeviaProducts: TProductInstance[] | [];
  orderedSentiaProducts: TProductInstance[] | [];
  orderedTereaProducts: TProductInstance[] | [];
} => {
  const sentiaProducts = gprls.filter(gprl => isProductSentia(gprl));
  const leviaProducts = gprls.filter(gprl => isProductLevia(gprl));
  const tereaProducts = gprls.filter(gprl => !isProductSentia(gprl) && !isProductLevia(gprl));

  const orderedTereaProducts = orderTereaProductsList(tereaProducts);
  const orderedSentiaProducts = orderTereaProductsList(sentiaProducts);
  const orderedLeviaProducts = orderTereaProductsList(leviaProducts);

  return { orderedLeviaProducts, orderedSentiaProducts, orderedTereaProducts };
};
