import cn from 'classnames';
import { FC, useState } from 'react';

import { ReactComponent as ChevronLangLight } from '@app/pmi/src/assets/media/V2/ChevronLang-Light.svg';
import { ReactComponent as ChevronLang } from '@app/pmi/src/assets/media/V2/ChevronLang.svg';
import { ReactComponent as GlobeLight } from '@app/pmi/src/assets/media/V2/Globe-Light.svg';
import { ReactComponent as Globe } from '@app/pmi/src/assets/media/V2/Globe.svg';

export interface LanguageSwitcherProps {
  supportedLanguages: Array<{ code: string; language: string }>;
  selectedLanguageCode: string;
  onLanguageSelect: (code: string) => void;
  isLight: boolean;
}

const LanguageSwitcher: FC<LanguageSwitcherProps> = ({
  onLanguageSelect,
  supportedLanguages,
  selectedLanguageCode,
  isLight,
}) => {
  // ToDp now this logic added for normal tasting in storybook, refactor when implementing logic
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [selectedLanguageCodeState, setSelectedLanguageCodeState] = useState(selectedLanguageCode);
  const filteredLanguagesLists = supportedLanguages.filter(({ code }) => code !== selectedLanguageCodeState);
  const selectedLanguageName = supportedLanguages.filter(({ code }) => code === selectedLanguageCodeState)[0].language;
  const [selectedLanguage, setSelectedLanguage] = useState(selectedLanguageName);

  const handleSelect = (el: { code: string; language: string }) => {
    onLanguageSelect(el.code);
    setSelectedLanguage(el.language);
    setSelectedLanguageCodeState(el.code);
    setIsDropDownOpen(false);
  };

  const getDropDownIcon = () => {
    if (isLight) {
      return <ChevronLangLight className="ta-v2-language-switcher-chevron" />;
    }
    return <ChevronLang className="ta-v2-language-switcher-chevron" />;
  };

  return (
    <div className="ta-v2-language-switcher">
      <button
        className={cn('ta-v2-dropdown-toggle', {
          isDropdownOpen: isDropDownOpen,
          isLight,
        })}
        onClick={() => {
          setIsDropDownOpen(prev => !prev);
        }}
      >
        {isLight ? <GlobeLight /> : <Globe />}
        <span className="font-ta-v2-global-body-large">{selectedLanguage}</span>
        {supportedLanguages.length > 1 ? getDropDownIcon() : null}
      </button>
      <div
        className={cn('ta-v2-dropdown-menu font-ta-v2-global-body-large', {
          'open-menu': isDropDownOpen,
        })}
      >
        {filteredLanguagesLists.map(element => (
          <button
            key={element.code}
            className="ta-v2-dropdown-item"
            onClick={() => {
              handleSelect(element);
            }}
          >
            {element.language}
          </button>
        ))}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
