// Post Messages
export const POST_MESSAGE_ADD_TO_CART = 'ExperienceAddToCart';
export const POST_MESSAGE_BEST_MATCH = 'ExperienceBestMatch';
export const POST_MESSAGE_DISPLAY_ROUTE = 'ExperienceDisplayRoute';
export const POST_MESSAGE_DOM_HEIGHT = 'ExperienceDomHeight';
export const POST_MESSAGE_HANDSHAKE = 'ExperienceApproveHandshake';
export const POST_MESSAGE_LOCALE = 'ExperienceLocale';
export const POST_MESSAGE_PRODUCT_CATEGORY = 'ExperienceProductCategory';
export const POST_MESSAGE_PRODUCT_SETS = 'ExperienceProductSets';
export const POST_MESSAGE_QUIZ_COMPLETED = 'ExperienceQuizCompleted';
export const POST_MESSAGE_REQUEST_DOM_HEIGHT = 'ExperienceRequestDomHeight';
export const POST_MESSAGE_REQUEST_HOST_HANDSHAKE = 'ExperienceRequestHostHandshake';
export const POST_MESSAGE_REQUEST_INCLUDE_STYLES = 'ExperienceRequestIncludeStyles';
export const POST_MESSAGE_SEND_DYNAMO_EVENT = 'ExperienceSendDynamoEvent';
export const POST_MESSAGE_SEND_TA_GA_EVENT = 'ExperienceTAGoogleAnalyticsEvent';
