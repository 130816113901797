import cn from 'classnames';
import { FC } from 'react';

import { ReactComponent as Cancel } from '@app/pmi/src/assets/media/V2/v2_cancel_white.svg';
import { ReactComponent as Tick } from '@app/pmi/src/assets/media/V2/v2_tick_white.svg';

type TCTAIcon = 'check' | 'close' | 'none';

interface Props {
  btnText: string | JSX.Element;
  isDark: boolean;
  onBtnClick?: () => void;
  className?: string;
  icon?: TCTAIcon;
  disabled?: boolean;
}

const ButtonOutlined: FC<Props> = ({ isDark, btnText, onBtnClick, className, icon, disabled }) => {
  const getCurrentIcon = iconName => {
    let currentIcon;

    const currentTickIcon = <Tick className={cn({ isDark })} />;
    const currentCancelIcon = <Cancel className={cn({ isDark })} />;

    switch (iconName) {
      case 'check':
        currentIcon = currentTickIcon;
        break;
      case 'close':
        currentIcon = currentCancelIcon;
        break;
      default:
    }

    return currentIcon;
  };

  return (
    <button
      disabled={disabled}
      type="button"
      className={cn('ta-v2-cta-btn-outline', className, {
        'ta-v2-cta-btn-outline-dark': isDark,
      })}
      onClick={onBtnClick}
    >
      {icon && icon !== 'none' && <span className="ta-v2-cta-btn-icon">{getCurrentIcon(icon)}</span>}

      <span className="font-ta-v2-global-body-large">{btnText}</span>
    </button>
  );
};

export default ButtonOutlined;
