import { defineMessages } from '@formatjs/intl';

export const localeCrafted = {
  landing: defineMessages({
    descriptionTextOne: {
      defaultMessage:
        'Everything **truly authentic** begins in nature. The natural world of botanicals has given us **many unique** tastes that have elevated culinary traditions all around the world.',
      id: 'ta.botanicals.landing.description_text_one',
    },
    descriptionTextThree: {
      defaultMessage: 'Click ‘Start’ to find out which TEREA Crafted flavour suits you the best!',
      id: 'ta.botanicals.landing.description_text_three',
    },
    descriptionTextTwo: {
      defaultMessage:
        'Now our blenders have crafted a new range of tobacco flavours dedicated to presenting the **true essence** of natural botanicals in an exciting way.',
      id: 'ta.botanicals.landing.description_text_two',
    },
    startBtnText: {
      defaultMessage: 'Start',
      id: 'ta.botanicals.landing.start_btn.text',
    },
  }),
  quiz: defineMessages({
    addToCartCTA: {
      defaultMessage: 'Add to cart',
      id: 'ta.botanicals.quiz.add_to_cart_cta',
    },
    flavourCTA: {
      defaultMessage: 'Learn about your selected flavour',
      id: 'ta.botanicals.quiz.flavour_cta',
    },
    instructions: {
      defaultMessage: 'Click and drag to turn each wheel',
      id: 'ta.botanicals.quiz.instructions',
    },
    resultTitle: {
      defaultMessage: 'This TEREA Crafted variant could be ideal for your selected flavour combination.',
      id: 'ta.botanicals.quiz.title_result',
    },
    rotate: {
      defaultMessage: 'Rotate',
      id: 'ta.botanicals.quiz.rotate',
    },
    submit: {
      defaultMessage: 'Submit',
      id: 'ta.botanicals.quiz.submit',
    },
    title: {
      defaultMessage: 'Select a combination below with the three wheels to find a flavour that suits you the best.',
      id: 'ta.botanicals.quiz.title',
    },
    titleSelect: {
      defaultMessage: 'Are you satisfied with your combination?',
      id: 'ta.botanicals.quiz.title_select',
    },
  }),
};
