import { useLayoutEffect, useRef, useState } from 'react';

export const useHeight = <T extends HTMLElement>() => {
  const ref = useRef<T>();

  const [height, setHeight] = useState(0);

  const heightRef = useRef(height);

  const [observer] = useState(
    () =>
      new ResizeObserver(() => {
        if (ref.current && heightRef.current !== ref.current.offsetHeight) {
          heightRef.current = ref.current.offsetHeight;
          setHeight(ref.current.offsetHeight);
        }
      }),
  );

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
      observer.observe(ref.current, {});
    }
    return () => observer.disconnect();
  }, [observer, ref.current]);

  return [ref, height] as const;
};
