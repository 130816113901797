import { FC } from 'react';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

const HealthWarning: FC = () => {
  return (
    <div className="ta-v2-health-warning-container">
      <span className="font-ta-v2-global-body-small font-weight-bold">
        <LocaleFragment message={localeV2.common.healthWarning} />
      </span>
    </div>
  );
};

export default HealthWarning;
