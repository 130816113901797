import { useSelector } from 'react-redux';

import {
  selectQuizAnswerTags,
  selectQuizData,
  selectQuizFetching,
  selectQuizFirstQuestionId,
  selectQuizId,
  selectQuizPath,
  selectQuizQuestionDesignSets,
  selectQuizQuestionNumberOfChoices,
} from '@lib/core/quizzes/selectors';
import { TQuizAnswerTag, TQuizData, TQuizNumberOfChoices, TQuizPath } from '@lib/core/quizzes/types';

type IQuiz = {
  isQuizFetching: boolean;
  quizQuestionDesignSets: Record<string, string>;
  quizFirstQuestionId: string;
  quizQuestionNumberOfChoices: TQuizNumberOfChoices;
  quizAnswerTags: TQuizAnswerTag[];
  quizData: TQuizData;
  quizPath: TQuizPath;
  quizId: string;
};

export const useQuiz = (): IQuiz => {
  return {
    isQuizFetching: useSelector(selectQuizFetching),
    quizAnswerTags: useSelector(selectQuizAnswerTags),
    quizData: useSelector(selectQuizData),
    quizFirstQuestionId: useSelector(selectQuizFirstQuestionId),
    quizId: useSelector(selectQuizId),
    quizPath: useSelector(selectQuizPath),
    quizQuestionDesignSets: useSelector(selectQuizQuestionDesignSets),
    quizQuestionNumberOfChoices: useSelector(selectQuizQuestionNumberOfChoices),
  };
};
