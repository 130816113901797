import { createSelector } from 'reselect';

import { TCharacter } from '@lib/core/characters/types';
import { IUserQuizSlice } from '@lib/core/quizzes/slices/userQuiz';
import { TQuizResultAnswerTags } from '@lib/core/quizzes/types';
import { TUserQuizType } from '@lib/core/quizzes/types/userQuiz';
import {
  QUIZ_TYPE_EXPERT,
  QUIZ_TYPE_MULTI_PRODUCT,
  QUIZ_TYPE_RECIPE,
  QUIZ_TYPE_SITUATIONAL,
  QUIZ_TYPE_TASTE,
  QUIZ_TYPE_TASTE_ECOMMERCE,
  QUIZ_TYPE_TASTE_LOCAL,
} from '@lib/core/quizzes/utils/consts';

/**
 * @returns the whole userQuiz state
 */
export const selectUserQuizState = (state): IUserQuizSlice => state.userQuiz || {};

/**
 * @returns loading status of userQuiz API requests
 */
export const selectUserQuizLoading: (state) => boolean = createSelector(
  [selectUserQuizState],
  userQuiz => userQuiz.isFetching || false,
);

/**
 * @returns the list of all userQuiz result characters
 */
export const selectUserQuizCharacters: (state) => TCharacter[] = createSelector(
  [selectUserQuizState],
  userQuiz => userQuiz.data?.characters || [],
);

/**
 * @returns userQuiz slug
 */
export const selectUserQuizSlug: (state) => string = createSelector(
  [selectUserQuizState],
  userQuiz => userQuiz.data?.quiz?.slug || '',
);

/**
 * @returns userQuiz completion status
 */
export const selectUserQuizCompleted: (state) => boolean = createSelector(
  [selectUserQuizState],
  userQuiz => userQuiz.data?.completed || false,
);

/**
 * @returns the unique userQuiz id
 */
export const selectUserQuizId: (state) => string = createSelector(
  [selectUserQuizState],
  userQuiz => userQuiz.data?.test_session_id || '',
);

/**
 * @returns the retailer character url for third party sites
 */
export const selectRetailerCharacterRedirectUrls: (state) => string = createSelector(
  [selectUserQuizState],
  userQuiz => userQuiz.data?.retailer_character_redirect_urls || '',
);

/**
 * @returns an object containing the submitted answer tags
 */
export const selectUserQuizResultAnswerTags: (state) => TQuizResultAnswerTags = createSelector(
  [selectUserQuizState],
  userQuiz => userQuiz.data?.results?.answer_tags || {},
);

/**
 * @returns the current userQuiz's quizType
 */
export const selectUserQuizType: (state) => TUserQuizType = createSelector([selectUserQuizState], userQuiz => {
  const userQuizType = userQuiz.data?.quiz?.quiz_type;

  return {
    isUserQuizTypeEcommerce: userQuizType === QUIZ_TYPE_TASTE_ECOMMERCE,
    isUserQuizTypeExpert: userQuizType === QUIZ_TYPE_EXPERT,
    isUserQuizTypeLocal: userQuizType === QUIZ_TYPE_TASTE_LOCAL,
    isUserQuizTypeMultiProduct: userQuizType === QUIZ_TYPE_MULTI_PRODUCT,
    isUserQuizTypeRecipe: userQuizType === QUIZ_TYPE_RECIPE,
    isUserQuizTypeSituational: userQuizType === QUIZ_TYPE_SITUATIONAL,
    isUserQuizTypeTaste: userQuizType === QUIZ_TYPE_TASTE,

    userQuizType,
  };
});
