import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IPreferenceExposure, formatUserPreferenceData } from '@app/native/src/models/UserFoodPreferences.model';

import { selectUserProductPreferences } from '@lib/core/products/selectors/userProductPreferences';
import {
  B2C_EXPOSURE_MOOD,
  B2C_EXPOSURE_TAGS,
  B2C_MOOD_PREFERENCES_DATA,
  B2C_USER_PRODUCT_PREFERENCES_TAGS_DATA,
  FOOD_PREFERENCES_UPDATE,
  PRODUCT_SLUG_REPLACEMENT,
  QUIZ_SESSION_ID_REPLACEMENT,
  REQUEST_METHODS,
} from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import request from '@lib/core/service/requests/request';
import { selectUserSessionId } from '@lib/core/users/selectors/user';
import {
  findNoPreferencesSlug,
  findUserProductPreferencesActiveSliceState,
} from '@lib/core/users/utils/productPreferences';
import {
  EXPOSURE_PREFERENCES,
  USER_PRODUCT_PREFERENCES,
  USER_PRODUCT_PREFERENCES_FOOD_DATA,
} from '@lib/tools/shared/helpers/consts';

export type IUserProductPreferencesState = {
  [USER_PRODUCT_PREFERENCES_FOOD_DATA]: IPreferenceExposure;
  [B2C_USER_PRODUCT_PREFERENCES_TAGS_DATA]: IPreferenceExposure;
  [B2C_MOOD_PREFERENCES_DATA]: IPreferenceExposure;
  isUserProductPreferencesDataLoading: boolean;
  userProductPreferencesError: string;
  selectedUserProductPreferencesFetchSuccess: boolean;
};

export type IUserSelectedProductPreferencesData = {
  slug: string;
  name: string;
};

export type IUserProductPreferencesExposure =
  | typeof EXPOSURE_PREFERENCES
  | typeof B2C_EXPOSURE_TAGS
  | typeof B2C_EXPOSURE_MOOD;

const initialState: IUserProductPreferencesState = {
  [B2C_MOOD_PREFERENCES_DATA]: {},
  [B2C_USER_PRODUCT_PREFERENCES_TAGS_DATA]: {},
  [USER_PRODUCT_PREFERENCES_FOOD_DATA]: {},
  isUserProductPreferencesDataLoading: false,
  selectedUserProductPreferencesFetchSuccess: false,
  userProductPreferencesError: '',
};

export const actionFetchSelectedUserProductPreferences = createTypedAsyncThunk(
  'b2cUserProductPreferencesData/actionFetchSelectedUserProductPreferences',
  async (userSessionID: string, { getState }) => {
    const allUserProductPreferencesData = selectUserProductPreferences(getState());
    let userSelectedProductPreferences: { user_product_preferences: IUserSelectedProductPreferencesData[] } = {
      user_product_preferences: [],
    };
    if (userSessionID) {
      userSelectedProductPreferences = await request(
        backendApiUrls.apiUrlGETUserProductPreferences.replace(QUIZ_SESSION_ID_REPLACEMENT, userSessionID),
      );
    }
    const userSelectedProductPreferencesData = userSelectedProductPreferences?.user_product_preferences ?? [];

    const foodPreferencesData = formatUserPreferenceData({
      allUserProductPreferencesData,
      exposure: EXPOSURE_PREFERENCES,
      userSelectedProductPreferencesData,
    });
    const tagsPreferencesData = formatUserPreferenceData({
      allUserProductPreferencesData,
      exposure: B2C_EXPOSURE_TAGS,
      userSelectedProductPreferencesData,
    });
    const moodPreferencesData = formatUserPreferenceData({
      allUserProductPreferencesData,
      exposure: B2C_EXPOSURE_MOOD,
      userSelectedProductPreferencesData,
    });

    return { foodPreferencesData, moodPreferencesData, tagsPreferencesData };
  },
);

export const actionUpdateUserProductPreferences = createTypedAsyncThunk(
  'b2cUserProductPreferencesData/actionUpdateUserProductPreferences',
  async (
    {
      slug,
      isEnabled,
      exposure,
      isRegistrationAction = false,
      isWidgetUpdating = false,
      sessionIdFromProps = null,
    }: {
      slug: string;
      isEnabled: boolean;
      exposure: IUserProductPreferencesExposure;
      isRegistrationAction?: boolean;
      isWidgetUpdating?: boolean;
      sessionIdFromProps?: string;
    },
    { getState },
  ) => {
    const sessionId = sessionIdFromProps || selectUserSessionId(getState());
    const productPreferencesState = getState().productPreferences;

    const backendApiUrl = backendApiUrls.apiUrlPUTUserProductPreferences
      .replace(QUIZ_SESSION_ID_REPLACEMENT, sessionId)
      .replace(PRODUCT_SLUG_REPLACEMENT, FOOD_PREFERENCES_UPDATE);
    const requestMethodPUT = {
      method: REQUEST_METHODS.PUT,
    };

    // b2c logic for updating all preferences
    if (!isRegistrationAction && !isWidgetUpdating) {
      const fieldState = findUserProductPreferencesActiveSliceState(exposure);
      const noPreferencesSlug = findNoPreferencesSlug(exposure);

      if (slug === noPreferencesSlug && productPreferencesState[fieldState][noPreferencesSlug].isEnabled) {
        const removeSlugKeys = Object.keys(productPreferencesState[fieldState]).filter(
          preferenceKey => productPreferencesState[fieldState][preferenceKey].slug !== noPreferencesSlug,
        );

        const removeAllOtherPreferencesData = {
          remove: true,
          user_product_preferences: removeSlugKeys,
        };

        await request(backendApiUrl, requestMethodPUT, removeAllOtherPreferencesData);
      } else if (slug !== noPreferencesSlug) {
        const removeNoPreferencesData = {
          remove: true,
          user_product_preferences: [noPreferencesSlug],
        };
        await request(backendApiUrl, requestMethodPUT, removeNoPreferencesData);
      }
    }

    // widget logic for deleting/updating food preferences
    if (!isRegistrationAction && isWidgetUpdating) {
      const currentWidgetFoodPreferences = Object.values(productPreferencesState[USER_PRODUCT_PREFERENCES_FOOD_DATA])
        .filter((pref: any) => pref.isEnabled)
        .map((filteredPref: any) => filteredPref.slug);

      await request(backendApiUrl, requestMethodPUT, {
        remove: true,
        user_product_preferences: currentWidgetFoodPreferences,
      });
    }

    // we set preferences after b2c and widget user registration and regular updating widget user food pref
    const preferencesSlugs = Array.isArray(slug) ? [...slug] : [slug];
    const finalSubmitData = {
      remove: isRegistrationAction ? false : !isEnabled,
      user_product_preferences: preferencesSlugs,
    };

    await request(backendApiUrl, requestMethodPUT, finalSubmitData);
    localStorage.removeItem(USER_PRODUCT_PREFERENCES);
  },
);

const productPreferencesSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionFetchSelectedUserProductPreferences.pending, state => {
      state.isUserProductPreferencesDataLoading = true;
    });
    builder.addCase(actionFetchSelectedUserProductPreferences.fulfilled, (state, action) => {
      state.foodPreferencesData = action.payload.foodPreferencesData;
      state.moodPreferencesData = action.payload.moodPreferencesData;
      state.tagsPreferencesData = action.payload.tagsPreferencesData;
      state.isUserProductPreferencesDataLoading = false;
      state.selectedUserProductPreferencesFetchSuccess = true;
    });
    builder.addCase(actionFetchSelectedUserProductPreferences.rejected, (state, action: any) => {
      state.isUserProductPreferencesDataLoading = false;
      state.selectedUserProductPreferencesFetchSuccess = true;
      if (action.payload?.errorMessage) {
        state.userProductPreferencesError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.userProductPreferencesError = action.error.message;
      }
    });

    builder.addCase(actionUpdateUserProductPreferences.rejected, (state, action: any) => {
      const { slug, exposure } = action.meta.arg;

      const fieldState =
        exposure === EXPOSURE_PREFERENCES ? USER_PRODUCT_PREFERENCES_FOOD_DATA : B2C_USER_PRODUCT_PREFERENCES_TAGS_DATA;
      if (state[fieldState][slug]) {
        state[fieldState][slug].isEnabled = !state[fieldState][slug].isEnabled;
      }
      if (action.payload?.errorMessage) {
        state.userProductPreferencesError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.userProductPreferencesError = action.error.message;
      }
    });
  },
  initialState,
  name: 'b2cUserProductPreferencesData',
  reducers: {
    actionToggleUserProductPreferencesValue: (
      state,
      action: PayloadAction<{ slug: string; exposure: IUserProductPreferencesExposure }>,
    ) => {
      const { slug, exposure } = action.payload;

      const fieldState = findUserProductPreferencesActiveSliceState(exposure);
      const noPreferencesSlug = findNoPreferencesSlug(exposure);

      if (slug !== noPreferencesSlug && state[fieldState][noPreferencesSlug].isEnabled) {
        state[fieldState][noPreferencesSlug].isEnabled = false;
      }
      if (slug === noPreferencesSlug && !state[fieldState][noPreferencesSlug].isEnabled) {
        Object.keys(state[fieldState]).forEach(preferenceKey => {
          state[fieldState][preferenceKey].isEnabled = false;
        });
      }
      state[fieldState][slug].isEnabled = !state[fieldState][slug].isEnabled;
    },
    resetUserProductPreferencesSlice: () => initialState,
  },
});

export default productPreferencesSlice.reducer;
export const { actionToggleUserProductPreferencesValue, resetUserProductPreferencesSlice } =
  productPreferencesSlice.actions;
