import { B2C_USER_PRODUCT_PREFERENCES_TAGS_DATA } from '@lib/core/service/consts';
import { IUserProductPreferencesExposure } from '@lib/core/service/slices/productPreferencesSlice';
import {
  EXPOSURE_PREFERENCES,
  USER_NO_FOOD_PREFERENCES_DATA,
  USER_NO_TAGS_PREFERENCES_DATA,
  USER_PRODUCT_PREFERENCES_FOOD_DATA,
} from '@lib/tools/shared/helpers/consts';

export const findNoPreferencesSlug = (exposure: IUserProductPreferencesExposure) =>
  exposure === EXPOSURE_PREFERENCES ? USER_NO_FOOD_PREFERENCES_DATA : USER_NO_TAGS_PREFERENCES_DATA;

export const findUserProductPreferencesActiveSliceState = (exposure: IUserProductPreferencesExposure) =>
  exposure === EXPOSURE_PREFERENCES ? USER_PRODUCT_PREFERENCES_FOOD_DATA : B2C_USER_PRODUCT_PREFERENCES_TAGS_DATA;
