import {
  EAN_CODE_REPLACEMENT,
  RETAILER_LOCATION_SLUG_FOR_REPLACEMENT,
  RETAILER_SLUG_FOR_REPLACEMENT,
} from '@lib/core/service/consts';
import { getRequestCommonParameters } from '@lib/core/service/requests/api';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import { requestWithKeysAsOptions } from '@lib/core/service/requests/request';

export const requestCheckEanCode = async ({ eanCode }: { eanCode: string }) => {
  const { retailerLocationSlug, retailerSlug } = getRequestCommonParameters();
  return await requestWithKeysAsOptions({
    apiPath: backendApiUrls.apiUrlGetProductBasedOnEan
      .replace(RETAILER_SLUG_FOR_REPLACEMENT, retailerSlug)
      .replace(RETAILER_LOCATION_SLUG_FOR_REPLACEMENT, retailerLocationSlug)
      .replace(EAN_CODE_REPLACEMENT, eanCode),
    withErrorReturn: true,
  });
};
