import { createSelector } from 'reselect';

import { IRemoteAccessSlice } from '@lib/core/retailers/slices/remoteAccess';
import { TGenerateRetailerAccessKey } from '@lib/core/retailers/types';

/**
 * @returns the whole remoteAccess state
 */
const selectRemoteAccessState = (state): IRemoteAccessSlice => state.remoteAccess || {};

/**
 * @returns rgt expiry timestamp
 */
export const selectRemoteAccessExpiry: (state) => string = createSelector(
  [selectRemoteAccessState],
  remoteAccess => remoteAccess?.data?.expiry || '',
);

/**
 * @returns rgt number of retries
 */
export const selectRemoteAccessRetries: (state) => number = createSelector(
  [selectRemoteAccessState],
  remoteAccess => remoteAccess?.data?.retries || 0,
);

/**
 * @returns rgt access key
 */
export const selectRemoteAccessKey: (state) => string = createSelector(
  [selectRemoteAccessState],
  remoteAccess => remoteAccess?.data?.access_key || '',
);

/**
 * @returns rgt custom id
 */
export const selectRemoteAccessCustomId: (state) => string = createSelector(
  [selectRemoteAccessState],
  remoteAccess => remoteAccess?.data?.custom_id || '',
);
/**
 * @returns rgt link validity
 */
export const selectIsRemoteAccessValid: (state) => boolean = createSelector(
  [selectRemoteAccessState],
  remoteAccess => remoteAccess?.data?.is_valid || false,
);

/**
 * @returns
 */
export const selectIsRemoteAccessLoading: (state) => boolean = createSelector(
  [selectRemoteAccessState],
  remoteAccess => remoteAccess?.isLoading || false,
);

/**
 * @returns rgt errors
 */
export const selectRemoteAccessError: (state) => string = createSelector(
  [selectRemoteAccessState],
  remoteAccess => remoteAccess?.error || '',
);

/**
 * * Used only for DevTools admins to generate multiple links with custom IDs
 */
export const selectRemoteAccessLinks: (state) => TGenerateRetailerAccessKey[] = createSelector(
  [selectRemoteAccessState],
  remoteAccess => remoteAccess?.list || [],
);
