import mixpanel from 'mixpanel-browser';

import { store } from '@lib/core/service/store';
import MixpanelTracker, { MixpanelExtras } from '@lib/tools/dat/mixpanel';
import { MP_CONFIG, MP_TOKEN } from '@lib/tools/dat/mixpanel/consts';
import Events from '@lib/tools/dat/mixpanel/events';
import Profile from '@lib/tools/dat/mixpanel/profile';
import { actionMixPanelStarting } from '@lib/tools/dat/slices';

export const initMixpanel = () => {
  const state = store.getState();
  const { tracking: trackingState } = state;

  if (!trackingState.mixPanelState && MP_TOKEN && MP_CONFIG) {
    mixpanel.init(MP_TOKEN, MP_CONFIG);
    store.dispatch(actionMixPanelStarting());
    MixpanelExtras.initialize();
    MixpanelTracker.events.entryPoint();
  }
};

export class Tracker {
  /** Methods to update User Profile in Mixpanel. */
  public profile: Profile;

  /** Mixpanel tracking events. */
  public events: Events;

  constructor() {
    this.profile = new Profile();
    this.events = new Events();
  }
}
