import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import request from '@lib/core/service/requests/request';
import { apiUrlLoginClientIP } from '@lib/core/service/slices/technical/urls';

interface IClientIpApiResponse {
  disable_captcha: boolean;
  ip: string;
  status: number;
}

interface IClientIpSlice {
  data: IClientIpApiResponse;
  isLoading: boolean;
}
const initialState: IClientIpSlice = {
  data: undefined,
  isLoading: false,
};

export const actionGetClientIp = createAsyncThunk(
  'actionGetClientIp',
  async () =>
    await request(apiUrlLoginClientIP, {
      mode: 'cors',
    }),
);

export const clientIpSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetClientIp.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetClientIp.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(actionGetClientIp.fulfilled, (state, action: PayloadAction<IClientIpApiResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
    });
  },
  initialState,
  name: 'clientIp',
  reducers: {},
});

export default clientIpSlice.reducer;
