import { Snackbar } from '@mui/base/Snackbar';
import { SnackbarCloseReason } from '@mui/base/useSnackbar';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseIcon from '@mui/icons-material/Close';
import { FC, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { styled } from 'styled-components';

import { themeDevTools } from '@lib/tools/devtools/components/theme';

const StyledSnackbar = styled(Snackbar)`
  position: fixed;
  z-index: 5500;
  display: flex;
  bottom: 16px;
  right: 16px;
  max-width: 560px;
  min-width: 300px;
`;

const SnackbarContent = styled('div')(
  () => `
    display: flex;
    gap: 12px;
    overflow: hidden;
    background-color: ${themeDevTools.color.black};
    border: 1px solid ${themeDevTools.color.white};
    box-shadow: ${`0 2px 16px rgba(0,0,0, 0.5)`};
    padding: 0.75rem;
    color: ${themeDevTools.color.white};
    text-align: start;
    position: relative;
  
    & .snackbar-message {
      flex: 1 1 0%;
      max-width: 100%;
    }
  
    & .snackbar-title {
      margin: 0;
      line-height: 1.5rem;
      margin-right: 0.5rem;
    }
  
    & .snackbar-description {
      margin: 0;
      color: ${themeDevTools.color.white};
    }
  
    & .snackbar-close-icon {
      cursor: pointer;
      flex-shrink: 0;
      padding: 2px;
  
      &:hover {
        background: ${themeDevTools.color.white};
      }
    }
    `,
);

const positioningStyles = {
  entered: 'translateX(0)',
  entering: 'translateX(0)',
  exited: 'translateX(500px)',
  exiting: 'translateX(500px)',
  unmounted: 'translateX(500px)',
};

interface ISnackbarAlert {
  title: string;
  description: string;
  handleSnackbarOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

/**
 * ! Generalize this component
 */
export const SnackbarAlert: FC<ISnackbarAlert> = ({ title, description, handleSnackbarOpen, isOpen }) => {
  const [exited, setExited] = useState(true);
  const nodeRef = useRef(null);

  const handleClose = (_: any, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    handleSnackbarOpen(false);
  };

  const handleOnEnter = () => {
    setExited(false);
  };

  const handleOnExited = () => {
    setExited(true);
  };

  return (
    <>
      <StyledSnackbar autoHideDuration={5000} exited={exited} open={isOpen} onClose={handleClose}>
        <Transition
          appear
          unmountOnExit
          in={isOpen}
          nodeRef={nodeRef}
          timeout={{ enter: 400, exit: 400 }}
          onEnter={handleOnEnter}
          onExited={handleOnExited}
        >
          {status => (
            <SnackbarContent
              ref={nodeRef}
              style={{
                transform: positioningStyles[status],
                transition: 'transform 300ms ease',
              }}
            >
              <CheckRoundedIcon
                sx={{
                  color: 'success.main',
                  flexShrink: 0,
                  height: '1.5rem',
                  width: '1.25rem',
                }}
              />
              <div className="snackbar-message">
                <p className="snackbar-title">{title}</p>
                <p className="snackbar-description">{description}</p>
              </div>
              <CloseIcon className="snackbar-close-icon" onClick={handleClose} />
            </SnackbarContent>
          )}
        </Transition>
      </StyledSnackbar>
    </>
  );
};
