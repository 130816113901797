import { createSlice } from '@reduxjs/toolkit';

export interface ISearchTextFilterState {
  searchTextValue: string;
}

const initialState: ISearchTextFilterState = {
  searchTextValue: '',
};

const searchText = createSlice({
  initialState,
  name: 'searchText',
  reducers: {
    changeSearchText: (state, action) => {
      state.searchTextValue = action.payload;
    },
  },
});

export default searchText.reducer;
export const { changeSearchText } = searchText.actions;
