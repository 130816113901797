import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import rangeReducer, { IRangeFilterState } from '@lib/tools/filterManager/slices/rangeFilterSlice';
import searchTextFilterReducer, { ISearchTextFilterState } from '@lib/tools/filterManager/slices/searchTextFilterSlice';
import showOnlyReducer, { IShowOnlyFilterState } from '@lib/tools/filterManager/slices/showOnlyFilterSlice';
import sublistReducer, { ISublistFilterState } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import toggleReducer, { IToggleFilterState } from '@lib/tools/filterManager/slices/toggleFilterSlice';
import { ORDERING_TYPE_PRICE, ORDERING_TYPE_RANK } from '@lib/tools/shared/helpers/consts';

export type TProductCatalogOrdering = typeof ORDERING_TYPE_PRICE | typeof ORDERING_TYPE_RANK;

interface IFilterManagerState {
  isFilterOpened: boolean;
  updatedFilterValuesTime: string;
  ordering: string;
}

export interface IProductFilterState {
  filterManager: IFilterManagerState;
  range: IRangeFilterState;
  showOnly: IShowOnlyFilterState;
  sublist: ISublistFilterState;
  toggle: IToggleFilterState;
  searchText: ISearchTextFilterState;
}

const initialState: IFilterManagerState = {
  isFilterOpened: false,
  ordering: ORDERING_TYPE_RANK,
  updatedFilterValuesTime: null,
};

const filterManagerSlice = createSlice({
  initialState,
  name: 'filterManager',
  reducers: {
    actionApplyCatalogFilter: state => {
      state.isFilterOpened = false;
      state.updatedFilterValuesTime = new Date().getTime().toString();
    },
    actionApplyCatalogOrdering: (state, action: PayloadAction<string>) => {
      state.ordering = action.payload;
      state.updatedFilterValuesTime = new Date().getTime().toString();
    },
    actionCloseCatalogFilter: state => {
      state.isFilterOpened = false;
    },
    actionOpenCatalogFilter: state => {
      state.isFilterOpened = true;
    },
    actionResetCatalogFilters: () => {
      return initialState;
    },
    actionResetFilterValuesUpdatedTime: state => {
      state.updatedFilterValuesTime = null;
    },
  },
});

export const {
  actionApplyCatalogFilter,
  actionCloseCatalogFilter,
  actionApplyCatalogOrdering,
  actionOpenCatalogFilter,
  actionResetCatalogFilters,
  actionResetFilterValuesUpdatedTime,
} = filterManagerSlice.actions;

const productFilter = combineReducers<IProductFilterState>({
  filterManager: filterManagerSlice.reducer,
  range: rangeReducer,
  searchText: searchTextFilterReducer,
  showOnly: showOnlyReducer,
  sublist: sublistReducer,
  toggle: toggleReducer,
});
const filtersReducer = (state, action) => {
  switch (action.type) {
    case actionResetCatalogFilters.type:
      return productFilter(undefined, action);
    case actionOpenCatalogFilter.type:
      return productFilter(
        {
          ...state,
          filterManager: {
            ...state.filterManager,
            isFilterOpened: true,
            range: { ...state.range },
            showOnly: { ...state.showOnly },
            sublist: { ...state.sublist },
            toggle: { ...state.toggle },
          },
        },
        action,
      );
    case actionCloseCatalogFilter.type:
      return productFilter({ ...state.filterManager }, action);

    default:
      productFilter(state, action);
  }

  return productFilter(state, action);
};
export default filtersReducer;
