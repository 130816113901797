class ScoreMatrixUtils {
  // ! Refactor. Invoke once and store the result.
  public static transformRawScoreMatrix(rawScoreMatrix: any) {
    const scores: any = {};
    let characters: string[] = [];

    const demystifyMatrix = (row: any, index: number) => {
      // Grab the list of characters identifiers on first iteration.
      if (!index) characters = Object.keys(row);
      // Clean copy before deletion
      const questionId = `${row.question_id}`;
      const answerId = `${row.answer_id}`;
      delete row.question_id;
      delete row.answer_id;

      if (!scores[questionId]) scores[questionId] = {};
      scores[questionId][answerId] = Object.values(row).map(Number);
    };

    rawScoreMatrix.forEach(demystifyMatrix);
    characters = characters.splice(2, characters.length); // remove unwanted keys from row

    return { characters, scores };
  }
}

export default ScoreMatrixUtils;
