// TEREA ESSENCES ID
export const TEREA_ESSENCE_AROMA_ID = 'TE001';
export const TEREA_ESSENCE_BODY_ID = 'TE002';
export const TEREA_ESSENCE_INTENSITY_ID = 'TE003';
export const TEREA_ESSENCE_COOLING_ID = 'TE004';

export const TEREA_ESSENCES_ORDER = [
  TEREA_ESSENCE_COOLING_ID,
  TEREA_ESSENCE_INTENSITY_ID,
  TEREA_ESSENCE_AROMA_ID,
  TEREA_ESSENCE_BODY_ID,
];

// HEETS ESSENCES IH
export const HEETS_ESSENCE_BODY_ID = 'HE001';
export const HEETS_ESSENCE_AROMA_ID = 'HE002';
export const HEETS_ESSENCE_INTENSITY_ID = 'HE003';
export const HEETS_ESSENCE_COOLING_ID = 'HE004';

export const HEETS_ESSENCES_ORDER = [
  HEETS_ESSENCE_COOLING_ID,
  HEETS_ESSENCE_INTENSITY_ID,
  HEETS_ESSENCE_BODY_ID,
  HEETS_ESSENCE_AROMA_ID,
];

export const TEREA_EU = 'terea-eu';
export const HEETS_EU = 'heets-eu';
