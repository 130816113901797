import { useMediaQuery } from 'react-responsive';

import leviaBackground from '@app/pmi/src/assets/media/V2/levia/levia_background.png';
import leviaDevice from '@app/pmi/src/assets/media/V2/levia/leviaDevice.png';
import leviaLogo from '@app/pmi/src/assets/media/V2/levia/leviaLogo.png';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

function LeviaBanner() {
  const isMobileResolution = useMediaQuery({ maxWidth: 767 });

  return (
    <div
      className="ta-v2-levia-banner"
      style={{
        backgroundImage: `linear-gradient(89deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%), url(${leviaBackground})`,
      }}
    >
      <img alt="device" className="ta-v2-levia-banner-device" src={leviaDevice} />
      <div className="ta-v2-levia-banner-content">
        <div className={`${isMobileResolution ? 'font-ta-v2-global-caption-small' : 'font-ta-v2-title-6'}`}>
          <LocaleFragment message={localeV2.result.leviaBannerTextOne} />
        </div>
        <div
          className={`${isMobileResolution ? 'font-ta-v2-global-caption-small' : 'font-ta-v2-title-6'} margin-bottom-10px`}
        >
          <LocaleFragment message={localeV2.result.leviaBannerTextTwo} />
        </div>
        <div className="font-ta-v2-custom-small-header-bold-v2">
          <LocaleFragment message={localeV2.result.leviaBannerCaptionOne} />
        </div>
        <div className="font-ta-v2-custom-small-header-bold-v2">
          <LocaleFragment message={localeV2.result.leviaBannerCaptionTwo} />
        </div>
      </div>
      <img alt="logo" className="ta-v2-levia-banner-logo" src={leviaLogo} />
    </div>
  );
}

export default LeviaBanner;
