import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrlGetWellness } from '@lib/core/productAttributes/slices/urls';
import { TWellness } from '@lib/core/productAttributes/types';
import request from '@lib/core/service/requests/request';

export interface IWellnessState {
  data: TWellness[];
  error: string;
  isLoading: boolean;
}

export const initialState: IWellnessState = {
  data: [],
  error: '',
  isLoading: false,
};

export const actionGetWellness = createAsyncThunk(
  'productAttributes/wellness/get',
  async () => await request(apiUrlGetWellness),
);

const wellnessSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetWellness.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetWellness.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    });
    builder.addCase(actionGetWellness.rejected, (state, action: any) => {
      if (action.payload?.errorMessage) {
        state.error = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.error = action.error.message;
      }
      state.isLoading = false;
    });
  },
  initialState,
  name: 'wellness',
  reducers: {},
});

export default wellnessSlice.reducer;
