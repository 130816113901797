import { TProductInstance } from '@lib/core/products/types';
import { IFeedbackData, TFeedback } from '@lib/core/users/slices/feedbacks';
import { ILocation } from '@lib/core/users/types';

export const isProductInWishlistFilter = (wishlistProductIds: string[] = [], productInstanceData: TProductInstance) =>
  wishlistProductIds?.includes(productInstanceData?.product?.identifier);

export const isLocationInWishlistFilter = (locationData: ILocation[] = [], locationId: string) =>
  locationData?.map(data => data?.retailer_location?.identifier).includes(locationId);

export const feedbackFilter = (feedbackData: IFeedbackData[], productInstanceData: TProductInstance): TFeedback =>
  feedbackData.find(feedback => feedback?.gprl?.identifier === productInstanceData?.identifier)?.feedback;
