/* eslint-disable class-methods-use-this */
/* eslint-disable no-dupe-class-members */
import mixpanel from 'mixpanel-browser';

import { store } from '@lib/core/service/store';
import { selectMixpanelUserDidIdentify } from '@lib/core/users/selectors/user';
import { MP_EVENTS, MP_PROPERTIES, SUPER_PROP_PREFIX } from '@lib/tools/dat/mixpanel/consts';
import { checkIfMixpanelExists, logEventForQA } from '@lib/tools/dat/mixpanel/decorators';
import { TMPSuperProperties, TMPUserProfileProperties } from '@lib/tools/dat/mixpanel/types';
import { actionMixPanelDidIdentify, actionMixPanelDidNotIdentify } from '@lib/tools/dat/slices';

type Primitive = string | number | boolean;
type Exact<T, U> = T & Record<Exclude<keyof U, keyof T>, never>;
type DeepestValues<T> = T extends string
  ? never
  : { [K in keyof T]: T[K] extends string ? T[K] : DeepestValues<T[K]> }[keyof T];
type MixpanelEventName = DeepestValues<typeof MP_EVENTS>;
type MixpanelPropertyKey = keyof typeof MP_PROPERTIES;
type MixpanelPropertyType = string | number | boolean | Date | string[];
type MixpanelProperty = Partial<Record<MixpanelPropertyKey, MixpanelPropertyType>>;

/** Utility class for tracking library. */
@checkIfMixpanelExists()
export default class Utilities {
  /**
   * Return the size of a collection, either an array or an object.
   *
   * Poor man's implementation of `Lodash.size()`. See {@link https://lodash.com/docs/4.17.15#size}
   *
   * @param collection - The collection to get the size of
   * @returns The number of items in the collection or 0 if the collection is null or undefined
   */
  public size<T>(collection: Array<T> | Record<string, T>) {
    if (collection == null) {
      return 0;
    }
    return Object.keys(collection).length;
  }

  /**
   * Filter an object based on a predicate function, returning a new object with only the keys
   * that satisfy the predicate.
   *
   * Poor man's implementation of `Lodash.pickBy()`. See {@link https://lodash.com/docs/4.17.15#pickBy}
   *
   * @param object - The object to be filtered
   * @param predicate - A function to test each property value
   * @returns A new object with only the keys that satisfy the predicate
   */
  public pickBy<T extends Primitive | Primitive[] | Record<string, any>>(
    object: Record<string, T>,
    predicate: (v: T) => boolean,
  ) {
    if (object == null) {
      return {};
    }
    const objectKeys = Object.keys(object);
    return objectKeys.reduce((obj, key) => {
      let newObject = obj;
      const child = obj[key];
      if (!predicate(child)) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { [key]: _remove, ...rest } = obj;
        newObject = rest;
      }
      return newObject;
    }, object);
  }

  /**
   * Remap keys of `body` using the values from `map`.
   *
   * Mixpanel encourages using human readable event names and properties for its web platform.
   * Of course, this guideline is not compatible when defining object keys.
   *
   * This function solves the problem by remapping the keys of the first argument
   * using the values of the second argument.
   *
   * Typing makes sure that all the keys in the first argument are matched in the second.
   */
  public remap<T extends Exact<MixpanelProperty, T>>(body: T) {
    const remapped: Record<string, T[keyof T]> = {};
    Object.keys(body).forEach(key => {
      const newKey = MP_PROPERTIES[key];
      remapped[newKey] = body[key];
    });
    return remapped;
  }

  public getDateTime() {
    return new Date().toISOString();
  }

  @logEventForQA()
  public identify(identifier: string) {
    mixpanel.identify(identifier);
    store.dispatch(actionMixPanelDidIdentify());
  }

  public get didIdentify() {
    return selectMixpanelUserDidIdentify(store.getState());
  }

  @logEventForQA()
  public reset() {
    mixpanel.reset();
    store.dispatch(actionMixPanelDidNotIdentify());
  }

  /**
   * Set properties in the User Profile on the Mixpanel platform.
   *
   * @param args - Object containing the properties to set as per {@link TMPUserProfileProperties this interface}
   * @param overwrite - Whether the old properties should be overwritten by the new ones
   */
  @logEventForQA(true)
  public setUserProfileProperties<T extends Exact<Partial<TMPUserProfileProperties>, T>>(args: T, overwrite: boolean) {
    if (!this.didIdentify) return;

    const userArgs = this.remap(args as Partial<TMPUserProfileProperties>);
    if (overwrite) {
      mixpanel.people.set(userArgs);
    } else {
      mixpanel.people.set_once(userArgs);
    }
  }

  /**
   * Set properties locally in Mixpanel's cookie.
   *
   * @param args - Object containing the properties to set as per {@link TMPSuperProperties this interface}
   * @param overwrite - Whether the old properties should be overwritten by the new ones
   */
  @logEventForQA()
  public setSuperProperties<T extends Exact<Partial<TMPSuperProperties>, T>>(args: T, overwrite: boolean) {
    const superProps = this.remap(args as Partial<TMPSuperProperties>);
    const prefixedSuperProps: Record<string, MixpanelPropertyType> = Object.entries(superProps).reduce(
      (prev, [key, value]) => ({ ...prev, [SUPER_PROP_PREFIX + key]: value }),
      {},
    );
    if (overwrite) {
      mixpanel.register(prefixedSuperProps);
    } else {
      mixpanel.register_once(prefixedSuperProps);
    }
  }

  /**
   * Retrieve the value of the specified super property.
   * Returns `undefined` if the property is not set.
   *
   * @param superProp - The name of the super property to retrieve.
   */
  public getSuperProperty(superProp: string) {
    return mixpanel.get_property(SUPER_PROP_PREFIX + superProp);
  }

  /**
   * Track an event with Mixpanel.
   *
   * @param eventName - Name of the event. Must exist in {@link MP_EVENTS this object}
   */
  public track(eventName: MixpanelEventName): void;
  /**
   * Track an event with Mixpanel and include additional properties.
   *
   * @param eventName - Name of the event. Must exist in {@link MP_EVENTS this object}
   * @param args - Set of properties to include with the event. Must exist in {@link MixpanelProperty}
   */
  public track<T extends Exact<MixpanelProperty, T>>(eventName: MixpanelEventName, args: T): void;
  @logEventForQA()
  public track<T extends Exact<MixpanelProperty, T>>(eventName: MixpanelEventName, args?: T) {
    let newArgs = {};
    if (args) {
      newArgs = this.remap(args);
    }
    mixpanel.track(eventName, newArgs);
  }
}
