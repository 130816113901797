import { FC } from 'react';
import Recaptcha from 'react-google-recaptcha';

import { ENV_RECAPTCHA_SITE_KEY } from '@lib/core/service/consts';

interface IReCaptchaContainer {
  setIsBtnDisabled: (args: boolean) => void;
  isDisabled: boolean;
  recaptchaRef: Record<string, any>;
  handleValue: (recaptchaValue: string) => void;
}

const ReCaptchaContainer: FC<IReCaptchaContainer> = ({ recaptchaRef, setIsBtnDisabled, isDisabled, handleValue }) => {
  const asyncScriptOnLoad = () => {
    setIsBtnDisabled(false);
  };

  return isDisabled ? (
    <Recaptcha
      ref={recaptchaRef}
      asyncScriptOnLoad={asyncScriptOnLoad}
      sitekey={ENV_RECAPTCHA_SITE_KEY}
      size="invisible"
      onChange={handleValue}
    />
  ) : null;
};

export default ReCaptchaContainer;
