import { useSelector } from 'react-redux';

import { selectQuizzes, selectQuizzesFetching } from '@lib/core/quizzes/selectors';
import { TQuiz } from '@lib/core/quizzes/types';

type IProps = {
  isQuizzesFetching: boolean;
  quizzes: TQuiz[];
};

export const useQuizzes = (): IProps => {
  return {
    isQuizzesFetching: useSelector(selectQuizzesFetching),
    quizzes: useSelector(selectQuizzes),
  };
};
