import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IRouteState {
  basename: string;
}

export const initialState: IRouteState = {
  basename: '',
};

export const routeSlice = createSlice({
  initialState,
  name: 'route',
  reducers: {
    setRouteBasename: (state, action: PayloadAction<{ basename: string }>) => {
      state.basename = action.payload.basename;
    },
  },
});

export default routeSlice.reducer;
export const { setRouteBasename } = routeSlice.actions;
