import { createSelector } from '@reduxjs/toolkit';

import { TProductInstance } from '@lib/core/products/types';
import { RootState } from '@lib/core/service/types/appStateType';

const selectWishlist = (state: RootState) => state?.wishlist;

const selectWishlistProductIds = createSelector(
  [selectWishlist],
  (wishlist): string[] => wishlist.data.map(data => data?.product?.identifier) || [],
);

const selectIsWishlistLoading = createSelector([selectWishlist], (wishlist): boolean => wishlist.isLoading || false);

const selectWishListData = createSelector([selectWishlist], (wishlist): TProductInstance[] => wishlist.data || []);

const selectIsWishlistLoaded = (state: RootState): boolean => state.wishlist.isWishlistLoaded;

export { selectWishlistProductIds, selectIsWishlistLoaded, selectIsWishlistLoading, selectWishListData };
