import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import request from '@lib/core/service/requests/request';
import { apiUrlCountriesList } from '@lib/core/service/slices/technical/urls';

export type ICountrySelectOption = {
  name: string;
  flag: string;
  code: string;
};

type IState = {
  countriesList: ICountrySelectOption[];
  isCountriesListLoading: boolean;
  countriesError: string;
};

export const initialState: IState = {
  countriesError: '',
  countriesList: [],
  isCountriesListLoading: false,
};

export const actionFetchCountriesList = createAsyncThunk(
  'actionFetchCountriesList',
  async () => await request(apiUrlCountriesList),
);

const b2cCountriesSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionFetchCountriesList.pending, state => {
      state.isCountriesListLoading = true;
    });
    builder.addCase(actionFetchCountriesList.fulfilled, (state, action: PayloadAction<ICountrySelectOption[]>) => {
      state.isCountriesListLoading = false;
      state.countriesList = action.payload;
    });
    builder.addCase(actionFetchCountriesList.rejected, (state, action: any) => {
      state.isCountriesListLoading = false;
      if (action.payload?.errorMessage) {
        state.countriesError = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.countriesError = action.error.message;
      }
    });
  },
  initialState,
  name: 'countries',
  reducers: {},
});

export default b2cCountriesSlice.reducer;
