import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { TCharacter } from '@lib/core/characters/types';
import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { uuidv4 } from '@lib/core/service/utils';
import { ILoginResponse, actionFacebookLogin, actionGoogleLogin, actionLogin } from '@lib/core/users/slices/auth';
import locationListReducer, { ILocationListState } from '@lib/core/users/slices/locationList';
import productListReducer, { IProductListState } from '@lib/core/users/slices/productList';
import profileReducer, { IProfileState } from '@lib/core/users/slices/profile';
import { uploadUserPictureApiUrlCreator, userDataApiUrlCreator } from '@lib/core/users/slices/urls';
import { TUser } from '@lib/core/users/types';

export interface IUserSlice {
  data: TUser | undefined;
  isLoading: boolean;
  error: string;
  isFullUserDataLoadedTemporaryHint: boolean;
  isUploadingProfilePicture: boolean;
  profile: IProfileState;
  productList: IProductListState;
  locationList: ILocationListState;
}

const initialState: IUserSlice = {
  data: undefined,
  error: '',
  isFullUserDataLoadedTemporaryHint: false,
  isLoading: false,
  isUploadingProfilePicture: false,
  locationList: locationListReducer(undefined, { type: '' }),
  productList: productListReducer(undefined, { type: '' }),
  profile: profileReducer(undefined, { type: '' }),
};

export const actionUploadUserPicture = createTypedAsyncThunk(
  'actionUploadUserPicture',
  async (imagebase64Data: string) => {
    return await request(
      uploadUserPictureApiUrlCreator(),
      { method: 'PUT' },
      {
        base64: imagebase64Data,
        destination: 'profile_image',
      },
    );
  },
);

export const actionGetUserData = createTypedAsyncThunk('actionGetUserData', async () => {
  return await request(userDataApiUrlCreator());
});

export const actionUpdateUserData = createTypedAsyncThunk(
  'actionUpdateUserData',
  async (requestParams: Partial<TUser>, { rejectWithValue }) => {
    try {
      return await request(userDataApiUrlCreator(), { method: REQUEST_METHODS.PATCH }, requestParams);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const userSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetUserData.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionUploadUserPicture.pending, state => {
      state.isUploadingProfilePicture = true;
    });
    builder.addCase(actionGetUserData.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(actionUploadUserPicture.fulfilled, state => {
      state.isUploadingProfilePicture = false;
    });
    builder.addCase(actionUploadUserPicture.rejected, state => {
      state.isUploadingProfilePicture = false;
    });
    builder.addMatcher(
      isAnyOf(actionGetUserData.fulfilled, actionUpdateUserData.fulfilled),
      (state, action: PayloadAction<TUser>) => {
        // Ignore null user_session_id after registrations. patchQuizComplete associates
        // the anonymous user_session_id to the newly registered user.
        const payload = { ...action.payload };
        const userSessionId = payload.user_session_id || state.data.user_session_id;
        state.data = { ...payload, user_session_id: userSessionId };
        state.isFullUserDataLoadedTemporaryHint = true;
        state.isLoading = false;
      },
    );
    builder.addMatcher(isAnyOf(actionGetUserData.rejected, actionUpdateUserData.rejected), (state, action: any) => {
      if (action.payload?.errorMessage) {
        state.error = action.payload.errorMessage;
      } else if (action.error?.message) {
        state.error = action.error.message;
      }
      state.isLoading = false;
    });
    // Listening for login actions from auth slice, which also returns partially user data
    builder.addMatcher(
      isAnyOf(actionLogin.fulfilled, actionGoogleLogin.fulfilled, actionFacebookLogin.fulfilled),
      (state, action: PayloadAction<ILoginResponse>) => {
        const { payload: { user } = {} } = action;
        state.data = state.data ? { ...state.data, ...user } : user;
      },
    );
    builder.addDefaultCase((state, action) => {
      state.profile = profileReducer(state.profile, action);
      state.productList = productListReducer(state.productList, action);
      state.locationList = locationListReducer(state.locationList, action);
    });
  },
  initialState,
  name: 'user',
  reducers: {
    actionGenerateUserSession: state => {
      state.data = { ...state.data, user_session_id: uuidv4() };
    },
    actionResetUserSlice: () => initialState,
    actionSetUserCharacters: (state, { payload }: { payload: TCharacter[] }) => {
      state.data = { ...state.data, characters: payload || [] };
    },
  },
});

export default userSlice.reducer;

export const { actionResetUserSlice, actionGenerateUserSession, actionSetUserCharacters } = userSlice.actions;
