import { useDispatch } from 'react-redux';

import { useQuiz, useQuizView } from '@lib/core/quizzes/hooks';
import { actionUpdateQuizAnswerTag } from '@lib/core/quizzes/slices/quizView';

export const QuizAnswerTagsTab = () => {
  const dispatch = useDispatch();

  const { quizAnswerTags } = useQuiz();
  const { quizViewAnswerTags } = useQuizView();

  const listQuizAnswerTags = [];
  const uniqueContexts = new Set([]);

  quizAnswerTags.forEach(answerTag => uniqueContexts.add(answerTag.context));

  uniqueContexts.forEach(context => {
    const tags = quizAnswerTags.filter(qat => qat.context === context);
    const tagsForCurrentContext = [];

    tags.forEach((answerTag, i) => {
      const isChecked = quizViewAnswerTags.some(
        selectedTag => JSON.stringify(selectedTag) === JSON.stringify(answerTag),
      );

      tagsForCurrentContext.push(
        <div key={i}>
          <input
            checked={isChecked}
            className="mr-2"
            name={`${answerTag.context}.${answerTag.name}`}
            type="checkbox"
            onChange={e => {
              e.stopPropagation();
              dispatch(
                actionUpdateQuizAnswerTag({
                  answerTag: quizAnswerTags.filter(qat => `${qat.context}.${qat.name}` === e.target.name)[0],
                }),
              );
            }}
          />
          <span>{answerTag.name}</span>
        </div>,
      );
    });

    listQuizAnswerTags.push(
      <div key={context}>
        <div className="m-2 font-weight-bold title">{context}</div>
        <span className="code">{tagsForCurrentContext}</span>
      </div>,
    );
  });

  return (
    <>
      <div className="panel collapsible" role="presentation">
        <span className="title">Tags</span>
      </div>

      <div className="panel">{listQuizAnswerTags}</div>
    </>
  );
};
