import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { selectAppliedAddOns } from '@lib/core/retailers/selectors/retailerLocation';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { requestCheckEanCode } from '@lib/core/service/requests/apiRequestsCreators';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { prependBasename } from '@lib/core/service/utils';
import { history } from '@lib/core/service/utils/Navigator';
import { handleFidelityCodeThunk } from '@lib/core/users/slices/fidelityCard';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { PRODUCT_ID_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { PAGES } from '@lib/tools/views/urls';

const checkIfProductEan = async code => {
  // WARN: eanScannerResponse interface cannot be IB2BB2CGPRL as that is the
  // Open Search interface. It has been replaced with "any" due to tracking issues
  // (see event `scanProduct` to see details).
  const eanScannerResponse: any[] = await requestCheckEanCode({ eanCode: code });
  const productId = eanScannerResponse && eanScannerResponse[0]?.identifier;
  return { eanScannerResponse, productId };
};

const handleEanCode = eanCode => {
  history.push(prependBasename(PAGES.vinhood.product, { [PRODUCT_ID_URL_PARAM]: eanCode }));
};
const initialState = {
  isScanningLoading: false,
};
export const scanCode = createTypedAsyncThunk('table/scanCode', async (code: string, { getState, dispatch }) => {
  const { isEnableKioskFidelityScanAddon } = selectAppliedAddOns(getState());
  const productCategory = selectServiceProductCategory(getState());

  // Check if the scanned code is a fidelity code
  const { productId, eanScannerResponse } = await checkIfProductEan(code);

  // If the code is an EAN code, handle it and reject the Promise
  if (productId) {
    handleEanCode(productId);
    MixpanelTracker.events.scanProduct(eanScannerResponse[0], code);
    return Promise.reject();
  }

  if (isEnableKioskFidelityScanAddon) {
    // If the code is a fidelity code, handle it
    return dispatch(
      handleFidelityCodeThunk({
        fidelityCardCode: code,
        productCategory,
      }),
    );
  }

  return true;
});

export const scannerSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(scanCode.pending, state => {
      state.isScanningLoading = true;
    });
    builder.addMatcher(isAnyOf(scanCode.fulfilled, scanCode.rejected), state => {
      state.isScanningLoading = false;
    });
  },
  initialState,
  name: 'scanner',
  reducers: {},
});

export default scannerSlice.reducer;
