import cn from 'classnames';
import { FC } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

type Props = {
  score: number;
  name: string;
  color: string;
  isHorizontalOrientation?: boolean;
  isDimensionSmall?: boolean;
  className?: string;
};

const EUessenceComponent: FC<Props> = ({
  score,
  name,
  color,
  isHorizontalOrientation = false,
  isDimensionSmall = false,
  className,
}) => {
  const progressPercent = score * 10;

  return (
    <div
      className={cn('ta-v2-eu-essence', className, {
        isDimensionSmall,
        isHorizontalOrientation,
      })}
    >
      <div className="ta-v2-eu-essence-progressbar-wrapper">
        <span
          className={cn({
            'font-ta-v2-custom-large-paragraph-bold': !isDimensionSmall,
            'font-ta-v2-global-hint-text-medium font-weight-bold': isDimensionSmall,
          })}
        >
          {score}
        </span>
        <CircularProgressbar
          circleRatio={0.85}
          className="ta-v2-eu-essence-progressbar"
          strokeWidth={10}
          value={progressPercent}
          styles={{
            path: {
              stroke: color,
              strokeLinecap: 'round',
              transform: 'rotate(-153deg)',
              transformOrigin: 'center center',
            },
            trail: {
              stroke: '#B8B7BB',
              strokeLinecap: 'round',
              transform: 'rotate(-153deg)',
              transformOrigin: 'center center',
            },
          }}
        />
      </div>
      <div
        className={cn('ta-v2-eu-essence-text', {
          'font-ta-v2-global-body-small font-weight-bold': !isDimensionSmall,
          'font-ta-v2-global-hint-text-medium font-weight-bold': isDimensionSmall,
        })}
      >
        <span>{name}</span>
      </div>
    </div>
  );
};

export default EUessenceComponent;
