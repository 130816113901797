import { TProductEssenceScore } from '@lib/core/products/types';
import {
  HEETS_ESSENCES_ORDER,
  HEETS_ESSENCE_AROMA_ID,
  HEETS_ESSENCE_BODY_ID,
  HEETS_ESSENCE_COOLING_ID,
  TEREA_ESSENCES_ORDER,
  TEREA_ESSENCE_AROMA_ID,
  TEREA_ESSENCE_BODY_ID,
  TEREA_ESSENCE_COOLING_ID,
} from '@lib/tools/shared/pmi/essences/consts';

export function sortEssences(list: TProductEssenceScore[], essenceIds: string[]): TProductEssenceScore[] {
  const result: TProductEssenceScore[] = [];
  const grouped = list.reduce((map: { [key: string]: TProductEssenceScore }, obj: TProductEssenceScore) => {
    const m = { ...map };
    m[obj.essence_id] = obj;
    return m;
  }, {});

  for (let i = 0; i < essenceIds.length; i += 1) {
    const essenceId = essenceIds[i];
    const val = grouped[essenceId];

    if (val) {
      result.push(val);
    }
  }

  return result;
}

export const reOrderEssencesForIQOS3Designs = (reOrderEssencesArguments: {
  essences: TProductEssenceScore[];
  isEssenceCoolingDisabled: boolean;
  isEssenceBodyDisabled: boolean;
  isEssenceAromaDisabled: boolean;
}): TProductEssenceScore[] => {
  const { essences, isEssenceAromaDisabled, isEssenceBodyDisabled, isEssenceCoolingDisabled } =
    reOrderEssencesArguments;
  let sortedEssences = sortEssences(essences, HEETS_ESSENCES_ORDER);

  if (isEssenceCoolingDisabled) {
    sortedEssences = sortedEssences.filter(essence => essence.essence_id !== HEETS_ESSENCE_COOLING_ID);
  }

  if (isEssenceBodyDisabled) {
    sortedEssences = sortedEssences.filter(essence => essence.essence_id !== HEETS_ESSENCE_BODY_ID);
  }

  if (isEssenceAromaDisabled) {
    sortedEssences = sortedEssences.filter(essence => essence.essence_id !== HEETS_ESSENCE_AROMA_ID);
  }

  return sortedEssences;
};

export const reOrderEssencesForIQOS4Designs = (reOrderEssencesArguments: {
  essences: TProductEssenceScore[];
  isEssenceCoolingDisabled: boolean;
  isEssenceBodyDisabled: boolean;
  isEssenceAromaDisabled: boolean;
}): TProductEssenceScore[] => {
  const { essences, isEssenceAromaDisabled, isEssenceBodyDisabled, isEssenceCoolingDisabled } =
    reOrderEssencesArguments;
  let sortedEssences = sortEssences(essences, TEREA_ESSENCES_ORDER);

  if (isEssenceCoolingDisabled) {
    sortedEssences = sortedEssences.filter(essence => essence.essence_id !== TEREA_ESSENCE_COOLING_ID);
  }

  if (isEssenceAromaDisabled) {
    sortedEssences = sortedEssences.filter(essence => essence.essence_id !== TEREA_ESSENCE_AROMA_ID);
  }

  if (isEssenceBodyDisabled) {
    sortedEssences = sortedEssences.filter(essence => essence.essence_id !== TEREA_ESSENCE_BODY_ID);
  }

  return sortedEssences;
};

export const isCoolingEssencePresent = (essences: TProductEssenceScore[]): boolean => {
  const coolingEssence = essences.find(
    essence => essence.essence_id === TEREA_ESSENCE_COOLING_ID || essence.essence_id === HEETS_ESSENCE_COOLING_ID,
  );

  return Number(coolingEssence?.score) > 0;
};
