import { createSlice } from '@reduxjs/toolkit';

export interface IComms {
  isHostHandshakeApproved: boolean;
  hostRedirectUrl: string;
  hostLeviaRedirectUrl: string;
  hostCustomId: string;
}

const initialState: IComms = {
  hostCustomId: '',
  hostLeviaRedirectUrl: '',
  hostRedirectUrl: '',
  isHostHandshakeApproved: false,
};

export const commsSlice = createSlice({
  initialState,
  name: 'comms',
  reducers: {
    actionSetCommsHandshake(state, action) {
      state.isHostHandshakeApproved = action.payload;
    },
    actionSetCommsHostCustomId(state, action) {
      state.hostCustomId = action.payload;
    },
    actionSetCommsHostRedirectUrl(state, action) {
      state.hostRedirectUrl = action.payload;
    },
    actionSetCommsHostRedirectUrlLevia(state, action) {
      state.hostLeviaRedirectUrl = action.payload;
    },
  },
});

export default commsSlice.reducer;

export const {
  actionSetCommsHandshake,
  actionSetCommsHostRedirectUrl,
  actionSetCommsHostCustomId,
  actionSetCommsHostRedirectUrlLevia,
} = commsSlice.actions;
