import BuildIcon from '@mui/icons-material/Build';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import PestControlIcon from '@mui/icons-material/PestControl';
import ScienceIcon from '@mui/icons-material/Science';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Resizable } from 're-resizable';
import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { ENV_DEVTOOLS_TOKEN, URL_PARAM_TOOLS, isApplicationKiosk } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { setIsDevToolsEnabled } from '@lib/core/service/slices';
import { actionUpdateTrackingLogs } from '@lib/tools/dat/slices';
import StyledTooltip from '@lib/tools/devtools/components/feedback/Tooltip';
import { themeDevTools } from '@lib/tools/devtools/components/theme';
import { LocalePanel } from '@lib/tools/devtools/panels/locale/LocalePanel';
import { LoggingPanel } from '@lib/tools/devtools/panels/logging/LoggingPanel';
import { RemoteAccessPanel } from '@lib/tools/devtools/panels/remoteAccess/RemoteAccessPanel';
import { SensoryPanel } from '@lib/tools/devtools/panels/sensory/SensoryPanel';
import { ServicePanel } from '@lib/tools/devtools/panels/service/ServicePanel';
import { useEffectSkipFirst } from '@lib/tools/views/hooks';

import '@lib/tools/devtools/dev_tools.scss';

/**
 * @todo add toggles for isAppFa, isAppKiosk
 * @todo add a toggle for mocking hosting sites and iframes
 */
export const DevTools = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { isDevToolsEnabled, isHostMocked } = useApp();

  // Panel names
  const PANELS = {
    closed: 'closed',
    linkGenerator: 'linkGenerator',
    locale: 'locale',
    logging: 'logging',
    sensory: 'sensory',
    service: 'service',
  };

  const [theme, setTheme] = useState(true);
  const [utilsViewSize, setUtilsViewSize] = useState({ height: 35, width: 38 });
  const resizableElementRef = useRef<Resizable>(null);
  const [view, setView] = useState(PANELS.closed);

  const handleChange = (_event: MouseEvent<HTMLElement>, nextView: string) => {
    setView(nextView);
  };

  useEffect(() => {
    // Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

    if (ENV_DEVTOOLS_TOKEN) {
      const urlUtilsToken = searchParams.get(URL_PARAM_TOOLS);

      if (urlUtilsToken === ENV_DEVTOOLS_TOKEN || isDevToolsEnabled) {
        dispatch(setIsDevToolsEnabled());

        // ! Log collector
        window.onerror = e => {
          dispatch(actionUpdateTrackingLogs({ log: { args: JSON.stringify(e), eventName: 'error', type: 'service' } }));
        };
      }
    }
  }, []);

  useEffect(() => {
    resizableElementRef.current?.updateSize(utilsViewSize);
  }, [utilsViewSize]);

  useEffectSkipFirst(() => {
    const newSize = { height: 35, width: 38 };
    const maxHeight = 47.2 * 6;

    switch (view) {
      case PANELS.closed:
        if (utilsViewSize.height <= 48) newSize.height = maxHeight;
        setUtilsViewSize(newSize);
        break;
      case PANELS.service:
      case PANELS.locale:
      case PANELS.sensory:
      case PANELS.logging:
      case PANELS.linkGenerator:
        if (utilsViewSize.width <= 48) {
          newSize.height = window.innerHeight - 20;
          if (window.innerWidth <= 500) newSize.width = window.innerWidth - 20;
          else newSize.width = window.innerWidth * 0.33;
          setUtilsViewSize(newSize);
        }
        break;
      default:
        if (utilsViewSize.width === 38 && utilsViewSize.height === 35) {
          newSize.height = maxHeight;
        } else {
          newSize.height = 35;
        }
        setUtilsViewSize(newSize);
        break;
    }
  }, [view]);

  useEffect(() => {
    if (!isApplicationKiosk) return;
    const rootElement = document.getElementsByClassName('root-element-kiosk')?.[0];
    if (isHostMocked) {
      rootElement?.setAttribute('class', 'root-element-kiosk mocked');
      rootElement?.setAttribute('style', 'border: 0px; height: 1920px; width: 1080px;');
    } else {
      rootElement?.setAttribute('class', 'root-element-kiosk');
      rootElement?.removeAttribute('style');
    }
  }, [isHostMocked]);

  if (!isDevToolsEnabled) return null;

  return (
    <>
      <div className="tools-wrapper">
        <div
          className={`panels-wrapper ${theme ? 'dark' : ''}`}
          role="presentation"
          onClick={event => {
            if (event.detail === 3) {
              return;
              // ! improve light theme
              // eslint-disable-next-line no-unreachable
              setTheme(current => !current);
            }
          }}
        >
          <Resizable
            ref={resizableElementRef}
            defaultSize={utilsViewSize}
            handleWrapperClass="handles"
            minHeight={48}
            minWidth={48}
            onResizeStop={(_e, _direction, _ref, d) => {
              setUtilsViewSize({
                height: utilsViewSize.height + d.height,
                width: utilsViewSize.width + d.width,
              });
            }}
          >
            <div className="content-wrapper">
              <div className="panel-views">
                {view === PANELS.service && <ServicePanel />}
                {view === PANELS.locale && <LocalePanel />}
                {view === PANELS.sensory && <SensoryPanel />}
                {view === PANELS.logging && <LoggingPanel />}
                {view === PANELS.linkGenerator && <RemoteAccessPanel />}
              </div>

              <div className="sidebar">
                <ToggleButtonGroup exclusive orientation="vertical" value={view} onChange={handleChange}>
                  <ToggleButton aria-label="panels" value={PANELS.closed}>
                    <BuildIcon sx={{ color: '#eee' }} />
                  </ToggleButton>

                  <StyledTooltip placement="left" title="Service">
                    <ToggleButton
                      aria-label={PANELS.service}
                      value={PANELS.service}
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover, &:hover': { background: '#0068ff' },
                        transitionDuration: '250ms',
                      }}
                    >
                      <MiscellaneousServicesIcon sx={{ color: '#eee' }} />
                    </ToggleButton>
                  </StyledTooltip>

                  <StyledTooltip placement="left" title="Localization">
                    <ToggleButton
                      aria-label={PANELS.locale}
                      value={PANELS.locale}
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover, &:hover': { background: '#F97300' },
                        transitionDuration: '250ms',
                      }}
                    >
                      <SpellcheckIcon sx={{ color: themeDevTools.color.white }} />
                    </ToggleButton>
                  </StyledTooltip>

                  <StyledTooltip placement="left" title="Sensory">
                    <ToggleButton
                      aria-label={PANELS.sensory}
                      value={PANELS.sensory}
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover, &:hover': { background: '#4E9F3D' },
                        transitionDuration: '250ms',
                      }}
                    >
                      <ScienceIcon sx={{ color: '#eee' }} />
                    </ToggleButton>
                  </StyledTooltip>

                  <StyledTooltip placement="left" title="Logging">
                    <ToggleButton
                      aria-label={PANELS.logging}
                      value={PANELS.logging}
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover, &:hover': {
                          background: '#FF204E',
                          borderRadius: 0,
                        },
                        transitionDuration: '250ms',
                      }}
                    >
                      <PestControlIcon sx={{ color: '#eee' }} />
                    </ToggleButton>
                  </StyledTooltip>

                  <StyledTooltip placement="left" title="Link Generator">
                    <ToggleButton
                      aria-label={PANELS.linkGenerator}
                      value={PANELS.linkGenerator}
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover, &:hover': {
                          background: '#9A0F98',
                          borderRadius: 0,
                        },
                        transitionDuration: '250ms',
                      }}
                    >
                      <InsertLinkIcon sx={{ color: '#eee' }} />
                    </ToggleButton>
                  </StyledTooltip>
                </ToggleButtonGroup>
              </div>
            </div>
          </Resizable>
        </div>
      </div>
    </>
  );
};
