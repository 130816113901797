import { useSelector } from 'react-redux';

import {
  selectFacebookPixelState,
  selectHotjarState,
  selectIsLoggingEnabled,
  selectLogHistory,
  selectMixPanelDidIdentify,
  selectMixPanelState,
} from '@lib/tools/dat/selectors';

export type ILogging = {
  hotjarState: boolean;
  facebookPixelState: boolean;
  isLoggingEnabled: boolean;
  logHistory: Record<string, string | Record<string, string>>[];
  mixPanelState: boolean;
  mixPanelDidIdentify: boolean;
};

export const useLogging = (): ILogging => {
  return {
    facebookPixelState: useSelector(selectFacebookPixelState),

    hotjarState: useSelector(selectHotjarState),
    isLoggingEnabled: useSelector(selectIsLoggingEnabled),
    logHistory: useSelector(selectLogHistory),
    mixPanelDidIdentify: useSelector(selectMixPanelDidIdentify),
    mixPanelState: useSelector(selectMixPanelState),
  };
};
