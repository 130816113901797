import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

function NewNode({ charactersArray, getNodeInfo, getFormCloseInfo }) {
  const [character, setCharacter] = useState('');
  const [score, setScore] = useState<number | null>(null);
  const [nodeText, setText] = useState('');
  const [nodeID, setNodeID] = useState('');
  const [charactersView, setCharactersView] = useState([]);
  const [scoresList, setScoresList] = useState({});

  const prepareSelectVariables = () => {
    const view: any = [];
    charactersArray.forEach((char: any) => {
      view.push(
        <MenuItem key={char} value={char}>
          {char}
        </MenuItem>,
      );
    });
    setCharactersView(view);
  };

  useEffect(() => {
    prepareSelectVariables();
  }, []);

  const handleTextSubmit = () => {
    if (nodeID && nodeText) {
      getNodeInfo(nodeID, nodeText, scoresList);
      getFormCloseInfo();
      setCharacter('');
      setNodeID('');
      setScoresList({});
      setText('');
      setScore(null);
    }
  };

  const handleCharAndScoreChange = () => {
    const charIndex = charactersArray.indexOf(character);
    setScoresList({ ...scoresList, [charIndex]: score });
  };

  return (
    <>
      <div style={{ color: 'black', height: 20, margin: 10 }}> CREATE NEW NODE</div>
      <Stack spacing={0.6}>
        <FormControl sx={{ minWidth: 225 }}>
          {!nodeID && (
            <InputLabel id="character-name-label" size="small">
              Question/ Answer ID
            </InputLabel>
          )}

          <TextField
            id="text"
            size="small"
            value={nodeID}
            onChange={e => {
              setNodeID(e.target.value);
            }}
          />
        </FormControl>
        {nodeID && nodeID.startsWith('QA') && (
          <Stack spacing={3}>
            <Stack direction="row" spacing={0.6}>
              <FormControl sx={{ width: 120 }}>
                <InputLabel id="character-name-label" size="small">
                  Characters
                </InputLabel>
                <Select
                  id="select-character-category"
                  label="Characters"
                  size="small"
                  value={character}
                  onChange={e => {
                    setCharacter(e.target.value);
                  }}
                >
                  {charactersView}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 100 }}>
                <InputLabel id="score-label" shrink={score !== null && score !== undefined} size="small">
                  Score
                </InputLabel>
                <TextField
                  id="score"
                  size="small"
                  value={score}
                  onChange={e => {
                    if (!e.target.value) {
                      setScore(0);
                    } else {
                      setScore(parseFloat(e.target.value));
                    }
                  }}
                />
              </FormControl>
              <Button className="Button" variant="outlined" onClick={() => handleCharAndScoreChange()}>
                Upload
              </Button>
            </Stack>
          </Stack>
        )}

        <FormControl sx={{ minWidth: 225 }}>
          {!nodeText && (
            <InputLabel id="character-name-label" size="small">
              Text
            </InputLabel>
          )}

          <TextField
            id="text"
            size="small"
            value={nodeText}
            onChange={e => {
              setText(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              handleTextSubmit();
            }}
          >
            {' '}
            Save
          </Button>
        </FormControl>
      </Stack>
    </>
  );
}

export default NewNode;
