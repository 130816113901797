import Extras from '@lib/tools/dat/mixpanel/extras';
import { Tracker } from '@lib/tools/dat/mixpanel/tracker';

/**
 * Mixpanel tracker instance.
 *
 * - Use the methods under `events` to track user interactions.
 * - Use the methods under `profile` to update information about the user.
 * These methods usually go in the `StoreListener`.
 *
 * @example
 * Example usage:
 * ```
 * import MixpanelTracker from '@lib/tools/dat/mixpanel';
 *
 * // Track click on "Enter" button in start page.
 * MixpanelTracker.events.startPageEnterClick();
 * ...
 * // StoreListener.tsx
 * MixpanelTracker.profile.upddateUserCharacters(characters);
 * ```
 */
const MixpanelTracker = new Tracker();

/** Contains utility methods that are fundamental for the correct functioning of event tracking. */
export const MixpanelExtras = new Extras();

export { initMixpanel } from '@lib/tools/dat/mixpanel/tracker';
export { useDataChangedEffect } from '@lib/tools/dat/mixpanel/hooks';
export default MixpanelTracker;
