import { RootState } from '@lib/core/service/types/appStateType';

export const selectServiceInstanceProductCategory = (state: RootState, defaultValue = ''): string =>
  state?.service?.serviceInstance?.serviceInstanceProductCategory || defaultValue;

export const selectServiceInstanceRetailerLocationId = (state: RootState, defaultValue = ''): string =>
  state?.service?.serviceInstance?.serviceInstanceRetailerLocationId || defaultValue;

export const selectServiceInstanceRetailerSlug = (state: RootState, defaultValue = ''): string =>
  state?.service?.serviceInstance?.serviceInstanceRetailerSlug || defaultValue;

export const selectServiceInstanceRetailerLocationSlug = (state: RootState, defaultValue = ''): string =>
  state?.service?.serviceInstance?.serviceInstanceRetailerLocationSlug || defaultValue;

export const selectIsServiceInstanceFeatureEnabled = (state: RootState, defaultValue = false): boolean =>
  (!!selectServiceInstanceProductCategory(state) &&
    !!selectServiceInstanceRetailerLocationId(state) &&
    !!selectServiceInstanceRetailerSlug(state)) ||
  defaultValue;

export const selectIsServiceInstanceRetailerDataLoading = (state: RootState, defaultValue = false): boolean =>
  state?.service?.serviceInstance?.retailer?.isLoading || defaultValue;

export const selectServiceInstanceRetailerLogo = (state: RootState, defaultValue = ''): string =>
  state?.service?.serviceInstance?.retailer?.data?.tags?.headerLogo || defaultValue;
