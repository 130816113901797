import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';
import { IFeedbackData } from '@lib/core/users/slices/feedbacks';

const selectFeedbackState = (state: RootState) => state?.feedback;

const selectFeedbackData = createSelector([selectFeedbackState], (feedback): IFeedbackData[] => feedback?.data || []);

const selectIsFeedbackLoading = createSelector(
  [selectFeedbackState],
  (feedback): boolean => feedback?.isLoading || false,
);

const selectLastUpdatedFeedbackId = createSelector(
  [selectFeedbackState],
  (feedback): string => feedback.lastUpdatedFeedbackId || '',
);

export { selectFeedbackData, selectLastUpdatedFeedbackId, selectIsFeedbackLoading };
