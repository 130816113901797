import { createSlice } from '@reduxjs/toolkit';

import { TProductInstance } from '@lib/core/products/types';
import { getProductsListData } from '@lib/core/products/utils';
import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { selectRetailerLocationSlug } from '@lib/core/retailers/selectors/retailerLocation';
import {
  B2C_SEARCH_TYPE_ARTICLES,
  B2C_SEARCH_TYPE_EXPERIENCES,
  B2C_SEARCH_TYPE_PRODUCTS,
  GPRL_CHARACTER_QUERY,
} from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import request from '@lib/core/service/requests/request';
import { createBackendRetailerLocationSlug, createBackendRetailerUrl } from '@lib/core/service/utils';

export type IResultProps = {
  results: TProductInstance[];
  searchCount: number | null;
};

export type ISearchInitialStateProps = {
  isLoadingGlobalSearch: boolean;
  searchQuery: string;
  searchResults: {
    products: IResultProps;
    experiences: IResultProps;
    articles: IResultProps;
  };
  suggestionProductsCount: Record<string, number>;
};

export const initialState: ISearchInitialStateProps = {
  isLoadingGlobalSearch: false,
  searchQuery: '',
  searchResults: {
    [B2C_SEARCH_TYPE_ARTICLES]: {
      results: [],
      searchCount: 0,
    },
    [B2C_SEARCH_TYPE_EXPERIENCES]: {
      results: [],
      searchCount: 0,
    },
    [B2C_SEARCH_TYPE_PRODUCTS]: {
      results: [],
      searchCount: 0,
    },
  },
  suggestionProductsCount: {},
};

export const fetchSuggestionProductsCount = createTypedAsyncThunk(
  'b2c/fetchSuggestionProductsCount',
  async ({ userCharactersIds }: { userCharactersIds: string[] }) => {
    const productsPromises = userCharactersIds.map(characterId =>
      getProductsListData({ [GPRL_CHARACTER_QUERY]: characterId }),
    );
    const data = await Promise.all(productsPromises);

    return data.reduce((acc, products) => {
      acc[products.results[0].product.category] = products.count;
      return acc;
    }, {});
  },
);

export const fetchGlobalSearchResult = createTypedAsyncThunk(
  'b2c/fetchGlobalSearchResult',
  async ({ params }: Record<string, any>, { getState }) => {
    const retailerSlugFromRedux = selectRetailerSlug(getState());
    const retailerLocationSlugFromRedux = selectRetailerLocationSlug(getState());

    const requestUrl = createBackendRetailerLocationSlug(
      createBackendRetailerUrl(backendApiUrls.apiUrlGlobalSearch, retailerSlugFromRedux),
      retailerLocationSlugFromRedux,
    );
    const json = await request(requestUrl, {
      params,
    });

    /**
     * @todo Currently as we have results for only products
     * @todoRefactor here to differentiate different type of results for 'products', 'experiences', 'articles'
     */
    return {
      products: {
        results: json.results,
        searchCount: json.count,
      },
    };
  },
);

export const globalSearchSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(fetchSuggestionProductsCount.pending, state => {
      state.isLoadingGlobalSearch = true;
    });
    builder.addCase(fetchGlobalSearchResult.pending, state => {
      state.isLoadingGlobalSearch = true;
      state.searchResults = initialState.searchResults;
    });
    builder.addCase(fetchSuggestionProductsCount.fulfilled, (state, { payload }) => {
      state.suggestionProductsCount = payload;
      state.isLoadingGlobalSearch = false;
    });
    builder.addCase(fetchGlobalSearchResult.fulfilled, (state, action) => {
      const { payload: { products } = {}, meta: { arg: { params: { query = '' } = {} } = {} } = {} } = action;
      state.searchResults = {
        ...state.searchResults,
        products,
      };
      state.isLoadingGlobalSearch = false;
      state.searchQuery = query;
    });
    builder.addCase(fetchGlobalSearchResult.rejected, state => {
      state.isLoadingGlobalSearch = false;
    });
  },
  initialState,
  name: 'globalSearch',
  reducers: {},
});

export default globalSearchSlice.reducer;
