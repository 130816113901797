import { createSlice } from '@reduxjs/toolkit';

import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import { IToolkitRejectedAction } from '@lib/core/service/types/common';

const initialState = {
  data: {},
  error: '',
  isLoading: false,
};
export const arrangeDeletedEdges = createTypedAsyncThunk(
  'changedNodes/arrangeDeletedNodes/delete',
  async (editedNodes: { alreadyInChangedNodes: object; notInChangedNodes: object }, { getState }) => {
    const changedNodes = JSON.parse(JSON.stringify(getState().changedNodes.data));
    const { alreadyInChangedNodes, notInChangedNodes } = editedNodes;
    let newNode;
    let selectedProductCategory;
    Object.keys(notInChangedNodes).forEach(key => {
      selectedProductCategory = notInChangedNodes[key].selectedProductCategory;
      if (key.startsWith('QA')) {
        newNode = {
          nodeID: key,
          parentID: notInChangedNodes[key].parentID,
          removedChildren: notInChangedNodes[key].removedChildren,
          scoresArray: notInChangedNodes[key].initializedArray,
          text: notInChangedNodes[key].text,
        };
      } else {
        newNode = {
          nodeID: key,
          removedChildren: notInChangedNodes[key].removedChildren,
          text: notInChangedNodes[key].text,
        };
      }
      if (!changedNodes[selectedProductCategory]) {
        changedNodes[selectedProductCategory] = {};
      }
      if (!changedNodes[selectedProductCategory][key]) {
        changedNodes[selectedProductCategory][key] = newNode;
      }
    });
    Object.keys(alreadyInChangedNodes).forEach(key => {
      selectedProductCategory = alreadyInChangedNodes[key].selectedProductCategory;
      alreadyInChangedNodes[key].removedChildren.forEach(removedChild => {
        changedNodes[selectedProductCategory][key].removedChildren.push(removedChild);
      });
    });
    return { changedNodes, selectedProductCategory };
  },
);

export const addNewNodeToMatrix = createTypedAsyncThunk(
  'changedNodes/addNewNodeToMatrix/post',
  async (
    {
      selectedProductCategory,
      id,
      initializedArray,
      text,
      removedChildren,
      addedChildren,
      parentID,
      isNewNode,
    }: {
      selectedProductCategory: string;
      id: string;
      initializedArray?: Array<number>;
      text: string;
      removedChildren?: Array<string>;
      addedChildren?: Array<string>;
      parentID?: string;
      isNewNode?: boolean;
    },
    { getState },
  ) => {
    const changedNodes = JSON.parse(JSON.stringify(getState().changedNodes.data));

    if (!changedNodes[selectedProductCategory]) {
      changedNodes[selectedProductCategory] = {};
    }
    if (!changedNodes[selectedProductCategory][id]) {
      changedNodes[selectedProductCategory][id] = {};
    }
    let newNode;
    // means that it's an answer node
    if (initializedArray) {
      newNode = {
        nodeID: id,
        scoresArray: initializedArray,
        text,
      };
      if (parentID) {
        newNode.parentID = parentID;
      }
      if (!parentID) {
        newNode.parentID = null;
      }
      if (removedChildren) {
        newNode.removedChildren = [removedChildren];
      }
      if (addedChildren) {
        newNode.addedChildren = [addedChildren];
      }
      if (parentID) {
        newNode.parentID = parentID;
      }
      if (isNewNode) {
        newNode.isNewNode = true;
      }
    }

    // means that it's an answer node
    else if (!initializedArray) {
      newNode = {
        nodeID: id,
        text,
      };
      if (removedChildren) {
        newNode.removedChildren = [removedChildren];
      }
      if (addedChildren) {
        newNode.addedChildren = [addedChildren];
      }
    }
    changedNodes[selectedProductCategory][id] = newNode;
    return { changedNodes, selectedProductCategory };
  },
);
export const changeScoreForAlreadyInPath = createTypedAsyncThunk(
  'changedNodes/changeScoreForAlreadyInPath/post',
  async (
    {
      selectedProductCategory,
      parentId,
      nodeId,
      scoreArray,
      newText,
    }: {
      selectedProductCategory: string;
      parentId: string;
      nodeId: string;
      scoreArray: Array<number>;
      newText?: string;
    },
    { getState },
  ) => {
    const changedNodes = JSON.parse(JSON.stringify(getState().changedNodes.data));
    if (!changedNodes[selectedProductCategory]) {
      changedNodes[selectedProductCategory] = {};
    }
    if (!changedNodes[selectedProductCategory][nodeId]) {
      changedNodes[selectedProductCategory][nodeId] = {};
    }
    const newNode = {
      nodeID: nodeId,
      parentID: parentId,
      scoresArray: scoreArray,
      text: newText,
    };
    changedNodes[selectedProductCategory][nodeId] = newNode;
    return { changedNodes, selectedProductCategory };
  },
);
export const changeScoreForAlreadyInState = createTypedAsyncThunk(
  'changedNodes/changeScoreForAlreadyInState/put',
  async (
    {
      selectedProductCategory,
      scoreArray,
      nodeId,
      newText,
    }: {
      selectedProductCategory: string;
      scoreArray: Array<number>;
      nodeId: string;
      newText?: string;
    },
    { getState },
  ) => {
    const changedNodes = JSON.parse(JSON.stringify(getState().changedNodes.data));
    changedNodes[selectedProductCategory][nodeId].scoresArray = scoreArray;
    if (newText) {
      changedNodes[selectedProductCategory][nodeId].text = newText;
    }
    return { changedNodes, selectedProductCategory };
  },
);

export const updateNodeState = createTypedAsyncThunk(
  'changedNodes/updateNodeState/put',
  async (
    {
      id,
      selectedProductCategory,
      removedChildren,
      addedChildren,
    }: {
      id: string;
      selectedProductCategory: string;
      removedChildren?: Array<string>;
      addedChildren?: Array<string>;
    },
    { getState },
  ) => {
    const changedNodes = JSON.parse(JSON.stringify(getState().changedNodes.data));
    if (removedChildren) {
      if (changedNodes[selectedProductCategory][id].removedChildren)
        changedNodes[selectedProductCategory][id].removedChildren.push(removedChildren);
      else changedNodes[selectedProductCategory][id].removedChildren = [removedChildren];
    }
    if (addedChildren) {
      if (changedNodes[selectedProductCategory][id].addedChildren)
        changedNodes[selectedProductCategory][id].addedChildren.push(addedChildren);
      else changedNodes[selectedProductCategory][id].addedChildren = [addedChildren];
    }
    return { changedNodes, selectedProductCategory };
  },
);

export const updateNodeToRemoved = createTypedAsyncThunk(
  'changedNodes/updateNodeToRemoved/put',
  async (
    {
      id,
      selectedProductCategory,
    }: {
      id: string;
      selectedProductCategory: string;
    },
    { getState },
  ) => {
    const changedNodes = JSON.parse(JSON.stringify(getState().changedNodes.data));
    changedNodes[selectedProductCategory][id].isRemovedNode = true;
    return { changedNodes, selectedProductCategory };
  },
);

export const updateNodeToRemovedNotInState = createTypedAsyncThunk(
  'changedNodes/updateNodeToRemovedNotInState/post',
  async (
    {
      id,
      selectedProductCategory,
      scoreArray,
      text,
    }: {
      id: string;
      selectedProductCategory: string;
      scoreArray: Array<number>;
      text: string;
    },
    { getState },
  ) => {
    const changedNodes = JSON.parse(JSON.stringify(getState().changedNodes.data));
    let newNode = {};
    if (scoreArray) {
      newNode = {
        isRemovedNode: true,
        nodeID: id,
        scoresArray: scoreArray,
        text,
      };
    } else {
      newNode = {
        isRemovedNode: true,
        nodeID: id,
        text,
      };
    }
    if (!changedNodes[selectedProductCategory]) {
      changedNodes[selectedProductCategory] = { [id]: newNode };
    } else if (!changedNodes[selectedProductCategory][id]) {
      changedNodes[selectedProductCategory][id] = newNode;
    }
    return { changedNodes, selectedProductCategory };
  },
);

const handleFulfilledAction = (state, action) => {
  const { changedNodes, selectedProductCategory } = action.payload;

  state.isLoading = false;
  state.data[selectedProductCategory] = changedNodes[selectedProductCategory];
};

const handleRejectedAction = (state, action: IToolkitRejectedAction) => {
  const { payload: { errors: { detail } = {} } = {} } = action;
  state.error = detail;
};

const handlePendingAction = state => {
  state.error = '';
  state.isLoading = true;
};

export const changedNodesSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(arrangeDeletedEdges.fulfilled, handleFulfilledAction);
    builder.addCase(arrangeDeletedEdges.rejected, handleRejectedAction);
    builder.addCase(arrangeDeletedEdges.pending, handlePendingAction);
    builder.addCase(addNewNodeToMatrix.fulfilled, handleFulfilledAction);
    builder.addCase(addNewNodeToMatrix.rejected, handleRejectedAction);
    builder.addCase(addNewNodeToMatrix.pending, handlePendingAction);
    builder.addCase(changeScoreForAlreadyInPath.fulfilled, handleFulfilledAction);
    builder.addCase(changeScoreForAlreadyInPath.rejected, handleRejectedAction);
    builder.addCase(changeScoreForAlreadyInPath.pending, handlePendingAction);
    builder.addCase(changeScoreForAlreadyInState.fulfilled, handleFulfilledAction);
    builder.addCase(changeScoreForAlreadyInState.rejected, handleRejectedAction);
    builder.addCase(changeScoreForAlreadyInState.pending, handlePendingAction);
    builder.addCase(updateNodeState.fulfilled, handleFulfilledAction);
    builder.addCase(updateNodeState.rejected, handleRejectedAction);
    builder.addCase(updateNodeState.pending, handlePendingAction);
    builder.addCase(updateNodeToRemoved.fulfilled, handleFulfilledAction);
    builder.addCase(updateNodeToRemoved.rejected, handleRejectedAction);
    builder.addCase(updateNodeToRemoved.pending, handlePendingAction);
    builder.addCase(updateNodeToRemovedNotInState.fulfilled, handleFulfilledAction);
    builder.addCase(updateNodeToRemovedNotInState.rejected, handleRejectedAction);
    builder.addCase(updateNodeToRemovedNotInState.pending, handlePendingAction);
  },
  initialState,
  name: 'changedNodes',
  reducers: {},
});

export default changedNodesSlice.reducer;
