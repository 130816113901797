import {
  // Select,
  // Stack,
  // InputLabel,
  // FormControl,
  // MenuItem,
  // TextField,
  // Button,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableCell,
  Input,
  Button,
} from '@mui/material';
import { useState } from 'react';

function NodeInfoForm({ selectedNode, charactersArray, scoresArray, updateNode, closeFormUpdate, prevText }) {
  const [score, setScore] = useState('');
  const [text, setText] = useState('');
  // const [charactersView, setCharactersView] = useState([]);
  const [editingCell, setEditingCell] = useState<{ rowIndex: number; columnId: number } | null>(null);
  const [isTyping, setIsTyping] = useState(false);
  const [changedScores, setChangedScores] = useState({});
  const [rowArray, setRowArray] = useState([prevText, ...scoresArray]);

  const handleCellClick = (rowIndex: number, columnId: number) => {
    setEditingCell({ columnId, rowIndex });
  };

  let headerArray;
  // when onBlur is called this one is called

  const handleSubmit = (columnIndex: any) => {
    // changed one is text
    if (columnIndex === 0) {
      const copyRowArray = [...rowArray];
      copyRowArray[0] = text;
      setRowArray(copyRowArray);
    }
    // if changed one is score
    else {
      const copyRowArray = [...rowArray];
      copyRowArray[columnIndex] = score;
      setRowArray(copyRowArray);
      const copyChangedScores = JSON.parse(JSON.stringify(changedScores));
      copyChangedScores[headerArray[columnIndex]] = parseFloat(score);
      setChangedScores(copyChangedScores);
    }
    setIsTyping(false);
  };

  const handleAllSave = () => {
    updateNode(selectedNode, changedScores, text);
    setScore('');
    setText('');
    closeFormUpdate();
  };

  const prepareHeader = () => {
    headerArray = ['Text', ...charactersArray];
  };

  prepareHeader();
  try {
    return (
      <>
        <Paper elevation={2}>
          <Table stickyHeader aria-label="score-matrix-table" style={{ padding: '8px' }}>
            <TableHead>
              <TableRow>
                {headerArray.map((column, i) => (
                  <TableCell key={i} align="right" style={{ fontSize: '12px', padding: '8px' }}>
                    {i === 0 ? 'Text' : column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={0} hover tabIndex={-1}>
                {rowArray &&
                  rowArray.map(columnIndex => {
                    const isEditing = editingCell && editingCell.rowIndex === 0 && editingCell.columnId === columnIndex;
                    const value = columnIndex ? score : text;
                    return (
                      <TableCell
                        key={columnIndex}
                        align="right"
                        style={{ fontSize: '12px', padding: '8px' }}
                        onClick={() => handleCellClick(0, columnIndex)}
                      >
                        {isEditing ? (
                          <Input
                            value={isEditing && isTyping ? value : rowArray[columnIndex]}
                            onBlur={() => {
                              handleSubmit(columnIndex);
                            }}
                            onChange={e => {
                              setIsTyping(true);
                              if (columnIndex === 0) {
                                if (!e.target.value) {
                                  setText('');
                                } else {
                                  setText(e.target.value);
                                }
                              } else if (!e.target.value) {
                                setScore('');
                              } else {
                                setScore(e.target.value);
                              }
                            }}
                          />
                        ) : (
                          rowArray[columnIndex]
                        )}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <div>
          <Button
            className="Button"
            size="small"
            style={{ margin: '5px', position: 'absolute', right: 0 }}
            onClick={() => handleAllSave()}
          >
            {' '}
            Save
          </Button>
        </div>
      </>
    );
  } catch (e) {
    console.error(e);
  }
}

export default NodeInfoForm;
