import arrowIcon from '@app/pmi/src/assets/media/V2/v2_arrow_slate.png';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

function TereaBanner() {
  const imgBackground =
    'https://media-staging.taste-advisor-vh.com/media/product_attributes/v2backgrounds/v2_terea_quiz_background.png';

  return (
    <div className="ta-v2-terea-banner" style={{ backgroundImage: `url(${imgBackground})` }}>
      <div className="ta-v2-terea-banner-content">
        <div className="ta-v2-terea-banner-content-text font-ta-v2-title-6">
          <LocaleFragment message={localeV2.result.tereaBannerText} />
        </div>
        <div className="ta-v2-terea-banner-content-icon" style={{ backgroundImage: `url(${arrowIcon})` }} />
      </div>
    </div>
  );
}

export default TereaBanner;
