import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { TLogHistoryItem } from '@lib/tools/dat/types';

export interface ILoggingState {
  hotjarState: boolean;
  facebookPixelState: boolean;
  isLoggingEnabled: boolean;
  logHistory: TLogHistoryItem[];
  mixPanelState: boolean;
  mixPanelDidIdentify: boolean;
}

export const initialState: ILoggingState = {
  facebookPixelState: false,
  hotjarState: false,
  isLoggingEnabled: false,
  logHistory: [],
  mixPanelDidIdentify: false,
  mixPanelState: false,
};

export const trackingSlice = createSlice({
  initialState,
  name: 'tracking',
  reducers: {
    actionEnableLogging: state => {
      state.isLoggingEnabled = true;
    },
    actionFacebookPixelStarting: state => {
      state.facebookPixelState = true;
    },
    actionFacebookPixelStopping: state => {
      state.facebookPixelState = initialState.facebookPixelState;
    },
    actionHotjarStarting: state => {
      state.hotjarState = true;
    },
    actionHotjarStopping: state => {
      state.hotjarState = initialState.hotjarState;
    },
    actionMixPanelDidIdentify: state => {
      state.mixPanelDidIdentify = true;
    },
    actionMixPanelDidNotIdentify: state => {
      state.mixPanelDidIdentify = initialState.mixPanelDidIdentify;
    },
    actionMixPanelStarting: state => {
      state.mixPanelState = true;
    },
    actionMixPanelStopping: state => {
      state.mixPanelState = initialState.mixPanelState;
    },
    actionUpdateTrackingLogs: (state, action: PayloadAction<{ log: TLogHistoryItem }>) => {
      // Push logs to Utils only if logging is enabled with the `?tools=<token>` URL parameter. // ! Token
      if (state.isLoggingEnabled) {
        const { log } = action.payload;
        delete log?.args?.token; // Do not display the token.
        state.logHistory.push(log);
      }
    },
  },
});

export default trackingSlice.reducer;
export const {
  actionEnableLogging,
  actionHotjarStopping,
  actionHotjarStarting,
  actionFacebookPixelStarting,
  actionFacebookPixelStopping,
  actionMixPanelStarting,
  actionMixPanelStopping,
  actionMixPanelDidIdentify,
  actionMixPanelDidNotIdentify,
  actionUpdateTrackingLogs,
} = trackingSlice.actions;
