import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';
import { IProductListState } from '@lib/core/users/slices/productList';
import { IProductList } from '@lib/core/users/types';
import { PRODUCT_LIST_WISHLIST } from '@lib/tools/shared/helpers/consts';

const selectProductList = (state: RootState) => state.user.productList;

export const selectProductListLoading = createSelector(
  [selectProductList],
  (productListState): boolean => productListState.isLoading || false,
);

export const selectProductListWishlistData = createSelector(
  [selectProductList],
  (productListState: IProductListState): IProductList[] =>
    productListState.data.filter(list => list[0]?.list_name === PRODUCT_LIST_WISHLIST).flat() || [],
);

export const selectProductListWishlistProductIds = createSelector(
  [selectProductListWishlistData],
  (listData): string[] => listData.map(data => data?.product?.product?.identifier) || [],
);
