/* eslint-disable class-methods-use-this */
import mixpanel from 'mixpanel-browser';

import { selectRetailerId, selectRetailerName, selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import {
  selectRetailerDesignSet,
  selectRetailerLocationId,
  selectRetailerLocationProductCategories,
  selectRetailerLocationSlug,
} from '@lib/core/retailers/selectors/retailerLocation';
import { ENV_IS_BUILD, isApplicationKiosk } from '@lib/core/service/consts';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import { AppStateType } from '@lib/core/service/types/appStateType';
import { TUserGroup } from '@lib/core/users/types';
import { checkIfMixpanelExists, logEventForQA } from '@lib/tools/dat/mixpanel/decorators';
import Utilities from '@lib/tools/dat/mixpanel/utils';
import { PRODUCT_CATEGORIES_ORDERED } from '@lib/tools/shared/helpers/consts';

@checkIfMixpanelExists()
export default class Extras {
  private utils: Utilities;

  constructor() {
    this.utils = new Utilities();
  }

  /**
   * Disable or enable Mixpanel tracking in production based on User IP,
   * DevTools state or whether the user belongs to the QA group.
   *
   * @param disableTracking - Whether to disable tracking or not
   * @param userGroups - Which django groups the user belongs to
   */
  public switchTrackingOnOff(disableTracking: boolean, userGroups: TUserGroup[]) {
    const groups = userGroups?.map(v => v.name) || [];
    const isQA = groups.includes('QA');

    if (!ENV_IS_BUILD) {
      mixpanel.opt_in_tracking({ track: null });
      return;
    }

    if (disableTracking || isQA) {
      mixpanel.opt_out_tracking();
      if (this.utils.didIdentify) mixpanel.people.delete_user(); // NOTE: delete profile or not?
      return;
    }

    mixpanel.opt_in_tracking({ track: null });
  }

  /**
   * Set necessary super properties for tracking *(mostly retailer info)*.
   *
   * If on kiosk, also reset mixpanel instance.
   */
  public initialize() {
    const state: AppStateType = store.getState();
    const retailerLocationProductCategories = PRODUCT_CATEGORIES_ORDERED.includes(selectServiceProductCategory(state))
      ? [selectServiceProductCategory(state)]
      : selectRetailerLocationProductCategories(state);
    const retailerLocationSlug = selectRetailerLocationSlug(state);
    const designSet = selectRetailerDesignSet(state);
    const retailerSlug = selectRetailerSlug(state);
    const retailerId = selectRetailerId(state);
    const retailerName = selectRetailerName(state);
    const retailerLocationID = selectRetailerLocationId(state);

    const enabledProfiling = false;

    const args = {
      designSet,
      enabledProfiling,
      retailerId,
      retailerLocationID,
      retailerLocationProductCategories,
      retailerLocationSlug,
      retailerName,
      retailerSlug,
    };

    // Each time the kiosk (re)loads, the mixpanel instance starts fresh.
    if (isApplicationKiosk) this.utils.reset();

    this.utils.setSuperProperties(args, true);
  }

  /** Reset Mixpanel state on physical kiosk timeout. */
  @logEventForQA()
  public handleKioskTimeout() {
    this.initialize();
  }
}
