import cn from 'classnames';
import Slider, { Handle } from 'rc-slider';
import React, { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { useQuizView } from '@lib/core/quizzes/hooks';
import { useApp } from '@lib/core/service/hooks';

interface Props {
  classname?: string;
  answers: Record<
    string,
    {
      text: string;
      image?: string;
    }
  >[];
  setCheckIfAnswerSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSliderSelection: (answerId: string) => void;
  sliderText: string | JSX.Element;
  currentPrimaryColor: string;
}

const AnswerSlider: FC<Props> = ({
  answers,
  classname,
  setCheckIfAnswerSelected,
  handleSliderSelection,
  sliderText,
  currentPrimaryColor,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(2);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);

  const { isLayoutRightToLeft } = useApp();

  const { quizViewQuestionId } = useQuizView();

  const HandleWrapper = props => {
    const { dragging, ...restProps } = props;

    return (
      <Handle
        dragging={dragging.toString()}
        {...restProps}
        className={cn('rc-slider-handle', {
          'rtl-orientation': isLayoutRightToLeft,
        })}
        onClick={() => {
          setIsAnswerSelected(true);
        }}
      >
        {isAnswerSelected ? (
          <svg
            className="rc-slider-handle-image"
            fill="none"
            height="40"
            viewBox="0 0 40 40"
            width="40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect fill={currentPrimaryColor} height="38" rx="19" width="38" x="1" y="1" />
            <circle cx="20" cy="20" fill={currentPrimaryColor} r="17" stroke="#34303D" strokeWidth="2" />
            <rect fill="#34303D" height="18" rx="1" width="2" x="17" y="11" />
            <rect fill="#34303D" height="18" rx="1" width="2" x="21" y="11" />
            <rect height="38" rx="19" stroke="#F6F4F0" strokeWidth="2" width="38" x="1" y="1" />
          </svg>
        ) : (
          <svg
            className="rc-slider-handle-image"
            fill="none"
            height="40"
            viewBox="0 0 40 40"
            width="40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect fill="#F6F4F0" height="38" rx="19" width="38" x="1" y="1" />
            <circle cx="20" cy="20" fill="#F6F4F0" r="17" stroke="#34303D" strokeWidth="2" />
            <rect fill={currentPrimaryColor} height="18" rx="1" width="2" x="17" y="11" />
            <rect fill={currentPrimaryColor} height="18" rx="1" width="2" x="21" y="11" />
            <rect height="38" rx="19" stroke="#F6F4F0" strokeWidth="2" width="38" x="1" y="1" />
          </svg>
        )}
      </Handle>
    );
  };

  const checkSelectedAnswer = useCallback(() => {
    if (isAnswerSelected) {
      setCheckIfAnswerSelected(true);
    } else {
      setCheckIfAnswerSelected(false);
    }
  }, [isAnswerSelected]);

  useEffect(() => {
    if (quizViewQuestionId) {
      setIsAnswerSelected(false);
      setSelectedAnswer(2);
    }
  }, [quizViewQuestionId]);

  useEffect(() => {
    handleSliderSelection(Object.keys(answers[selectedAnswer])?.[0]);
  }, [answers, selectedAnswer]);

  useLayoutEffect(() => {
    checkSelectedAnswer();
  }, [checkSelectedAnswer]);

  return (
    <div className={`ta-v2-qds8003-answer-slider-block ${classname}`}>
      <div className="ta-v2-qds8003-answer-slider-header">
        {isAnswerSelected ? (
          <div className="font-ta-v2-custom-large-paragraph-bold ta-v2-qds8003-answer-slider-header-text">
            {answers[selectedAnswer][Object.keys(answers[selectedAnswer])[0]]?.text}
          </div>
        ) : (
          <div className="ta-v2-qds8003-answer-slider-header-loading" />
        )}
      </div>
      <div className="ta-v2-qds8003-answer-slider-wrapper">
        <div className="ta-v2-qds8003-answer-slider-icons-block">
          <svg
            className="ta-v2-qds8003-answer-slider-icon ta-v2-qds8003-answer-slider-icon-click"
            fill="none"
            height="36"
            role="button"
            viewBox="0 0 36 36"
            width="36"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setSelectedAnswer(0);
              setIsAnswerSelected(true);
            }}
          >
            <circle
              cx="18"
              cy="18"
              fill={selectedAnswer > 0 && isAnswerSelected ? currentPrimaryColor : '#B8B7BB'}
              r="11"
              stroke="#F6F4F0"
              strokeWidth="2"
            />
            <path
              d="M22 19C22.5523 19 23 18.5523 23 18C23 17.4477 22.5523 17 22 17H14C13.4477 17 13 17.4477 13 18C13 18.5523 13.4477 19 14 19H22Z"
              fill="#34303D"
            />
          </svg>
          <svg
            className="ta-v2-qds8003-answer-slider-icon"
            fill="none"
            height="36"
            viewBox="0 0 36 36"
            width="36"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="18"
              cy="18"
              fill={selectedAnswer > 1 && isAnswerSelected ? currentPrimaryColor : '#B8B7BB'}
              r="13"
              stroke="#F6F4F0"
              strokeWidth="2"
            />
          </svg>
          <svg
            className="ta-v2-qds8003-answer-slider-icon"
            fill="none"
            height="36"
            viewBox="0 0 36 36"
            width="36"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="18"
              cy="18"
              fill={selectedAnswer > 2 && isAnswerSelected ? currentPrimaryColor : '#B8B7BB'}
              r="15"
              stroke="#F6F4F0"
              strokeWidth="2"
            />
          </svg>
          <svg
            className="ta-v2-qds8003-answer-slider-icon"
            fill="none"
            height="37"
            viewBox="0 0 37 37"
            width="37"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="18"
              cy="18"
              fill={selectedAnswer > 3 && isAnswerSelected ? currentPrimaryColor : '#B8B7BB'}
              r="17"
              stroke="#F6F4F0"
              strokeWidth="2"
            />
          </svg>
          <svg
            className="ta-v2-qds8003-answer-slider-icon-click"
            fill="none"
            height="40"
            role="button"
            viewBox="0 0 40 40"
            width="40"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              setSelectedAnswer(4);
              setIsAnswerSelected(true);
            }}
          >
            <circle
              cx="20"
              cy="20"
              fill={selectedAnswer > 4 && isAnswerSelected ? currentPrimaryColor : '#B8B7BB'}
              r="19"
              stroke="#F6F4F0"
              strokeWidth="2"
            />
            <path
              d="M25 21C25.5523 21 26 20.5523 26 20C26 19.4477 25.5523 19 25 19H15C14.4477 19 14 19.4477 14 20C14 20.5523 14.4477 21 15 21H25Z"
              fill="#34303D"
            />
            <path
              d="M19 25C19 25.5523 19.4477 26 20 26C20.5523 26 21 25.5523 21 25L21 15C21 14.4477 20.5523 14 20 14C19.4477 14 19 14.4477 19 15L19 25Z"
              fill="#34303D"
            />
          </svg>
        </div>
        <Slider
          className={`${isAnswerSelected ? 'rc-slider-answer-selected' : ''}`}
          defaultValue={2}
          handle={HandleWrapper}
          max={4}
          min={0}
          reverse={!!isLayoutRightToLeft}
          step={1}
          trackStyle={{ backgroundColor: currentPrimaryColor }}
          value={selectedAnswer}
          onAfterChange={value => {
            setSelectedAnswer(Math.round(value));
          }}
          onChange={value => {
            setIsAnswerSelected(true);
            setSelectedAnswer(value);
            if (!isAnswerSelected) {
              setIsAnswerSelected(true);
            }
          }}
        />
      </div>
      <div className={cn('font-ta-v2-global-body-small ta-v2-qds8003-answer-slider-static-text', { isAnswerSelected })}>
        {sliderText}
      </div>
    </div>
  );
};

export default AnswerSlider;
