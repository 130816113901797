import { useSelector } from 'react-redux';

import { selectUserQuizType } from '@lib/core/quizzes/selectors';
import { TUserQuizType } from '@lib/core/quizzes/types/userQuiz';

export const useUserQuizType = (): TUserQuizType => {
  return {
    ...useSelector(selectUserQuizType),
  };
};
