import { createSlice } from '@reduxjs/toolkit';

export interface IRangeFilterState {
  defaultMin: number;
  defaultMax: number;
  newMin: number;
  newMax: number;
}

interface ISetPriceRangeValuesPayload {
  payload: {
    defaultMin?: number;
    defaultMax?: number;
    newMin?: number;
    newMax?: number;
  };
}

const initialState: IRangeFilterState = {
  defaultMax: 0,
  defaultMin: 0,
  newMax: 0,
  newMin: 0,
};

const rangeFilterSlice = createSlice({
  initialState,
  name: 'rangeFilter',
  reducers: {
    resetPriceRange: state => {
      state.newMin = state.defaultMin;
      state.newMax = state.defaultMax;
    },
    setPriceRangeValues: (state, action: ISetPriceRangeValuesPayload) => {
      const { payload: { defaultMin, defaultMax, newMin, newMax } = {} } = action;
      state.defaultMin = defaultMin || state.defaultMin;
      state.defaultMax = defaultMax || state.defaultMax;
      state.newMin = Math.floor(newMin !== undefined ? newMin : state.newMin);
      state.newMax = Math.round(newMax || state.newMax);
    },
  },
});

export default rangeFilterSlice.reducer;
export const { resetPriceRange, setPriceRangeValues } = rangeFilterSlice.actions;
