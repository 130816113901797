import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { REQUEST_METHODS } from '@lib/core/service/consts';
import request from '@lib/core/service/requests/request';
import { IToolkitRejectedAction } from '@lib/core/service/types/common';
import { postUserFeedback } from '@lib/core/users/slices/urls';

interface IFeedbackSlice {
  isLoading: boolean;
  error: string;
}
const initialState: IFeedbackSlice = {
  error: '',
  isLoading: false,
};

export const actionPostProductFeedback = createAsyncThunk(
  'products/feedback/post',
  async ({
    session,
    metadata,
    email = null,
  }: {
    session: string;
    metadata: { feedback: { liked: boolean; product: string } };
    email?: string;
  }) => {
    const config = {
      method: REQUEST_METHODS.POST,
    };
    const body = {
      metadata,
      session,
    };
    return await request(`${postUserFeedback()}${email ? `${email}/` : ''}`, config, body);
  },
);

const productFeedbackSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionPostProductFeedback.pending, state => {
      state.error = '';
      state.isLoading = true;
    });
    builder.addCase(actionPostProductFeedback.fulfilled, state => {
      state.isLoading = false;
    });
    builder.addCase(actionPostProductFeedback.rejected, (state, action: IToolkitRejectedAction) => {
      const { payload: { errors: { detail } = {} } = {} } = action;
      state.isLoading = false;
      state.error = detail;
    });
  },
  initialState,
  name: 'productFeedbackSlice',
  reducers: {
    actionResetProductFeedback: () => initialState,
  },
});

export default productFeedbackSlice.reducer;
export const { actionResetProductFeedback } = productFeedbackSlice.actions;
