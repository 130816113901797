import { RootState } from '@lib/core/service/types/appStateType';

export const selectProductSetsIsFetching = (state: RootState, defaultValue = false): boolean =>
  state.productsRoot.productSets.isProductSetsLoading || defaultValue;

export const selectProductSetProducts = (state: RootState, defaultValue = []) =>
  state.productsRoot.productSets.productSetProducts || defaultValue;

export const selectProductSetsIds = (state: RootState, defaultValue = []) =>
  state.productsRoot.productSets.productSetsIds || defaultValue;

export const selectProductSetsImages = (state: RootState, defaultValue = []) =>
  state.productsRoot.productSets.productSetsImages || defaultValue;

export const selectProductSetsProductListIsFetching = (state: RootState, defaultValue = null) =>
  state.productsRoot.productSets.isProductSetProductsLoading || defaultValue;
