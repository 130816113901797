import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  selectRetailer3dPartyAgreement,
  selectRetailerAgreementsIndexedObj,
} from '@lib/core/retailers/selectors/retailer';
import {
  HARDCODED_AGREEMENT_ID_MARKETING,
  HARDCODED_AGREEMENT_ID_PROFILING,
  HARDCODED_AGREEMENT_ID_TECHNICAL_STUFF,
  HARDCODED_AGREEMENT_ID_THIRD_PARTY,
  REQUEST_METHODS,
} from '@lib/core/service/consts';
import request from '@lib/core/service/requests/request';
import { Agreements } from '@lib/core/service/slices/technical/types';
import { privacyLogApiUrlCreator } from '@lib/core/service/slices/technical/urls';
import { RootState } from '@lib/core/service/types/appStateType';
import { selectAuthToken } from '@lib/core/users/selectors/auth';

// Note: this will be slightly upgraded after confirm be approach
export interface IAgreementsTypes {
  [Agreements.isUserAllowedUseLocalStorage]?: boolean;
  [Agreements.isUserAllowedUseEmailForMarketing]?: boolean;
  [Agreements.isUserAllowedProfiling]?: boolean;
  [Agreements.isUserAllowedUseThirdParty]?: boolean;
  [Agreements.hasRegisteredUserNotFinishedOnboarding]?: boolean;
  isAgreementLoading?: boolean;
}

const initialState: IAgreementsTypes = {
  hasRegisteredUserNotFinishedOnboarding: false,
  isAgreementLoading: false,
  isUserAllowedProfiling: false,
  isUserAllowedUseEmailForMarketing: false,
  isUserAllowedUseLocalStorage: false,
  isUserAllowedUseThirdParty: false,
};

// Using [category] field we can create this list dynamically if BE will be updated
export const hardcodedListOfAgreementsIDs = {
  [HARDCODED_AGREEMENT_ID_MARKETING]: [Agreements.isUserAllowedUseEmailForMarketing],
  [HARDCODED_AGREEMENT_ID_PROFILING]: [Agreements.isUserAllowedProfiling],
  [HARDCODED_AGREEMENT_ID_TECHNICAL_STUFF]: [Agreements.isUserAllowedUseLocalStorage],
  [HARDCODED_AGREEMENT_ID_THIRD_PARTY]: [Agreements.isUserAllowedUseThirdParty],
  isUserAllowedProfiling: HARDCODED_AGREEMENT_ID_PROFILING,
  isUserAllowedUseEmailForMarketing: HARDCODED_AGREEMENT_ID_MARKETING,
  isUserAllowedUseLocalStorage: HARDCODED_AGREEMENT_ID_TECHNICAL_STUFF,
  isUserAllowedUseThirdParty: HARDCODED_AGREEMENT_ID_THIRD_PARTY,
};

// It can be changed to pure request-like thunk if we will move logic to one of HOCs.
// Also, this is just recap of all agreement-like things that was brutally encrypted around app
export const actionChangeAgreements = createAsyncThunk(
  'actionChangeAgreements',
  async (newAgreements: IAgreementsTypes, thunkAPI) => {
    const { getState } = thunkAPI;
    const state: RootState = getState() as RootState;

    // JSON > Array > Requests
    const responsePromises = Object.keys(newAgreements).map(agreementName => {
      const newValue = newAgreements[agreementName];

      const agreementHardcodedID =
        agreementName === Agreements.isUserAllowedUseThirdParty
          ? selectRetailer3dPartyAgreement(state)?.privacy_id
          : hardcodedListOfAgreementsIDs[agreementName];

      const thisAgreementDataForCurrentRetailer = selectRetailerAgreementsIndexedObj(state)[agreementHardcodedID];

      // Only if retailer has this agreement ID and user has token, we make request. If not - just update slice.
      // Maybe will move this in one of providers to achieve independent modules.
      if (!thisAgreementDataForCurrentRetailer || !selectAuthToken(getState())) {
        return { [agreementName]: newValue };
      }
      return request(
        privacyLogApiUrlCreator(),
        {
          method: REQUEST_METHODS.POST, // This is copied from legacy, but i didn't catch case when POST works
        },
        {
          privacy_id: agreementHardcodedID,
          selection: newValue,
        },
      ).then(() => ({ [agreementName]: newValue }));
    });

    // Requests > Array > JSON
    return await Promise.all(responsePromises).then(agreementsArray => Object.assign({}, ...agreementsArray));
  },
);

export const agreementsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionChangeAgreements.pending, state => {
      state.isAgreementLoading = true;
    });
    builder.addCase(actionChangeAgreements.fulfilled, (state, action: PayloadAction<IAgreementsTypes>) => ({
      ...state,
      ...action.payload,
      isAgreementLoading: false,
    }));
  },
  initialState,
  name: 'agreementsSlice',
  reducers: {
    actionAcceptAllBasicAgreements: () => ({
      ...initialState,
      isUserAllowedProfiling: true,
      isUserAllowedUseLocalStorage: true,
    }),
    actionRejectAllBasicAgreements: () => ({
      ...initialState,
      isUserAllowedUseLocalStorage: true,
    }),
    actionResetAllAgreements: () => initialState,
  },
});

export default agreementsSlice.reducer;
export const { actionAcceptAllBasicAgreements, actionRejectAllBasicAgreements, actionResetAllAgreements } =
  agreementsSlice.actions;
