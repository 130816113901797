import classNames from 'classnames';
import Slider, { Handle } from 'rc-slider';
import React, { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react';

import { ReactComponent as HandleImage } from '@app/pmi/src/assets/media/V2/handleVeev.svg';

import { useQuizView } from '@lib/core/quizzes/hooks';

interface Props {
  classname?: string;
  answers: Record<
    string,
    {
      text: string;
      image?: string;
    }
  >[];
  setCheckIfAnswerSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSliderSelection: (answerId: string) => void;
}

const AnswerSlider: FC<Props> = ({ answers, classname, setCheckIfAnswerSelected, handleSliderSelection }) => {
  const [selectedAnswer, setSelectedAnswer] = useState(2);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  let infoAnswerText = '';

  const firstAnswerObject = answers[0][Object.keys(answers[0])[0]];
  const lastAnswerObject = answers[4][Object.keys(answers[4])[0]];
  const firstAnswerText = firstAnswerObject?.text?.split(';')[1];
  const lastAnswerText = lastAnswerObject?.text?.split(';')[1];

  const roundedSelectedAnswer = Math.round(selectedAnswer);
  const selectedAnswerIdentifier = Object.keys(answers[roundedSelectedAnswer])[0];
  const selectedAnswerObject = answers[roundedSelectedAnswer][selectedAnswerIdentifier];

  if (roundedSelectedAnswer === 0 || roundedSelectedAnswer === 4) {
    infoAnswerText = selectedAnswerObject?.text?.split(';')[0];
  } else {
    infoAnswerText = selectedAnswerObject?.text;
  }

  const { quizViewQuestionId } = useQuizView();

  const firstAnswerImage = firstAnswerObject?.image;
  const lastAnswerImage = lastAnswerObject?.image;
  const handleBlockWrapperHorizontalPosition = (sliderValue, slideMax) => {
    let correctValue = 31;
    if (roundedSelectedAnswer === 0) {
      correctValue = sliderValue * 62;
    }

    if (roundedSelectedAnswer === 4) {
      correctValue = 62 - (slideMax - sliderValue) * 62;
    }

    return correctValue;
  };
  const HandleWrapper = props => {
    const { dragging, ...restProps } = props;

    return (
      <Handle
        dragging={dragging.toString()}
        {...restProps}
        className="rc-slider-handle"
        onClick={() => {
          setIsAnswerSelected(true);
        }}
      >
        <HandleImage className="rc-slider-handle-image" />
        <div
          className="handle-block-wrapper"
          style={{ left: `-${handleBlockWrapperHorizontalPosition(restProps.value, restProps.max)}px` }}
        >
          <div className="handle-block">
            <div
              style={{ width: `${(restProps.max - restProps.value) * 25}%` }}
              className={classNames('handle-image-wrapper left', {
                'handle-image-wrapper-right-border': roundedSelectedAnswer !== 0 && roundedSelectedAnswer !== 4,
              })}
            >
              <div
                className="handle-image handle-image-left"
                style={{ backgroundImage: `url('${firstAnswerImage}')` }}
              />
            </div>
            <div
              style={{ width: `${restProps.value * 25}%` }}
              className={classNames('handle-image-wrapper right', {
                'handle-image-wrapper-left-border': roundedSelectedAnswer !== 0 && roundedSelectedAnswer !== 4,
              })}
            >
              <div
                className="handle-image handle-image-right"
                style={{ backgroundImage: `url('${lastAnswerImage}')` }}
              />
            </div>
          </div>
        </div>
      </Handle>
    );
  };

  const checkSelectedAnswer = useCallback(() => {
    if (isAnswerSelected) {
      setCheckIfAnswerSelected(true);
    } else {
      setCheckIfAnswerSelected(false);
    }
  }, [isAnswerSelected]);

  useEffect(() => {
    if (quizViewQuestionId) {
      setIsAnswerSelected(false);
      setSelectedAnswer(2);
    }
  }, [quizViewQuestionId]);

  useEffect(() => {
    handleSliderSelection(selectedAnswerIdentifier);
  }, [answers, roundedSelectedAnswer]);

  useLayoutEffect(() => {
    checkSelectedAnswer();
  }, [checkSelectedAnswer]);

  return (
    <div className={`ta-v2-qds8011-answer-slider-block ${classname}`}>
      <div className="ta-v2-qds8011-answer-slider-header">
        {isAnswerSelected ? (
          <div className="font-ta-v2-global-body-small height-24px font-weight-bold">{infoAnswerText}</div>
        ) : (
          <div className="ta-v2-qds8011-answer-slider-header-loading" />
        )}
      </div>
      <div className="ta-v2-qds8011-answer-slider-images-block">
        <div className="images-wrapper" />
        <div className="image-block left-image-border" style={{ backgroundImage: `url('${firstAnswerImage}')` }} />
        <div className="image-block right-image-border" style={{ backgroundImage: `url('${lastAnswerImage}')` }} />
      </div>
      <div className="ta-v2-qds8011-answer-slider-wrapper">
        <div className="ta-v2-qds8011-answer-slider-dots-block">
          <div className="ta-v2-qds8011-answer-slider-dot" />
          <div className="ta-v2-qds8011-answer-slider-dot" />
          <div className="ta-v2-qds8011-answer-slider-dot" />
          <div className="ta-v2-qds8011-answer-slider-dot" />
          <div className="ta-v2-qds8011-answer-slider-dot" />
        </div>
        <Slider
          defaultValue={2}
          handle={HandleWrapper}
          max={4}
          min={0}
          step={0.01}
          value={selectedAnswer}
          onAfterChange={value => {
            setSelectedAnswer(Math.round(value));
          }}
          onChange={value => {
            if (!isAnswerSelected) {
              setIsAnswerSelected(true);
            }
            setSelectedAnswer(value);
          }}
        />
      </div>
      <div className="font-ta-v2-global-hint-text-medium ta-v2-qds8011-answer-slider-answer-text">
        <div className="ta-v2-qds8011-answer-slider-answer-text-left">{firstAnswerText}</div>
        <div className="ta-v2-qds8011-answer-slider-answer-text-right">{lastAnswerText}</div>
      </div>
    </div>
  );
};

export default AnswerSlider;
