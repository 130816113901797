import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';

import { ENV_IS_TESTING } from '@lib/core/service/consts';
import Reducers from '@lib/core/service/rootReducer';
import { initialMockedState } from '@lib/core/tests/consts';

export const Reducer = combineReducers({
  ...Reducers,
  agreements: persistReducer(
    {
      blacklist: ['isUserAllowedUseThirdParty'],
      key: 'agreements',
      storage,
      timeout: 50,
    },
    Reducers.agreements,
  ),
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      timeout: 50,
    },
    Reducers.auth,
  ),
  productTip: persistReducer(
    {
      key: 'productTip',
      storage,
      timeout: 50,
      whitelist: ['usersProductTipsStorage'],
    },
    Reducers.productTip,
  ),
  quizView: persistReducer(
    {
      key: 'quizView',
      storage,
      timeout: 50,
      whitelist: ['answerTags'],
    },
    Reducers.quizView,
  ),
  remoteAccess: persistReducer(
    {
      key: 'remoteAccess',
      storage,
      timeout: 50,
    },
    Reducers.remoteAccess,
  ),
  routes: persistReducer(
    {
      key: 'basename',
      storage,
      timeout: 50,
      whitelist: ['basename'],
    },
    Reducers.routes,
  ),
  service: persistReducer(
    {
      key: 'service',
      storage,
      timeout: 50,
      whitelist: ['isDevToolsEnabled', 'isTasteIdGuideShown', 'shouldHideDownloadAppCard', 'shouldHideLoginPrompt'],
    },
    Reducers.service,
  ),
  user: persistReducer(
    {
      key: 'user',
      storage,
      timeout: 50,
    },
    Reducers.user,
  ),
  userQuiz: persistReducer(
    {
      blacklist: ['isSendUserTestToUserFinished'],
      key: 'userQuiz',
      storage,
      timeout: 50,
    },
    Reducers.userQuiz,
  ),
});

export const createMockedStore = (mockedState = {}) =>
  configureStore({
    middleware: [thunkMiddleware],
    preloadedState: mockedState,
    reducer: Reducer,
  });

// ! Need to ensure import order in main.tsx
export const store = ENV_IS_TESTING
  ? createMockedStore(initialMockedState)
  : configureStore({
      middleware: [thunkMiddleware],
      reducer: Reducer,
    });

persistStore(store);
