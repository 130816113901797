import Chart from 'react-google-charts';

export const QuizSankey = (props: any) => {
  const options = {
    sankey: {
      link: {
        color: { fillOpacity: 0.1 },
        colorMode: 'gradient',
      },
      node: {
        colors: ['#EC407A', '#AB47BC', '#7E57C2', '#29B6F6', '#26C6DA', '#26A69A', '#9CCC65', '#FFCA28'],
        interactivity: true,
        label: {
          bold: true,
          color: '#ffffff',
          fontSize: 12,
        },
        width: 5,
      },
    },
  };

  const { data } = props;

  return <Chart chartType="Sankey" data={data} height="800px" options={options} width="100%" />;
};
