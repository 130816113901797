// eslint-disable-next-line import/no-cycle
import { createSlice } from '@reduxjs/toolkit';

import { userProductPreferencesApiUrl } from '@lib/core/products/slices/urls';
import { IUserProductPreferences } from '@lib/core/products/types/userProductsPreferences';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { IToolkitRejectedAction } from '@lib/core/service/types/common';

export interface IUserProductPreferencesSlice {
  isLoading: boolean;
  data: IUserProductPreferences[];
  error: string;
}
const initialState: IUserProductPreferencesSlice = {
  data: undefined,
  error: '',
  isLoading: false,
};

export const actionGetUserProductPreferences = createTypedAsyncThunk(
  'products/userProductPreferences/get',
  async () => await request(userProductPreferencesApiUrl),
);
export const userProductPreferencesSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetUserProductPreferences.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionGetUserProductPreferences.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });

    builder.addCase(actionGetUserProductPreferences.rejected, (state, action: IToolkitRejectedAction) => {
      state.isLoading = false;
      const { payload: { errors: { detail = '' } = {} } = {} } = action;
      state.error = detail;
    });
  },
  initialState,
  name: 'userProductPreferences',
  reducers: {},
});

export default userProductPreferencesSlice.reducer;
