import { IRetailerLocationsBySlugRequestParams, IRetailerLocationsRequestParams } from '@lib/core/retailers/types';
import { getRequestCommonParameters } from '@lib/core/service/requests/api';

/**
 * Used for get retailer data based on slug (usually from url)
 *
 * @param {string} retailerSlug
 * @returns {string} url for get retailer data based on slug
 */
export const retailerDataApiUrlCreator = ({ retailerSlug }: { retailerSlug: string }) => {
  return `/retailers/${retailerSlug}/`;
};

/**
 * Used for get retailer location data based on retailer location id and retailer slug
 *
 * @param {string} retailerLocationId
 * @param {string} retailerSlug
 * @returns {string} url for get retailer location data based on retailer location id and retailer slug
 */
export const retailerLocationDataApiUrlCreator = ({
  retailerLocationId,
  retailerSlug,
}: IRetailerLocationsRequestParams) => {
  return `/retailers/${retailerSlug}/retailer-locations/${retailerLocationId ? `${retailerLocationId}/` : ''}`;
};

/**
 * Used for get retailer location data by retailer location slug
 */
export const retailerLocationDataBySlugApiUrlCreator = ({
  retailerLocationSlug,
  retailerSlug,
}: IRetailerLocationsBySlugRequestParams) => {
  return `/retailers/${retailerSlug}/retailer-locations-by-slug/${retailerLocationSlug}/`;
};

/**
 * Used for post/get user privacy values acceptance after registration (widget/b2c)
 *
 * @returns {string} url for privacy api request based on user email or (need to check) token
 */
export const privacyLogApiUrlCreator = () => {
  const { retailerSlug } = getRequestCommonParameters();
  return `/retailers/${retailerSlug}/privacy-log/`;
};

export const apiUrlUseAccessKey = '/retailers/use-retailer-access-key/';
export const apiUrlGenerateAccessKey = '/retailers/generate-retailer-access-key/';
export const apiUrlVerifyAccessKey = '/retailers/verify-retailer-access-key/';
export const apiUrlOpenIframeIntegration = '/retailers/validate-open-integration/';
export const apiUrlGetListTags = '/retailers/tags/';
