import { FC } from 'react';

import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';

interface Props {
  nicotineLevelData: {
    description: string;
    name: string;
  }[];
  classname?: string;
}

const NicotineLevels: FC<Props> = ({ nicotineLevelData, classname }) => {
  return (
    <div className={`ta-v2-nicotine-level-card ${classname}`}>
      <span className="font-ta-v2-global-regulatory-text-medium">
        <LocaleFragment message={localeV2.product.nicotineLevel} />
      </span>
      <div className="ta-v2-nicotine-level-card-content">
        <p className="ta-v2-nicotine-level-card-text">
          <span className="font-ta-v2-global-body-small ta-v2-nicotine-level-card-text-name">{`${nicotineLevelData?.[0]?.name.replace(
            'veev-nicotine-',
            '',
          )}%`}</span>
          <span className="font-ta-v2-global-body-small ta-v2-nicotine-level-card-text-description">{`(${nicotineLevelData?.[0]?.description})`}</span>
        </p>

        {nicotineLevelData.length > 1 && (
          <>
            <div className="ta-v2-nicotine-level-card-content-margin" />
            <p className="ta-v2-nicotine-level-card-text">
              <span className="font-ta-v2-global-body-small ta-v2-nicotine-level-card-text-name">{`${nicotineLevelData?.[1]?.name.replace(
                'veev-nicotine-',
                '',
              )}%`}</span>
              <span className="font-ta-v2-global-body-small ta-v2-nicotine-level-card-text-description">{`(${nicotineLevelData?.[1]?.description})`}</span>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default NicotineLevels;
