import { Fragment } from 'react';
import ReactMarkdown, { Options } from 'react-markdown';
import remarkBreaks from 'remark-breaks';

import { capitalizeFirstLetter } from '@lib/core/service/utils';

type Props = Omit<Options, 'children'> & {
  text: string | number | JSX.Element;
  transformText?: 'default' | 'none' | 'custom-pmi';
};

const CustomMDReactComponent = ({ text = '', transformText = 'default', ...rest }: Props) => {
  const transformedText = (() => {
    const textStr = String(text);
    if (transformText === 'default') {
      return capitalizeFirstLetter(textStr);
    }
    if (transformText === 'custom-pmi') {
      return textStr.replace(/\|\|/g, '  \n');
    }
    return textStr;
  })();

  return (
    <ReactMarkdown components={{ p: Fragment }} remarkPlugins={[remarkBreaks]} {...rest}>
      {transformedText}
    </ReactMarkdown>
  );
};

export default CustomMDReactComponent;
