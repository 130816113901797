import { createSlice } from '@reduxjs/toolkit';

import { QA_URL_PARAM_CURRENT_DATE } from '@lib/core/service/consts';

export const initialState = {
  [QA_URL_PARAM_CURRENT_DATE]: null,
};

export const QAParameterSlice = createSlice({
  initialState,
  name: 'QAParameterSlice',
  reducers: {
    updateCurrentDateParams: (state, action) => {
      state[QA_URL_PARAM_CURRENT_DATE] = action.payload;
    },
  },
});

export default QAParameterSlice.reducer;
export const { updateCurrentDateParams } = QAParameterSlice.actions;
